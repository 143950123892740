<template>
  <main>
    <div role="main" class="about-us"><!---->
      <div class="about-us__banner">
        <div class="about-us__badges">
          <div class="about-us__badge"><img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/badge-char-1.4fb8c70.png"
              alt="about us badge char 1" class="about-us__badge-char"> <img
              src="https://d3oqh5ecy4r3n8.cloudfront.net/img/badge-1.614f6f4.png" alt="about us badge 1"
              class="about-us__badge-image"></div>
          <div class="about-us__badge"><img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/badge-char-2.ce820a4.png"
              alt="about us badge char 2" class="about-us__badge-char"> <img
              src="https://d3oqh5ecy4r3n8.cloudfront.net/img/badge-2.e58cf76.png" alt="about us badge 2"
              class="about-us__badge-image"></div>
          <div class="about-us__badge"><img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/badge-char-3.fcd9de5.png"
              alt="about us badge char 3" class="about-us__badge-char"> <img
              src="https://d3oqh5ecy4r3n8.cloudfront.net/img/badge-3.5c4f15f.png" alt="about us badge 3"
              class="about-us__badge-image"></div>
          <div class="about-us__badge"><img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/badge-char-4.0ebd51e.png"
              alt="about us badge char 4" class="about-us__badge-char"> <img
              src="https://d3oqh5ecy4r3n8.cloudfront.net/img/badge-4.5d024c2.png" alt="about us badge 4"
              class="about-us__badge-image"></div>
        </div>
      </div>
      <div class="about-us__content">
        <div
                        class="spinner"
                        style="margin-top: 150px"
                        v-if="loading"
                      ></div>
        <div  v-for="(footerData, index) in footerData?.qna"
                        :key="index" class="about-us__column">
          <h2>{{footerData?.question}}</h2>
          <div>
            <p class="flex-container" v-html="footerData?.answer"></p>
          </div>
        </div>


        <!-- <div class="about-us__column">
          <h2>Withdraw Winnings Instantly</h2>
          <div>
          <p>When you’ve landed a big win at bitleon, you want to withdraw that money right away. To help you, we’ve
            put together a cutting-edge auto-processing system that is processing your withdrawal with lightning speed.
            The average cashout time at bitleon is no longer than 10 minutes.</p>
        </div>
      </div>
      <div class="about-us__column">
        <h2>3+ Years Support Experience</h2>
        <div>
          <p>Everyone is claiming to have experienced support. We take this even further. All support agents at bitleon
            have at least 3 years of casino experience and they know bitleon and the industry inside and out.</p>
          <p>All you have to do is open the live chat if you have any questions, or just stop by to say hi. We're
            friendly, we promise!</p>
        </div>
      </div> -->
    </div>
  </div>

</main>
<div>
  <footerBottom />
</div></template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'AboutUs',
  data() {
    return {
      footerData: [],
      loading: false,
      SITEAUTHKEY:""
    };
  },
  components: {
    footerBottom
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    async getFooterData() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_KEY}/api/navigation/get-all-navigation?name=About Us&site_auth_key=${this.SITEAUTHKEY}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        this.footerData = data?.data[0]?.data[0];
        // console.log(data?.data[0]?.data[0], "game in about navigation");
      } catch (error) {
        console.error(error);
        // Handle error
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    const siteAuthKey=process.env.VUE_APP_API_SITE_AUTH_KEY
    this.SITEAUTHKEY=siteAuthKey
    this.getFooterData();

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>.about-us__banner {
  background: url(https://d3oqh5ecy4r3n8.cloudfront.net/img/banner.b3b97a6.jpg) 50%/cover no-repeat;
  display: flex;
  padding-bottom: min(7.25%, 100px);
  padding-top: min(7.25%, 100px);
  width: 100%
}

@media(max-width:768px) {
  .about-us__banner {
    padding: 30px 15px
  }
}

.about-us__badges {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .about-us__badges {
    padding: 0 15px
  }
}

@media(max-width:478px) {
  .about-us__badges {
    grid-template-columns: 1fr 1fr
  }
}

.about-us__badge {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative
}

.about-us__badge-char {
  height: auto;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  width: 100%
}

@media(max-width:478px) {
  .about-us__badge-char {
    display: none
  }
}

:first-of-type>.about-us__badge-char {
  left: -31%
}

:nth-of-type(2)>.about-us__badge-char {
  left: -7%;
  top: -18%;
  width: 63%
}

:nth-of-type(3)>.about-us__badge-char {
  bottom: -11%;
  right: -6%;
  width: 74%
}

:nth-of-type(4)>.about-us__badge-char {
  max-height: 111%;
  right: -39%;
  top: -6%
}

.about-us__badge-image {
  position: relative;
  z-index: 1
}

.about-us__content {
  grid-gap: 60px;
  color: #d3d7db;
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  max-width: 1420px;
  padding: 60px 45px;
  width: 100%
}

@media(max-width:1024px) {
  .about-us__content {
    gap: 30px;
    grid-template-columns: 1fr 1fr;
    padding: 0 15px;
    padding-bottom: 30px;
    padding-top: 30px
  }
}

@media(max-width:478px) {
  .about-us__content {
    grid-template-columns: 1fr
  }
}

.about-us__content h2 {
  color: #fff;
  font-size: 24px;
  line-height: 1.2;
  margin: 0 0 1.2em
}

@media(max-width:768px) {
  .about-us__content h2 {
    font-size: 18px
  }
}

.about-us__content p {
  font-size: 18px;
  margin: 0 0 1.2em
}

@media(max-width:768px) {
  .about-us__content p {
    font-size: 14px
  }
}

@media(max-width:1024px) {
  .about-us__column:first-child {
    grid-column: 1/-1
  }
}

.about-us__video-wrapper {
  margin: 0 auto;
  max-width: 1420px;
  padding: 20px 45px;
  width: 100%
}

@media(max-width:1024px) {
  .about-us__video-wrapper {
    padding: 0 15px;
    padding: 20px 15px
  }
}

.about-us__video-container {
  padding-top: 56.25%;
  position: relative;
  width: 100%
}

.about-us__video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}
.about-us{
  text-align:left;
}</style>