<template>
    <main>
  
      <section v-if ="game.length>0" data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
        <div data-cy="cy-games-sections" class="games-sections">
  
          <div data-cy="cy-games-sections__heading" class="" style="margin-top: 10px">
            <div data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
              <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                data-cy="cy-games-sections__heading-back" @click="goBack">  
                <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
              </button> <!---->

              <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                {{ t(`${this.$route.params.category}`) }} 
              </h1> <!---->
            </div>
          </div>
          <div >
            <div data-cy="cy-games-block" style="margin-top: 20px" class="game-category-box ">
              <div v-for="game in game" :key="game._id" class="">
                <!-- window.location.href=`/game/casino/${gpid}/${gameid}?mode=${mode}`; -->
  
                <a :href="`/game/clone-casino/${game.provider_id}/${game.game_id}/${game.api_provider_name}?mode=player`" class="c-game-container">
                  <!-- Game badges -->
  
                  <!-- Game box with image -->
                  <div class="c-game-box">
                    <img :src="game?.image_url" :alt="game.game_name" class="c-game-box__image image-casino-category">
                  </div>
                </a>
              </div>
            </div> <!---->
            <div data-cy="cy-games-block__footer" class="games-block__footer"><!----></div>
          </div>
        </div>
        <div class="load-more-container">
          <button style="margin-top: 10px" @click="loadMore" v-if="currentPage < totalPages" class="load-more-button">
            {{ t(`Load More`) }}
            <span class="load-more-icon"></span>
          </button>
        </div>
      </section>
  
    </main>
    <div>
      <footerBottom />
    </div>
  </template>
    
  <script>
  import footerBottom from '../../parts/footer.vue'
  
  
  export default {
    name: 'CloneProviderGameByCategory',
    data() {
      return {
        loading: false,
        SITEAUTHKEY: "",
        allProvidersDropdown: [],
        game: [],
        readMoreData: 'none',
        readMoreDataButton: 'inline-flex',
        currentPage: 1,
        totalPages: null,
        currentRoute: "",
        gpId: "",
        category: "",
  
      };
    },
    components: {
      footerBottom
    },
    props: {
      msg: String
    },
    mounted() {
  
      //  this.fetchProviderList();
      this.fetchAllGameByCategory()
console.log(this.$route.params,"route.params.apiProviderName")
  
    },
    methods: {
      t(key) {
        return this.$t(key);
      },
  
      async fetchAllGameByCategory(newProvider, oldProvider) {
        const category = newProvider 
        if (newProvider !== oldProvider) {
          this.game = []
        }
        console.log("calling API", category);
        try {
          const url = `${process.env.VUE_APP_API_KEY}/api/secondary-game/get-game-by-game-category?game_category=${this.$route.params.category}&status=true&page=${this.currentPage}&limit=28&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`
  
          const headers = {
            'Content-Type': 'application/json'
          };
  
          const response = await fetch(url, {
            method: 'GET',
            headers: headers,
  
          });
          if (response.status !== 200) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          this.totalPages = data.pagination.totalPages;
          // console.log(data.pagination.totalPages,"data.pagination.totalPages")
          // this.game = data.data
          this.game = [...this.game, ...data.data];
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle error gracefully
        }
      },
  
      
      loadMore() {
  
        if (this.currentPage < this.totalPages) {
          // Increment current page and fetch more games
          this.currentPage++;
          this.fetchAllGameByCategory();
        }
      },
      readMore() {
        this.readMoreData = 'block';
        this.readMoreDataButton = 'none';
      },
      goBack() {
        this.$router.back();
      },
  
  
    },
  
    watch: {
      "$route.params.gpId": function (newProvider, oldProvider) {
        // console.log("callsed updateefet");
        // Update your array or perform any necessary actions here
        this.loaded = 4;
        this.fetchAllGameByCategory(newProvider, oldProvider);
  
      },
      currentPage: function (newPage, oldPage) {
        this.fetchAllGameByCategory();
      },
  
    },
  
  
  }
  </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    
  <style scoped>
  .provider-container {
    /* border:2px solid red; */
    display: flex;
    overflow: scroll;
    gap: 10px;
    padding: 10px;
  
  
  }
  
  .provider-container::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0
  }
  
  .category-box {
    width: 100%;
    margin: auto;
  
  }
  
  .provider-text2 {
    width: 160px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    padding: 10px;
    background-color: #272533;
    font-weight: bold;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, padding 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  
  }
  
  .provider-text2:hover {
  
    transform: scale(1.1);
  }
  
  .load-more-container {
    text-align: center;
  }
  
  .load-more-button {
    background-color: #1e90ff;
    /* Adjust color as needed */
    border: none;
    color: white;
    padding: 15px 30px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .load-more-button:hover {
    background-color: #007bff;
    /* Adjust hover color as needed */
  }
  
  .load-more-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-top: 2px solid rgba(255, 255, 255, 0.7);
    border-right: 2px solid rgba(255, 255, 255, 0.7);
    animation: loadMoreAnimation 0.8s infinite alternate;
  }
  
  @keyframes loadMoreAnimation {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  
  .c-swiper-wrapper {
    margin: 0 auto 30px;
    max-width: 1420px;
    padding: 0 45px;
    width: 100%
  }
  
  @media(max-width:1024px) {
    .c-swiper-wrapper {
      padding: 0 15px
    }
  }
  
  .is-mobile .c-swiper-wrapper,
  .is-tablet .c-swiper-wrapper {
    padding: 0
  }
  
  .c-swiper-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;
    position: relative;
    z-index: 2
  }
  
  @media(max-width:1024px) {
    .c-swiper-header {
      margin: 0 0 11px
    }
  }
  
  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 45px
  }
  
  @media(max-width:1024px) {
  
    .is-mobile .c-swiper-header,
    .is-tablet .c-swiper-header {
      padding: 0 15px
    }
  }
  
  .c-swiper-header__box {
    align-items: center;
    display: flex
  }
  
  .c-swiper-container {
    overflow: hidden;
    position: relative
  }
  
  .is-desktop .c-swiper-container {
    margin-top: -40px;
    padding-top: 40px
  }
  
  .c-swiper {
    -ms-overflow-style: none;
    display: flex;
    height: inherit;
    margin-left: -10px;
    overflow: hidden;
    scrollbar-width: none;
    width: calc(100% + 20px)
  }
  
  .c-swiper::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0
  }
  
  .c-swiper.scroll-enabled {
    overflow: scroll
  }
  
  @media(max-width:1024px) {
    .c-swiper {
      margin-left: -5px;
      width: calc(100% + 10px)
    }
  }
  
  .is-desktop .c-swiper {
    margin-top: -40px;
    padding-top: 40px
  }
  
  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    margin: 0;
    padding: 0 45px;
    width: 100%
  }
  
  @media(max-width:1024px) {
  
    .is-mobile .c-swiper,
    .is-tablet .c-swiper {
      padding: 0 15px
    }
  }
  
  .c-swiper-item {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    height: inherit;
    width: 100%
  }
  
  .c-swiper-item.is-placeholder {
    width: 6.65%
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item,
  .is-tablet .c-games-swiper-section .c-swiper-item {
    justify-content: flex-start;
    min-width: 134px;
    padding: 5px 10px 5px 0;
    width: 134px
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 169px;
    padding: 5px 45px 5px 0;
    width: 169px
  }
  
  @media(max-width:1024px) {
  
    .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
    .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
      min-width: 139px;
      padding: 5px 15px 5px 0;
      width: 139px
    }
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
    min-width: 305px;
    width: 305px
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 340px;
    width: 340px
  }
  
  @media(max-width:1024px) {
  
    .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
    .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
      min-width: 310px;
      width: 310px
    }
  }
  
  @media(max-width:478px) {
    .is-mobile .c-games-swiper-section .c-swiper-item {
      min-width: 34%;
      width: 34%
    }
  
    .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
      min-width: 31.375%;
      padding-right: 0;
      width: 31.375%
    }
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
    max-width: 305px;
    min-width: auto;
    width: 64.35%
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    max-width: 295px;
    min-width: auto;
    padding-right: 0;
    width: 61.45%
  }
  
  .c-swiper-side-gradient {
    height: calc(100% - 20px);
    position: absolute;
    top: 10px;
    z-index: 4
  }
  
  @media(max-width:1024px) {
    .c-swiper-side-gradient {
      height: calc(100% - 10px);
      top: 5px
    }
  }
  
  .c-swiper-side-placeholder {
    background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity .25s ease-in;
    width: .5%
  }
  
  .c-swiper-side-placeholder.is-visible {
    opacity: 1
  }
  
  .c-swiper-side-navigation {
    background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
    pointer-events: none;
    right: 0;
    transition: all .25s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 5%
  }
  
  .c-swiper-side-navigation.should-hide-gradient {
    background: none
  }
  
  .c-swiper-side-navigation .c-swiper-navigation {
    align-items: flex-end;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: center;
    width: 100%
  }
  
  .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    margin-right: 0;
    opacity: 0;
    pointer-events: all;
    transition: opacity .25s ease-in
  }
  
  .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
    margin-top: 10px
  }
  
  .c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
    opacity: 1
  }
  
  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 10px;
    height: calc(100% - 60px);
    top: auto
  }
  
  @media(max-width:1024px) {
  
    .is-desktop .c-swiper-side-navigation,
    .is-desktop .c-swiper-side-placeholder {
      bottom: 5px;
      height: calc(100% - 50px)
    }
  }
  
  .c-game-badges {
    position: absolute;
    justify-content: space-between;
    top: 15px;
    right: 7%;
    display: flex;
    width: 85%;
  }
  
  .c-game-badges-collection {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    z-index: 5;
  }
  
  .c-game-badges span {
    flex: 0 0 auto;
    margin-right: 3px;
  }
  
  .c-game-badge.is-exclusive {
    background-color: #00bd71;
  }
  
  .c-game-badges span:last-child {
    margin-right: 0;
  }
  
  .c-game-badges .c-game-badge__text {
    margin: 0;
    /* position: relative;
      top: 15px; */
  }
  
  .c-game-badge {
    fill: #fff;
    align-items: center;
    border-radius: 4px;
    color: #fff;
    cursor: default;
    display: flex;
    font-size: 11px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
    padding: 0 4px;
    position: relative;
  }
  
  .c-swiper-container .game .c-game-badges .game-badge-provider {
    display: none;
  }
  
  .c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
    display: flex;
    z-index: 5;
  }
  
  @media (hover: hover) {
    .c-game-container:hover .c-game-box__image {
      transform: scale3d(1.05, 1.05, 1);
      z-index: 4;
      position: relative;
    }
  
    .c-game-container:hover {
      background: #3c3955;
    }
  }
  
  .c-swiper-container .game .c-game-box img {
    border-radius: 10px;
  }
  
  .c-swiper-item-games {
    display: flex;
  }
  
  .c-game-badge.is-bs-original {
    background-color: #e31351;
    /* margin-right: 5px; */
  }
  
  .c-game-badge__iconb {
    width: 0;
  }
  
  .sprite-icons {
    width: 0;
  }
  
  .c-game-badge.is-jackpot {
    background: #854dff;
    color: #fff;
  }
  
  .c-game-badge.is-new {
    background-color: #e31351;
  }
  
  .c-swiper-wrapper {
    margin: 0 auto 30px;
    max-width: 1420px;
    padding: 0 45px;
    width: 100%;
  }
  
  .c-swiper-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;
    position: relative;
    z-index: 2;
  }
  
  .c-swiper-header__box {
    align-items: center;
    display: flex;
  }
  
  .games-sections__title {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 15px 0 0;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  @media(max-width:700px) {
    .games-sections__title {
      color: #fff;
      font-size: 15px;
      font-weight: 800;
      line-height: 1.5;
      margin: 0 15px 0 0;
      -webkit-text-decoration: none;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
  
  .games-sections__title.is-clickable {
    cursor: pointer;
  }
  
  .homepage-variation-2 .games-sections__title {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    text-transform: capitalize;
  }
  
  .c-swiper-navigation {
    align-items: center;
    display: flex;
  }
  
  .c-swiper-header__box {
    align-items: center;
    display: flex;
  }
  
  .c-swiper-container {
    overflow: hidden;
    position: relative;
  }
  
  .is-desktop .c-swiper-container {
    margin-top: -40px;
    padding-top: 40px;
  }
  
  .c-swiper {
    -ms-overflow-style: none;
    display: flex;
    height: inherit;
    margin-left: -10px;
    overflow: hidden;
    scrollbar-width: none;
    width: calc(100% + 20px);
  }
  
  .c-swiper.scroll-enabled {
    overflow: scroll;
  }
  
  .is-desktop .c-swiper {
    margin-top: -40px;
    padding-top: 40px;
  }
  
  .c-swiper-item {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    height: inherit;
    width: 100%;
  }
  
  .c-providers-swiper__item {
    box-sizing: border-box;
    display: inline-block;
    flex: 0 1 auto;
    line-height: 0;
    padding: 10px;
    position: relative;
    width: 20%;
  }
  
  .c-provider-card {
    background: #3c3955;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
  }
  
  .is-variation-2 .c-provider-card,
  .is-variation-2 .c-provider-card:not(.is-disabled):hover {
    background: transparent;
  }
  
  .c-provider-card__body {
    align-items: center;
    aspect-ratio: 1.85;
    background-color: hsla(0, 0%, 100%, .05);
    display: flex;
    justify-content: center;
  }
  
  .is-variation-2 .c-provider-card__body {
    aspect-ratio: 2/1;
  }
  
  .c-provider-card__image {
    max-height: 80%;
    max-width: 80%;
    transition: transform .2s ease-out;
  }
  
  .c-providers-swiper__item {
    box-sizing: border-box;
    display: inline-block;
    flex: 0 1 auto;
    line-height: 0;
    padding: 10px;
    position: relative;
    width: 20%;
  }
  
  .c-provider-card {
    background: #3c3955;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
  }
  
  .is-variation-2 .c-provider-card,
  .is-variation-2 .c-provider-card:not(.is-disabled):hover {
    background: transparent;
  }
  
  .c-provider-card__image {
    max-height: 80%;
    max-width: 80%;
    transition: transform .2s ease-out;
  }
  
  .bs-c-homepage-v2-title {
    text-align: left;
  }
  
  .buy-img {
    height: 20px;
  }
  
  .games-block__button {
    margin: auto;
  }
  
  .c-site-description__section {
    text-align: left;
  }
  
  .c-game-badges .game-badge-provider {
    display: none;
  }
  
  .c-game-container:hover .c-game-badges .game-badge-provider {
    display: flex;
    z-index: 5;
  }
  
  .c-game-container .c-game-box img {
    border-radius: 10px;
  }</style>
  
  
  