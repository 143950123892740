<template>
  <main>
    <div class="c-games-category"><!---->
      <div>
        <section data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="games-sections__heading">
              <div data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  NEW GAMES
                </h1> <!---->
              </div>
            </div>
            <div>
              <div data-cy="cy-games-block" class="games-block">
                <div class="game"><!----> <a href="/slots/winter-star" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-exclusive"><!----> <span
                            class="c-game-badge__text">Exclusive</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evoplay.svg" alt="Evoplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evoplay/WinterStar.webp"
                        alt="Winter Star Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/dragon-age" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-exclusive"><!----> <span
                            class="c-game-badge__text">Exclusive</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/DragonAge.webp"
                        alt="Dragon Age Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/joker-ice-frenzy-epic-strike" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_jokerIceFrenzyEpicStrikeDesktop.webp"
                        alt="Joker Ice Frenzy Epic Strike Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/katana-klash-hold-n-link" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/netgame.svg"
                          alt="NetGame Entertainment" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/netgame/KatanaKlashHoldNLink.webp"
                        alt="Katana Klash: Hold 'N Link Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/capymania-yellow" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/CapymaniaYellow.webp"
                        alt="Capymania Yellow Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/cygnus-4" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/elk.svg" alt="ELK"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/elk/Cygnus4.webp"
                        alt="Cygnus 4 Slot" class="c-game-box__image">
                      <div class="c-game-box__latest-win">
                        💰 $32.29
                      </div>
                    </div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/double-up-respin" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/slotopia.svg" alt="slotopia"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evoplay/DoubleUPRespin.webp"
                        alt="Double UP Respin Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/aztec-fire-2" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/3oaks.svg" alt="3 Oaks Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/3oaks/aztec_fire_2.webp"
                        alt="Aztec Fire 2 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/capymania-orange" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/CapymaniaOrange.webp"
                        alt="Capymania Orange Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruity-christmas" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/FruityChristmas.webp"
                        alt="Fruity Christmas Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/capymania-green" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/CapymaniaGreen.webp"
                        alt="Capymania Green Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/the-belt-of-champion-bonus-buy" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evoplay.svg" alt="Evoplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evoplay/TheBeltofChampionBonusBuy.webp"
                        alt="The Belt of Champion Bonus Buy Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/xmas-coins-running-wins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/fugaso.svg" alt="FUGASO"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/groove/XmasCoinsRunningWins.webp"
                        alt="Xmas Coins: Running Wins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/hottest-666" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/Hottest666.webp"
                        alt="Hottest 666 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/santa-mummy" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/belatra.svg" alt="Belatra"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/belatra/SantaMummy.webp"
                        alt="Santa Mummy Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/christmas-crash" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evoplay.svg" alt="Evoplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evoplay/ChristmasCrash.webp"
                        alt="Christmas Crash Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/tsar-treasures" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pgsoft.svg" alt="PGSoft"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/TsarTreasures.webp"
                        alt="Tsar Treasures Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/oxygen" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/elk.svg" alt="ELK"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/elk/oXygen.webp"
                        alt="oXygen Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/boost-the-wheel" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mancala.svg" alt="Mancala Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mancala/BoosttheWheel.webp"
                        alt="Boost the Wheel! Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/wild-wild-bet" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mascot.svg" alt="Mascot"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mascot/wild_wild_bet.webp"
                        alt="Wild Wild Bet Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/scrooge-coins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/onlyplay.svg" alt="Onlyplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/onlyplay/ScroogeCoins.webp"
                        alt="Scrooge Coins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/gold-blitz-extreme" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_goldBlitzExtremeDesktop.webp"
                        alt="Gold Blitz Extreme Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/originals/pachinko-bitstarzoriginals" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-bs-original"><svg
                            xmlns="http://www.w3.org/2000/svg" class="c-game-badge__icon icon sprite-icons">
                            <use href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-star"
                              xlink:href="/_nuxt/94d9e219d9c4e62c730b4aa304976808.svg#i-icon-star"></use>
                          </svg> <span class="c-game-badge__text">Original</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bitstarzoriginals.svg"
                          alt="BitStarz Originals" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/bitstarzoriginals/pachinko.webp?v=1"
                        alt="Pachinko" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/chronicles-of-olympus-ii-zeus" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_chroniclesOfOlympusIIZeusDesktop.webp"
                        alt="Chronicles of Olympus II - Zeus Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mega-moolah-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€2,418,335.15</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_megaMoolahMegaways20MaxDesktop.webp"
                        alt="Mega Moolah Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/table-games/american-roulette-platipus" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-exclusive"><!----> <span
                            class="c-game-badge__text">Exclusive</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/platipus.svg" alt="Platipus"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/platipus/rouletteamerican.webp"
                        alt="American Roulette" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/gemza" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/Gemza.webp"
                        alt="Gemza Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/hoop-kings" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/booming.svg" alt="Booming Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/booming/HoopKings.webp"
                        alt="Hoop Kings Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/first-of-olympians" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/platipus.svg" alt="Platipus"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/platipus/firstofolympians.webp"
                        alt="First of Olympians Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/gates-of-olympus-1000" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/GatesofOlympus1000.webp"
                        alt="Gates of Olympus 1000 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/ice-princess" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/belatra.svg" alt="Belatra"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/belatra/IcePrincess.webp"
                        alt="Ice Princess Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/tycoons-billionaire-bucks-hold-win" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bsg.svg" alt="Betsoft Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/bsg/TycoonsBillionaireBucksHoldAndWin.webp"
                        alt="Tycoons: Billionaire Bucks - Hold &amp; Win Slot" class="c-game-box__image">
                      <div class="c-game-box__latest-win">
                        💰 $1.76
                      </div>
                    </div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/hercules-10k-ways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/reelplay.svg" alt="Reelplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/Hercules10KWays.webp"
                        alt="Hercules 10K Ways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/10-fruitata-wins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/technology.svg" alt="CT Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/technology/10FruitataWins.webp"
                        alt="10 Fruitata Wins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/25-coins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/wazdan.svg" alt="Wazdan"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/wazdan/25Coins.webp"
                        alt="25 Coins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/gold-nuggets" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/3oaks.svg" alt="3 Oaks Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/3oaks/gold_nuggets.webp"
                        alt="Gold Nuggets Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/ice-scratch-gold" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/IceScratchGold.webp"
                        alt="Ice Scratch Gold Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/blazin-rails" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_blazinRailsDesktop.webp"
                        alt="Blazin' Rails Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/table-games/santa-claw-factory" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/clawbuster.svg" alt="clawbuster"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/clawbuster/SANTA_CLAW_FACTORY.webp"
                        alt="Santa Claw Factory" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/crystal-land-2" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/playson.svg" alt="Playson"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/infin/CrystalLand2.webp"
                        alt="Crystal Land 2 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/chicken-night-fever" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_chickenNightFeverDesktop.webp"
                        alt="Chicken Night Fever Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/ice-scratch-silver" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/IceScratchSilver.webp"
                        alt="Ice Scratch Silver Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/jelly-valley" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/playson.svg" alt="Playson"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/infin/JellyValley.webp"
                        alt="Jelly Valley Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/super-sombrero" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/skywind.svg" alt="Skywind"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/skywind/SuperSombrero.webp"
                        alt="Super Sombrero Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/christmas-morning" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/ChristmasMorning.webp"
                        alt="Christmas Morning Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/juicy-fruits-multihold" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/JuicyFruitsMultihold.webp"
                        alt="Juicy Fruits Multihold Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/5-lucky-sevens" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gameart.svg" alt="GameArt"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gameart/5LuckySevens.webp"
                        alt="5 Lucky Sevens Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/ice-scratch-bronze" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/IceScratchBronze.webp"
                        alt="Ice Scratch Bronze Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/lucky-snowlight" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/LuckySnowlight.webp"
                        alt="Lucky Snowlight Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/sea-boat-adventure" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/maxwingaming.svg"
                          alt="MaxWin Gaming" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/SeaBoatAdventure.webp"
                        alt="Sea Boat Adventure Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/dj-fox" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pushgaming.svg" alt="Push Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pushgaming/DJFox.webp"
                        alt="DJ Fox Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/aussies-vs-emus" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bluegurugames.svg"
                          alt="Blue Guru Games" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/AussiesVSEmus.webp"
                        alt="Aussies VS Emus Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/snow-dragon" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/zillion.svg" alt="Zillion"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/zillion/SnowDragon.webp"
                        alt="Snow Dragon Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/gem-crush" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/netent.svg" alt="NetEnt"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/GemCrush.webp"
                        alt="Gem Crush Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mine-island" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/smartsoft.svg"
                          alt="SmartSoft Gaming" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/smartsoft/MineIsland.webp"
                        alt="Mine Island Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fire-stampede" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/FireStampede.webp"
                        alt="Fire Stampede Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/floating-dragon-new-year-festival-ultra-megaways-hold-spin"
                    class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/FloatingDragonNewYearFestivalUltraMegawaysHoldSpin.webp"
                        alt="Floating Dragon New Year Festival Ultra Megaways Hold &amp; Spin Slot"
                        class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/dragons-money" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/platipus.svg" alt="Platipus"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/platipus/dragonsmoney.webp"
                        alt="Dragon's Money Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/blade-master" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/backseatgaming.svg"
                          alt="backseatgaming" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/BladeMaster96.webp"
                        alt="Blade Master Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/diamond-bounty-xmas-hold-and-win" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/kalamba.svg" alt="Kalamba"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/kalamba/DiamondBountyXmasHoldandWin_k.webp"
                        alt="Diamond Bounty Xmas Hold and Win Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/giggly-greedy-story" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamebeat.svg" alt="Gamebeat"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamebeat/GigglyGreedyStory.webp"
                        alt="Giggly Greedy Story Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/merry-hog" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/belatra.svg" alt="Belatra"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/belatra/MerryHog.webp"
                        alt="Merry Hog Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mr-pigg-e-bank" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_mrPiggEBankDesktop.webp"
                        alt="Mr. Pigg E. Bank Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
              </div> <!---->
              <div data-cy="cy-games-block__footer" class="games-block__footer"><button
                  class="c-button games-block__button size-l" data-cy="cy-games-block__button"><!----> <span>Load
                    more</span> <!----></button></div>
            </div>
          </div>
        </section>
      </div>
      <section class="c-site-description">
        <div class="c-site-description__section">
          <h2>
            New Games
          </h2>
          <p>
            <span>
              Who doesn’t like the latest releases? It could be Casino Games, the latest Gucci Collection, Starlink from
              Elon Musk. Whatever it may be, it’s always exciting to check out the latest and the greatest.
            </span>
            <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
          </p>
          <div :style="{ display: readMoreData }">
            <p>But here, it’s all about Casino, and we have a dedicated Games Team that ensures that the latest releases
              are tested, checked for quality and then released if it meets the standard that we demand and expect. I
              mean, at bitleon we like to tell it like it is, and if a game sucks (and some sure do) we are not going to
              release them and pretend they’re the next best thing since sliced bread.</p>
            <p>Today we work with over 50 game studios, so we have a lot to choose from, and we’re constantly looking into
              new and innovative providers that could appeal to you. Oh, and if you’re missing a game or provider, just
              ask us on Live Chat and we’re happy to see what we can do!</p>
          </div>

        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>ONLY THE BEST WILL DO</h2>
          <div>
            <p>With all that said, everyone’s different, and although I might be a fan of some weird Cat game and Nick the
              Casino Manager is more of a <a href="#">Elvis the Frog in Vegas</a>, it’s important
              to offer a wide variety of games.</p>
            <p>Although we make sure the quality is high, we always make sure we have a wide variety of themes, mechanics,
              appearance, bonus rounds, free spins features, etc. This, because our taste and preferences may differ.</p>
            <p>But what you can count on, is that all our games are by high quality providers and that they are licensed
              and fair.</p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>WORLD’S LEADING MULTI-CURRENCY CASINO</h2>
          <div>
            <p>As you may or may not know, bitleon was the first fiat-crypto Casino. So what does that mean? Well, FIAT
              is a fancy word for regular international currencies such as Dollars, Euros, Yen, Venezuelan Bolivars, yeah
              you get the point. Crypto needs no further explanation I think.</p>
            <p>What we try to do is make sure we only bring onboard <router-link to="/game-providers">Game
                Studios</router-link> that offer
              play in as many currencies as possible. The reason for this is rather simple of course. Just as you might
              have a preference for a certain theme, you might also have a preference for a certain currency. So it would
              make very little sense to bring on a Game Studio that only made games playable in Polish Zloty.</p>
            <p>Just as we believe that Crypto is going to the moon, we also have a slot catalog that stretches to the moon
              and back with exciting role-playing games, interactive 3D slots, progressive jackpot slots, classic 3-reel
              slots, video poker, bonus game slots, provably fair slots, and just about every other category you can think
              of.</p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>TAKE IT TO THE TABLES</h2>
          <div>
            <p>If you’re a table games player or live casino enthusiast then your seat is booked at bitleon, with the
              latest table games and live dealer games from the world’s leading providers always on offer.</p>
            <p>With live dealer and non-live table games from studios like <router-link
                to="/games/evolution">Evolution</router-link>, <router-link to="/games/platipus">Platipus</router-link>,
              <router-link to="/games/belatra">Belatra</router-link>, <router-link
                to="/games/evoplay">Evoplay</router-link>, <router-link to="/games/bgaming">BGaming</router-link>,
              <router-link to="/games/luckystreak">Lucky Streak</router-link>, and numerous other world-class providers,
              bitleon players
              have a front-row seat to the hottest casino action as soon as it arrives.</p>
            <p>We offer all the classic table games including Dice, Blackjack, Baccarat, Poker, Sic Bo, and Roulette.
              These games come in all shapes and sizes and in many different varieties too.</p>
            <p>And guess what? Our games can be played using YOUR CHOICE of accepted fiat or cryptocurrency on our
              revolutionary casino platform - awesome!</p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>TRY SOMETHING NEW!</h2>
          <div>
            <p>Now that you’ve made it to our super hot selection of new games, you’ll know exactly where to go when
              you’re looking for the latest casino games around. From new table games to the freshly-squeezed slots, crash
              games, live dealer games, game shows, RNGs - you name it.</p>
            <p>If it's new, you’ll find it right here.</p>
          </div>
        </div>
      </section>

    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'NewGames',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex'
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  methods: {
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px)
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}</style>
