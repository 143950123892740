<template>
  <main>
    <div class="c-games-category"><!---->
      <div>
        <section data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="games-sections__heading">
              <div data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  MEGAWAYS GAMES
                </h1> <!---->
              </div>
            </div>
            <div>
              <div data-cy="cy-games-block" class="games-block">
                <div class="game"><!----> <a href="/slots/howling-wolves-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/booming.svg" alt="Booming Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/booming/HowlingWolvesMegaways.webp"
                        alt="Howling Wolves Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/the-dog-house-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/TheDogHouseMegaways1.webp"
                        alt="The Dog House Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/aztec-magic-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/AztecMagicMegaways.webp"
                        alt="Aztec Magic Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/big-bass-bonanza-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/BigBassBonanzaMegaways.webp"
                        alt="Big Bass Bonanza Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/jingle-ways-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/JingleWaysMegaWays.webp"
                        alt="Jingle Ways MegaWays Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/megahops-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/booming.svg" alt="Booming Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/booming/MegahopsMegaways.webp"
                        alt="Megahops Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/judgement-day-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/JudgementDayMegaWays.webp"
                        alt="Judgement Day Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/gonzos-quest-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/GonzosQuestMegaways.webp"
                        alt="Gonzo's Quest Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/nft-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/NFTMegaWays.webp"
                        alt="NFT Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/gems-inferno-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/GemsInfernoMegaWays.webp"
                        alt="Gems Inferno Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/egypt-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/EgyptMegaWays.webp"
                        alt="Egypt Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/dynamite-riches-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/DynamiteRichesMegaWays.webp"
                        alt="Dynamite Riches MegaWays Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mega-moolah-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span><span class="c-game-badge is-jackpot"><!---->
                          <span class="c-game-badge__text">€2,418,335.15</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_megaMoolahMegaways20MaxDesktop.webp"
                        alt="Mega Moolah Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/doggy-riches-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/DoggyRichesMegaways.webp"
                        alt="Doggy Riches Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/risque-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/RisqueMegaways.webp"
                        alt="Risque Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/buffalo-mania-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/BuffaloManiaMegaWays.webp"
                        alt="Buffalo Mania MegaWays Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/christmas-catch" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bigtimegaming.svg"
                          alt="Big Time Gaming" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/ChristmasCatch.webp"
                        alt="Christmas Catch Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/blood-suckers-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/bloodsuckersmega.webp"
                        alt="Blood Suckers MegaWays Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/big-cat-rescue-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/BigCatRescueMegaWays.webp"
                        alt="Big Cat Rescue MegaWays Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/big-buffalo-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/skywind.svg" alt="Skywind"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/skywind/BigBuffaloMegaways.webp"
                        alt="Big Buffalo Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/book-of-cats-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/BookOfCatsMegaways.webp"
                        alt="Book Of Cats Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/london-tube" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/LondonTube.webp"
                        alt="London Tube Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/the-wish-master-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/netent.svg" alt="NetEnt"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/TheWishMasterMegaWays.webp"
                        alt="The Wish Master Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fury-of-odin-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/FuryofOdinMegaways.webp"
                        alt="Fury of Odin Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/5-frozen-charms-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/5FrozenCharmsMegaways.webp"
                        alt="5 Frozen Charms Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruit-shop-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/netent.svg" alt="NetEnt"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/FruitShopMegaways.webp"
                        alt="Fruit Shop Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/savage-buffalo-spirit-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/SavageBuffaloSpiritMegaways.webp"
                        alt="Savage Buffalo Spirit Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/halloween-horrors-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/irondogstudio.svg"
                          alt="Iron Dog Studio" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1x2gaming/HalloweenHorrorsMegaways.webp"
                        alt="Halloween Horrors Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/wild-celebrity-bus-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/WildCelebrityBusMegaways.webp"
                        alt="Wild Celebrity Bus Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/megaways-jack-frost" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/irondogstudio.svg"
                          alt="Iron Dog Studio" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1x2gaming/MegawaysJackFrost.webp"
                        alt="Megaways Jack Frost Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/leprechauns-magic-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/maxwingaming.svg"
                          alt="MaxWin Gaming" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/LeprechaunsMagicMegaways.webp"
                        alt="Leprechaun's Magic Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/more-turkey" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bigtimegaming.svg"
                          alt="Big Time Gaming" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/MoreTurkey.webp"
                        alt="More Turkey Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/sweet-rush-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/SweetRushMegaways.webp"
                        alt="Sweet Rush Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/sweet-candy-cash-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/irondogstudio.svg"
                          alt="Iron Dog Studio" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1x2gaming/SweetCandyCashMegaways.webp"
                        alt="Sweet Candy Cash Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/megaways-duel-of-the-dead" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/kalamba.svg" alt="Kalamba"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/kalamba/MegawaysDueloftheDead_k.webp"
                        alt="Megaways Duel of the Dead Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/power-of-merlin-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/PowerofMerlinMegaways.webp"
                        alt="Power of Merlin Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruity-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/irondogstudio.svg"
                          alt="Iron Dog Studio" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1x2gaming/FruityMegaways.webp"
                        alt="Fruity Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/chervona-kalyna-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/onlyplay.svg" alt="Onlyplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/onlyplay/ChervonaKalynaMegaways.webp"
                        alt="Chervona Kalyna Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/jungle-gold-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/onlyplay.svg" alt="Onlyplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/onlyplay/JungleGoldMegaways.webp"
                        alt="Jungle Gold Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/joker-coins-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/onlyplay.svg" alt="Onlyplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/onlyplay/JokerCoinsMegaways.webp"
                        alt="Joker Coins Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/wolf-blaze-wowpot-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€9,570,269.87</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_wolfBlazeWowpotMegawaysDesktop.webp"
                        alt="Wolf Blaze WOWPOT! Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/9-pots-of-gold-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_9PotsOfGoldMegawaysDesktop.webp"
                        alt="9 Pots of Gold Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/1942-sky-warrior" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/1942SkyWarrior.webp"
                        alt="1942: Sky Warrior Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/the-race" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bigtimegaming.svg"
                          alt="Big Time Gaming" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/TheRace.webp"
                        alt="The Race Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/10001-nights-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/10001nightsmegaways.webp"
                        alt="10001 Nights Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/liga-fortuna-megaways-pro" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/onlyplay.svg" alt="Onlyplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/onlyplay/LigaFortunaMegawaysPRO.webp"
                        alt="Liga Fortuna Megaways PRO Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/gallo-gold-brunos-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_galloGoldMegawaysDesktop.webp"
                        alt="Gallo Gold Bruno's Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/hot-spicy-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/onlyplay.svg" alt="Onlyplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/onlyplay/HotSpicyMegaways.webp"
                        alt="Hot &amp; Spicy Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/outlaw" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bigtimegaming.svg"
                          alt="Big Time Gaming" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/Outlaw.webp"
                        alt="Outlaw Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/wild-wild-riches-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/WildWildRichesMegaways.webp"
                      alt="Wild Wild Riches Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/megaways-jack-and-the-magic-beans" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/irondogstudio.svg"
                        alt="Iron Dog Studio" class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1x2gaming/MegawaysJackandTheMagicBeans.webp"
                      alt="Megaways Jack and The Magic Beans Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/age-of-the-gods-king-of-olympus-megaways"
                  class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                          class="c-game-badge__text">€11,052.10</span></span> <!----></div> <span
                      class="game-badge-separator"></span> <!----> <span
                      class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/playtech.svg" alt="Playtech"
                        class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/playtech/AgeoftheGodsKingofOlympusMegaways.webp"
                      alt="Age of the Gods: King of Olympus Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/buffalo-king-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                        alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/BuffaloKingMegaways1.webp"
                      alt="Buffalo King Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/floating-dragon-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                        alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/FloatingDragonMegaways.webp"
                      alt="Floating Dragon Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/wolf-blaze-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/quickfire.svg" alt="Quickfire"
                        class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_wolfBlazeMegawaysDesktop.webp"
                      alt="Wolf Blaze Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/the-rodfather-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/booming.svg" alt="Booming Games"
                        class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/booming/TheRodfatherMegaways.webp"
                      alt="The Rodfather Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/rasputin-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bigtimegaming.svg"
                        alt="Big Time Gaming" class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/RasputinMegaways.webp"
                      alt="Rasputin Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/muertos-multiplier-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                        alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/MuertosMultiplierMegaways.webp"
                      alt="Muertos Multiplier Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/wild-west-gold-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                        alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/WildWestGoldMegaways.webp"
                      alt="Wild West Gold Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/5-families" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                          class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                      class="game-badge-separator"></span> <!----> <span
                      class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                        class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/5Families.webp"
                      alt="5 Families Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/aztec-wilds-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/irondogstudio.svg"
                        alt="Iron Dog Studio" class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1x2gaming/AztecWildsMegaways.webp"
                      alt="Aztec Wilds Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/5-lions-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                        alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/5LionsMegaways1.webp"
                      alt="5 Lions Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
              <div class="game"><!----> <a href="/slots/spin-score-megaways" class="c-game-container">
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                    <!----> <span class="game-badge-provider is-bagde-hover"><span
                        class="game-badge-provider__overlay"></span> <img
                        src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                        alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                  </div>
                  <div class="c-game-box"><img
                      src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/SpinandScoreMegaways.webp"
                      alt="Spin &amp; Score Megaways Slot" class="c-game-box__image"> <!----></div>
                </a></div>
            </div> <!---->
            <div data-cy="cy-games-block__footer" class="games-block__footer"><button
                class="c-button games-block__button size-l" data-cy="cy-games-block__button"><!----> <span>Load
                  more</span> <!----></button></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</main>
<div>
  <footerBottom />
</div></template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'Megaways',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex'
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  methods: {
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px)
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

.c-game-container .c-game-box img {
  border-radius: 10px;
}

.c-game-badge.is-highest.is-cold-rtp {
  background-color: #3861fb;
}</style>
