



<template>
  <main>
    <div class="c-games-category"><!---->
      <div >
        <section  data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="">
              <div  data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  {{t(`${this.name}`)}} {{t(`Games`)}}
                </h1> <!---->
              </div>

              
            </div>
            <div style="margin-top: 20px" >
              <div data-cy="cy-games-block" class="game-category-box">
                <div v-for="game in games" :key="game._id" class="">
      <router-link :to="`/game/casino/${game.gameProviderId}/${game.gameID}?mode=player`">
               
                <div class="c-game-box">
                  <img :src="game.gameInfos[0].gameIconUrl" :alt="game.gameInfos[0].gameName" class="c-game-box__image image-casino-category">
                </div>
            </router-link>
            </div>
            <template v-if="loading">
            <!-- Skeleton loading state -->
            <div class="skeleton-box">
              <div
                v-for="n in 20"
                :key="n"
                style=" background-color: #2b2740"
                class="skeleton"
              ></div>
            </div>
          </template>
              </div> <!---->
              <div data-cy="cy-games-block__footer" class="games-block__footer"><!----></div>
            </div>
          </div>
          <div class="load-more-container">
      <button style="margin-top: 10px" @click="loadMore" v-if="currentPage < totalPages" class="load-more-button">
        {{t(`Load More`)}}
        <span class="load-more-icon"></span>
      </button>
    </div>
        </section>
      </div>
      
<section v-if="this.name=='jackpot'" class="c-site-description">
  <div class="c-site-description__section">
    <h2>
      JACKPOTS
    </h2>
    <p>
      <span>
        Strike it rich with bitleon epic jackpot slots, because with this millionaire-making game category, you can
        break the bank, while having the time of your life. Jackpots, also known as progressive jackpots, come with
        flexible jackpot amounts that increase with every bet made.
      </span>
      <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
    </p>
    <div :style="{ display: readMoreData }">
      <p>bitleon has seen jackpot games close in on up to €20 million in prize money before, so if you’re a big
        dreamer, bitleon jackpot games have what it takes to make you a big winner. With over 80 jackpot games
        available, consisting of table, slot and Bitcoin games, players are spoiled for choice when it comes to
        playing in the big leagues.</p>
      <p>You may be wondering how jackpot games work? We’re here to take you through the basics and get you set to
        spin our ultra-rewarding jackpot reels!</p>
    </div>

  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>How to win the big bucks</h2>
    <div>
      <p>Jackpot amounts accumulate from each bet that is placed on a specific game. These games are linked to a
        worldwide network of players playing the same game, so the jackpot amounts build up pretty fast. After a
        jackpot is won, most games will revert back to their own particular beginning prize levels, which vary from
        game to game.</p>
      <p>Triggering jackpots also vary from game to game as well, with some games requiring players to place higher
        bets, land certain symbols on certain reels and positions, or spin bonus wheels. To navigate your way to
        extraordinary wealth, take a look at the game rules of the jackpot games you’re interested in playing so you
        know exactly what you need to do to spin your way to a mouth-watering payout.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Shout out to the heavy hitters</h2>
    <div>
      <p>While all of bitleon jackpot games are world-class, some of them are just so supreme that they deserve a
        special shout-out! The biggest jackpot game through and through is no doubt Microgaming’s safari-inspired
        Mega Moolah, it’s safe to say that this game is a record-breaker.</p>
      <p>The other huge jackpot games you can win the big bucks with are Fortunium Gold, Major Millions, and The
        Bomb.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>We’ve got what you need</h2>
    <div>
      <p>Timing is everything when playing jackpot games because ideally, you’d want to play a game that has a
        massive jackpot available to win at the time of your visit. At bitleon, the jackpot amounts to win are
        displayed on screen, and updated live, to give players the most accurate indication of what’s in store for
        them to bag in the jackpot department.</p>
      <p>On bitleon Home Page players can see what the ultimate total jackpot amount is to be won. More so, each
        jackpot game will have its current jackpot amount displayed on its icon in the jackpot menu, so you are
        always in the know.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Break the bank with bitleon!</h2>
    <div>
      <p>There’s only one way to hit the jackpot players, and that’s with bitleon huge jackpot games!</p>
      <p>If you’ve set your sights on a millionaire status then you have come to the home of huge payouts. What does
        your jackpot destiny hold… will you clean up and be bitleon next big jackpot winner? You have to spin the
        reels to find out!</p>
    </div>
  </div>
</section>

<section v-if="this.name=='slots'" class="c-site-description">
        <div class="c-site-description__section">
          <h2>
            Slots
          </h2>
          <p><span>Come on, let’s be honest. When it comes to playing at an online casino, the tables have to take a
              backseat. In these money-infested waters, the slot machine is the big fish and there are thousands of them
              out there for you to enjoy.</span> 
              
            </p>
          <div>
            <p>Lucky for you, bitleon has a pond bigger than most, where you can take your pick from the best slots on
              the market. Go fish!
              <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
            </p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>More Popular Than Ever</h2>
          <div>
            <p>Ever since the slot machine made its way onto the scene all the way back in the late 1890s, the attraction
              for this magical machine has gotten bigger and bigger as time has passed. Today, playing online slots is
              more popular than ever and has become a part of everyday life for millions of people across the globe.</p>
            <p>So why do so many of them come and play at bitleon? Because we listen to our players and take great pride
              in offering the best of the best in terms of online slots.</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Top Slots Standing By</h2>
          <div>
            <p>When it comes to slots, there are a certain group of elite providers that keep coming out with fantastic
              slots and we make sure to team up with them and keep our enormous slot library up to date with the latest
              releases on the market.</p>
            <p>Big-time providers such as <a href="/games/pragmatic">Pragmatic Play</a>, <a href="/games/nolimit">Nolimit
                City</a>, <a href="/games/booming">Booming Games</a>, <a href="/games/bsg">Betsoft Gaming</a>, <a
                href="/games/netent">NetEnt</a>, <a href="/games/playngo">Play’n GO</a> and <a
                href="/games/spinomenal">Spinomenal</a> all have slots standing by to show you a good time and secure you
              those big wins.</p>
            <p>Are you looking for the classic fruit machine, a more interactive game or perhaps the best graphics and
              latest 3D technology? Whatever your preference in a slot is, you are sure to find it right here at bitleon!
            </p>
          </div>
        </div>
        <div class="c-site-description__section"  :style="{ display: readMoreData }">
          <h2>Spin Your Way to Riches</h2>
          <div>
            <p> It’s pretty much impossible to pick just one game that we feel is the best because all players tend to
              find different games entertaining. At bitleon, we never forget that playing slots is all about
              entertainment because after all, that’s why we are here.</p>
            <p>Yes, landing that monster win is something we are all looking for, but the biggest piece of the entire
              online casino cake is after all entertainment. There are some games that seem to stand the test of time
              though. Slots such as <a href="/slots/platinum-lightning">Platinum Lightning</a>, <a
                href="/slots/wolf-gold">Wolf Gold</a>, <a href="/slots/book-of-dead">Book of Dead</a>, <a
                href="/slots/elvis-frog-in-vegas">Elvis Frog in Vegas</a> and our very own <a
                href="/slots/bitleon-billion">bitleon Billion</a> are waiting to send the big bucks in your direction.
            </p>
            <p>If you are a fan of online slots, just like the rest of us, then you can rest assured that you have found
              your way to the ultimate slot capital of the world, bitleon!</p>
          </div>
        </div>
      </section>


      <section v-if="this.name=='live'" class="c-site-description">
  <div class="c-site-description__section">
    <h2>
      Live Casino
    </h2>
    <p>
      <span>
        With bitleon’s Live Casino, you can experience the thrill of a live game from the safety and comfort of
        your own home - or wherever you choose to play!
      </span>
      <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
    </p>
    <div :style="{ display: readMoreData }">
      <p>bitleon works with leading live casino game providers such as Evolution Gaming, Vivo Gaming and Authentic
        Gaming. Thanks to these collaborations, you’ll be treated to an immersive experience made all the better
        thanks to High-Definition streaming, multiple camera angles and knowledgeable and experienced dealers - who
        aren’t too hard on the eyes, either.</p>
      <p>You won’t be left wanting when it comes to variety that’s for sure. We offer 150 Live Casino games
        including Blackjack, Roulette, Baccarat, Poker, Wheel games, Sic Bo, Dream Catcher and Top Card Football
        Studio. In addition, most of our Live Casino games are available 24/7.</p>
    </div>

  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Live Blackjack</h2>
    <div>
      <p>At bitleon, you’ll find interesting Live Casino variants such as Blackjack Party, First Person Blackjack,
        Infinite Blackjack and Salon Priveé Blackjack.</p>
      <p>Feeling like a big hitter who wants to wager a bit more each round? There’s also Blackjack VIP, which is
        perfect for anyone that has high roller ambitions.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Live Roulette</h2>
    <div>
      <p>Most games play with a single-zero wheel, but it is possible to play American Roulette with its double
        zeros too, if that is more of your style.</p>
      <p>“Normal” Roulette is so last season though. In the bitleon Live Casino environment, you’ll find a plethora
        of exotic variants such as Platinum Authentic Roulette, Dup Roulette Saint Vincent, Auto Speed Roulette,
        Immersive Roulette and Lightning Roulette.</p>
      <p>Furthermore, you can also play Roulette at a private table. Some of the games are available in native
        languages so check out Ruletka Live, Svensk Roulette and Deutsches Roulette too.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Live Baccarat</h2>
    <div>
      <p>If you’re a Baccarat fan, then you’re in for a treat thanks to variations like Live Baccarat (Vivo), Live
        Baccarat (Evolution), Baccarat Squeeze, Baccarat Controlled Squeeze and Salon Privé Baccarat.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Other Live Games</h2>
    <div>
      <p>If all of that wasn’t enough, there’s also Live Dream Catcher – the colorful money-wheel game – and Live
        Football Studio Top card, which is a card game based on live soccer/football fixtures.</p>
      <p>It’s safe to say that these games let you mix it up with something fresh when the situation calls for it.
      </p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Access to Live Casino</h2>
    <div>
      <p>All you need to do to get in on the Live Casino action (or check out the free play versions on offer) is
        register an account with us. Games are broadcast in real time, so bitleon casino action is locked, loaded,
        and live!</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Languages</h2>
    <div>
      <p>If you were playing at a regular brick-and-mortar casino, you’d expect to be spoken to in your native
        tongue and that’s exactly what bitleon offers. Call it a casino experience that is home away from home if
        you will.</p>
      <p>Experience your favourite Live games in the following languages: Arabic, Bulgarian, Chinese, Dutch,
        English, Finnish, French, Georgian, German, Greek, Hindi, Indonesian, Italian, Japanese, Korean, Mongolian,
        Norwegian, Persian, Portuguese, Russian, Serbian, Spanish, Swedish, Thai, Turkish or Vietnamese.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Currencies</h2>
    <div>
      <p>As bitleon is the leading cryptocurrency casino, you’ll be able to bet on Live games using Bitcoin, Ether,
        Litecoin and Bitcoin Cash.</p>
      <p>Fancy something a little more traditional? We accept the Euro, US Dollar, Canadian Dollar, Australian
        Dollar, New Zealand Dollar, Japanese Yen, Renminbi, Polish Złoty, Russian Ruble and Norwegian Krone as well.
      </p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Live Games Where You Are!</h2>
    <div>
      <p>Desktop play, mobile play, and everything in-between, thanks to bitleon you’ll be able to play the very
        best live casino games from wherever you choose, and on the device that suits you best.</p>
      <p>If you’re ready for a 5-star live casino experience, one thing is for sure, you’ve come to the right place!
      </p>
    </div>
  </div>
</section>

  <section v-if="this.name=='table'" class="c-site-description">
        <div class="c-site-description__section">
          <h2>
            Table Games
          </h2>
          <p>
            <span>
              Table games combine the true essence of gambling with pure excitement and thrill. And when it comes to
              betting on the best in the industry, bitleon has you covered like a Roulette layout on payday.
            </span>
            <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
          </p>
          <div :style="{ display: readMoreData }">
            <p>
              With over 250 table games to choose from, bitleon is your number one destination when it comes to wagering
              on tables. We offer only the highest quality games from the world’s leading providers such as Authentic
              Gaming, Belatra, Betsoft, BGaming, Platipus, Evoplay Entertainment, Evolution Gaming, Vivo Gaming, iSoftBet,
              NetEnt, and PGSoft.</p>
            <p>Cards, wheels, dice, and everything nice can be found on our premium selection of table games. And if
              you’re looking to play with cryptos then take a seat, there's a whole stack of table games available in
              multiple cryptocurrencies.</p>
            <p>We take immense pride in bringing top-quality casino entertainment to our players, and our Table Games menu
              is nothing short of spectacular!
            </p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Play Your Way with Multiple Currencies</h2>
          <div>
            <p>It’s no secret that our games are available in multiple currencies - we shout it loud and proud for the
              whole world to hear after all. Because at bitleon, we want you to play <strong>YOUR WAY</strong>. We’ve
              gone to great lengths to ensure that you can enjoy playing many of your favorite games in various fiat and
              cryptocurrencies.</p>
            <p><strong>Go old school with fiat currencies:</strong> Euro (EUR), US Dollars (USD), Canadian Dollar (CAD),
              Australian Dollar (AUD), New Zealand Dollar (NZD), Japanese Yen (JPY), Renminbi (RMB), Polish Złoty (PLN),
              Russian Ruble (RUB), Norwegian Krone (NOK).</p>
            <p><strong>Unchain the game with cryptocurrencies:</strong> Bitcoins (BTC), Bitcoin Cash (BCH), Ethereum
              (ETH), Dogecoin (DOG), Tether (USDT) and Litecoin (LTC).</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Roulette</h2>
          <div>
            <p>Take your pick, we have them all! French, European, and American Roulette games are served fresh and hot
              every day. Add a little spice with bonus Roulette games too, such as <a
                href="/table-games/lucky-roulette">Lucky Roulette</a> or <a
                href="/table-games/golden-chip-roulette">Golden Chip Roulette</a> for some fiery features and extra
              payouts.</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Blackjack</h2>
          <div>
            <p>Hit the Blackjack tables at bitleon and find nothing but magic! Variations of Blackjack from around the
              world are available at the click of a button. We have some of the most exciting bonus-added Blackjack games
              ever made too, such as <a href="/slots/blackjack-lucky-sevens">Blackjack Lucky Sevens</a> or <a
                href="/table-games/double-exposure">Double Exposure</a> if you’d like a glimpse at both the dealer’s
              cards.</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Poker</h2>
          <div>
            <p>The massive pot of Poker games just keeps getting bigger at bitleon. Rake in the winnings with our
              world-class selection of Poker in many varieties from the most prominent providers around. <a
                href="/table-games/texas-holdem">Texas Hold’em</a>, <a href="/table-games/oasis-poker">Oasis Poker</a>, <a
                href="/table-games/lucky-roulette">Jacks or Better</a>, <a href="/table-games/caribbean-poker">Caribbean
                Poker</a>, and many more legendary Poker games are waiting for your winning hand.</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Baccarat</h2>
          <div>
            <p>When it comes to Baccarat, bitleon is a natural nine! Our incredible spread of Baccarat games comes second
              to none. Try your hand at our vast selection such as Baccarat by <a
                href="/table-games/baccarat-bgaming">BGaming</a> or <a href="/table-games/baccarat-netent">NetEnt</a> for
              something classic. If you have an exotic taste then <a href="/slots/baccarat-777">Baccarat 777</a> or <a
                href="/table-games/big-win-baccarat">Big Win Baccarat</a> should satisfy the craving.</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Other Games</h2>
          <div>
            <p>Table games are forever evolving in the world of online gaming. We have many non-traditional table games
              that have been created by some of the best and brightest minds in the industry. If you’re looking for
              something weird and wonderful, or just a little different to anything else, then look no further, bitleon
              has it all!</p>
            <p>Enjoy the tables folks. And remember to Dream Big, Win Bigger at bitleon!</p>
          </div>
        </div>


      </section>

    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import axios from 'axios'; // Import axios for making HTTP requests
import footerBottom from '../parts/footer.vue'; // Import footer component

export default {
  name: 'Table Games',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex',
      games: [], // Array to store games
      loading:false,
      currentPage: 1, // Track the current page
      totalPages: null,
      currentRoute: '',
      name: '',
      category: ''
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  mounted() {
    // Call the API to fetch initial games
    this.fetchGames();
   
      // this.getCurrentRouteInfo();
      // console.log(this.name,this.category)

  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    toggleSidebar() {
      if (window.innerWidth > 1366) {
        this.sidebarWidth = this.sidebarWidth === "0px" ? "240px" : "0px";
        this.displayTopHeaderMobile =
          this.displayTopHeaderMobile === "none" ? "none" : "none";
      } else if (window.innerWidth <= 1366 && window.innerWidth > 478) {
        this.sidebarWidth = this.sidebarWidth === "0px" ? "300px" : "0px";
        this.displayTopHeaderMobile =
          this.displayTopHeaderMobile === "none" ? "flex" : "none";
      } else {
        this.sidebarWidth = this.sidebarWidth === "0px" ? "100%" : "0px";
        this.displayTopHeaderMobile =
          this.displayTopHeaderMobile === "none" ? "flex" : "none";
      }

      this.padding = this.padding === "0px" ? "10px 10px 180px" : "0px";
      this.$refs.navbar__menu_button.classList.toggle("is-open");
    },
 
    async fetchGames(newCategory,oldCategory) {
      const category=newCategory||this.$route.params.category
      if(newCategory!==oldCategory){
        this.games=[]
      }
      this.loading=true
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_KEY}/api/game/get-game-by-game-type`, {
          params: {
            status: true,
            page: this.currentPage,
            game_type:category,
            limit: 28,
            site_auth_key:process.env.VUE_APP_API_SITE_AUTH_KEY
          }
        });
        if (response.status === 200 && response.data.success) {
          // Update games array with new data
      this.loading=false

          this.games = [...this.games, ...response.data.data];
          this.totalPages = response.data.pagination.totalPages;
        } else {
      this.loading=false

          console.error('Failed to fetch games:', response.data.message);
        }
      } catch (error) {
      this.loading=false

        console.error('Error fetching games:', error);
      }
    },
    // Function to load more games
    loadMore() {
      if (this.currentPage < this.totalPages) {
        // Increment current page and fetch more games
        this.currentPage++;
        this.fetchGames();
      }
    },
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  },
  watch: {
    "$route.params.category": function (newProvider, oldProvider) {
      // console.log("callsed updateefet");
      // Update your array or perform any necessary actions here
      // this.loaded = 4;
      this.fetchGames(newProvider, oldProvider);
 
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.skeleton-box {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.skeleton {
  width: 180px;
  height: 230px;
  border-radius: 8px;
}
@media screen and (max-width: 1200px) {

.skeleton-box {
display: grid;
width: 100%;
grid-template-columns: repeat(7, 1fr);
gap: 10px;
}

.skeleton {
width: 130px;
height: 160px;
border-radius: 8px;
}
}


@media screen and (max-width:700px) {

.skeleton-box {
display: grid;
width: 100%;
grid-template-columns: repeat(4, 1fr);
gap: 10px;
}

.skeleton {
width: 120px;
height: 130px;
border-radius: 8px;
}
}

@media screen and (max-width:448px) {

.skeleton-box {
display: grid;
width: 100%;
grid-template-columns: repeat(3, 1fr);
gap: 6px;
}

.skeleton {
width: 135px;
height: 140px;
border-radius: 8px;
}
}
.load-more-container {
  text-align: center;
}

.load-more-button {
  background-color: #1e90ff; /* Adjust color as needed */
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.load-more-button:hover {
  background-color: #007bff; /* Adjust hover color as needed */
}

.load-more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
  border-right: 2px solid rgba(255, 255, 255, 0.7);
  animation: loadMoreAnimation 0.8s infinite alternate;
}

@keyframes loadMoreAnimation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}
.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  /* width: calc(100% + 20px) */
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100px
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

.c-game-container .c-game-box img {
    border-radius: 10px;
}
</style>


