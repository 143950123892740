<template>
  <main>
    <div v-if="allProvidersDropdown?.length>0" class="c-games-category">
      <!---->
      <div>
        <section
          data-cy="cy-games-sections-wrapper"
          class="games-sections-wrapper"
        >
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="">
              <div
                data-cy="cy-games-sections__heading-box"
                class="games-sections__heading-box"
              >
                <button
                  class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back"
                  @click="goBack"
                >
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button>
                <!---->
                <h1
                  data-cy="cy-games-sections__title"
                  class="games-sections__title"
                >
                  {{ t(`Game Studios`) }}
                </h1>
                <!---->
              </div>
            </div>
            <div id="providers-swiper-section" class="provider-home">
              <a
                :href="'/ex-game-category/all%20games/name'"
                style="text-decoration: none"
                class="provider-p"
              >
                <div class="">
                  <p
                    class="box-t-1"
                    style="display: flex; flex-direction: column"
                  >
                    All Studios
                    <span class="box-t-3">14 games</span>
                  </p>
                </div>
              </a>
              <div
                id="swiper-item-1"
                class="provider-box"
                v-for="provider in allProvidersDropdown"
                :key="provider._id"
              >
                <router-link
                  style="text-decoration: none"
                  :to="`/game-provider/${provider.gpName}/${provider.gpId}`"
                >
                  <div class="provider-p">
                    <!-- {{ provider?.gpName }} -->
                    <div class="img-box">
                      <img
                        class="provider-p-img"
                        :src="provider?.image_url"
                        alt="jh"
                      />
                    </div>
                    <div class="box-t">
                      <p class="box-t-1">Evolution</p>
                      <span class="box-t-2">14 games</span>
                    </div>
                  </div>
                </router-link>
              </div>
              <!---->
            </div>
          </div>
          <div class="load-more-container">
            <button
              style="margin-top: 10px"
              @click="loadMore"
              v-if="currentPage < totalPages"
              class="load-more-button"
            >
              {{ t(`Load More`) }}
              <span class="load-more-icon"></span>
            </button>
          </div>
        </section>
      </div>
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import axios from "axios"; // Import axios for making HTTP requests
import footerBottom from "../parts/footer.vue"; // Import footer component

export default {
  name: "Table Games",
  data() {
    return {
      readMoreData: "none",
      readMoreDataButton: "inline-flex",
      games: [], // Array to store games
      currentPage: 1, // Track the current page
      totalPages: null,
      currentRoute: "",
      name: "",
      category: "",
      allProvidersDropdown: [],
    };
  },
  props: {
    msg: String,
  },
  components: {
    footerBottom,
  },
  mounted() {
    // Call the API to fetch initial games
    this.fetchProviderList();

    // this.getCurrentRouteInfo();
    // console.log(this.name,this.category)
  },
  methods: {
    t(key) {
      return this.$t(key);
    },

    async fetchProviderList() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/casinoprovider/get-provider?limit=100&status=true&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const responseData = await response.json();
        // console.log("category in provids:", responseData);

        // Save the retrieved user data
        this.allProvidersDropdown = responseData.data;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    // Function to load more games
    loadMore() {
      if (this.currentPage < this.totalPages) {
        // Increment current page and fetch more games
        this.currentPage++;
        this.fetchGames();
      }
    },
    readMore() {
      this.readMoreData = "block";
      this.readMoreDataButton = "none";
    },
    goBack() {
      this.$router.back();
    },
  },
  watch: {
    "$route.params.category": function (newProvider, oldProvider) {
      // console.log("callsed updateefet");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c-games-category {
  margin: 0px 20px;
}
.provider-p {
  width: 100%;
  background-color: #35324a;
  font-weight: bold;
  color: white;
  margin-top: 10px;
  border-radius: 10px;
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out,
    padding 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
}

.provider-p:hover {
  background-color: #57556e;
}
.img-box {
  height: 90%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.box-t {
  background-color: #2b2740;
  width: 100%;
  height: 60%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-t-1 {
  color: #bdc2ca;
  font-size: 20px;
  margin-top: -10px;
}
.box-t-3 {
  color: #9ea4ae;
  font-size: 16px;
}
.box-t-2 {
  color: #9ea4ae;

  margin-top: -20px;
}
.provider-p-img {
  width: 100%;
  max-height: 70px;
  margin: auto;
}
.provider-p-img {
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out,
    padding 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
}
.provider-p-img:hover {
  transform: scale(1.2);
}

.provider-home {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  row-gap: 15px;
  margin-top: 10px;
}
@media (max-width: 1204px) {
  .provider-home {
    display: grid;
    gap: 6px;
  }
}
@media (max-width: 875px) {
  .provider-home {
    gap: 10px;
  }
  .c-games-category {
    margin: 0px 5px;
  }
  .provider-p {
    height: 170px;
  }
}
@media (max-width: 700px) {
  .provider-home {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 550px) {
  .provider-home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.load-more-container {
  text-align: center;
}

.load-more-button {
  background-color: #1e90ff; /* Adjust color as needed */
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.load-more-button:hover {
  background-color: #007bff; /* Adjust hover color as needed */
}

.load-more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
  border-right: 2px solid rgba(255, 255, 255, 0.7);
  animation: loadMoreAnimation 0.8s infinite alternate;
}

@keyframes loadMoreAnimation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, 0.05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform 0.2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform 0.2s ease-out;
}
</style>
