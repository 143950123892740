<template>
  <main>
    <div class="basic-page payments-page">
      <h1 style="color: white">
       {{footerData?.title}}
      </h1>
      <div
                        class="spinner"
                        style="margin-top: 150px"
                        v-if="loading"
                      ></div>
      <p style="color: white"> {{footerData?.description}}</p>
      <div v-for="(footerData,index) in footerData?.qna"
                            :key="index" >
        <h2  style="color: white">{{footerData?.question}}</h2>
        <div class="">
          <div>
              <p class="flex-container" style="color:white" v-html="footerData?.answer"></p>
          </div>
          <h2  style="color: white">Deposit</h2>
          <div v-if="cryptoLoader" class="cryptoLoaderBox">
              <div class="spinner"></div>
            </div>
          <div
          v-else
          
          class="payments-page__cards payments-page__withdrawal">
            
          <div 
            v-for="(item, index) in visibleDepositItems"
                :key="index"
          class="bs-payment-method coinspaid-BCH" style="--item-no: 1;">
            <ul id="coinspaid-180-BCH" class="bs-payment-method__content">
              <li class="bs-payment-method__item bs-payment-method-header header-crypto"><img alt="coinspaid-icon"
                  class="is-bch animated-icon header-image" :data-src="item?.icon"
                  :src="item?.icon" lazy="loaded"><span class="header-text">{{ item?.currency }}</span> </li>
              <li class="bs-payment-method__item"><span class="bs-payment-method__item-label">
                  Type
                </span> <span class="bs-payment-method__item-value bs-payment-method-type-name">{{ item?.name }}</span></li>
            
                <li class="bs-payment-method__item"><span class="bs-payment-method__item-label">
                  Rate
                </span> <span class="bs-payment-method__item-value"><strong>{{ item?.rate_usd }}</strong></span></li>
               
              <li class="bs-payment-method__item"><span class="bs-payment-method__item-label">
                  Processing time
                </span> <span class="bs-payment-method__item-value"><strong> Instantly</strong></span></li>
                <li class="bs-payment-method__item"><span class="bs-payment-method__item-label">
                  Limit per transaction
                </span>
                <div class="bs-payment-method__item-value"><strong>Min {{ item?.min_deposit }} </strong> <strong>Max {{item?.max_deposit}}</strong></div>
              </li> 
              <li v-if="!isAuthicate" class="bs-payment-method__item bs-payment-method__cta"><button
            @click="openModal1"

                  class="c-button is-full-width size-l"> <span>Deposit</span> </button></li>
            </ul>
          </div>
          
          </div>

          <div class="btn-load-more">
            <button style="background-color: #1C90FF;" v-if="depositHasMore" @click="loadMoreDeposit" class="c-button   size-l">Load More</button>

          </div>

        </div>
      </div>


      <div>
        <h2  style="color: white">Withdraw</h2>
        <div v-if="cryptoLoader" class="cryptoLoaderBox">
              <div class="spinner"></div>
            </div>
        <div 
        v-else
        
        class="payments-page__cards payments-page__withdrawal">
         
          <div 
           v-for="(item, index) in visibleWithdrawItems"
                :key="index"
          class="bs-payment-method coinspaid-BTC" style="--item-no: 0;">
            <ul id="coinspaid-180-BTC" class="bs-payment-method__content">
              <li class="bs-payment-method__item bs-payment-method-header header-crypto"><img alt="coinspaid-icon"
                  class="is-bch animated-icon header-image" :data-src="item?.icon"
                  :src="item?.icon" lazy="loaded"><span class="header-text">{{ item?.currency }}</span> </li>
              <li class="bs-payment-method__item"><span class="bs-payment-method__item-label">
                  Type
                </span> <span class="bs-payment-method__item-value bs-payment-method-type-name">{{ item?.name }}</span></li>
              <li class="bs-payment-method__item"><span class="bs-payment-method__item-label">
                  Fee
                </span> <span class="bs-payment-method__item-value"><strong>0%</strong></span></li>
              <li class="bs-payment-method__item"><span class="bs-payment-method__item-label">
                  Processing time
                </span> <span class="bs-payment-method__item-value"><strong> Instantly</strong></span></li>
              <li class="bs-payment-method__item"><span class="bs-payment-method__item-label">
                  Limit per transaction
                </span>
                <div class="bs-payment-method__item-value"><strong>Min {{ item?.min_withdrawal }} </strong> <strong>Max {{ item?.max_withdrawal }} </strong></div>
              </li> 
            </ul>
          </div>
        
        </div>
        <div class="btn-load-more">
            <button style="background-color: #1C90FF;" v-if="withdrawHasMore" @click="loadMoreWithdraw" class="c-button  size-l">Load More</button>

          </div>
      </div>
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'
import { inject } from "vue";


export default {
  name: 'BonusesTerms',
  setup() {
    const modalState = inject("modalState");
    const closeModal1 = inject("closeModal1");
    const openModal1 = inject("openModal1");

    return {
      modalState,
      closeModal1,
      openModal1,

    };
  },
  data() {
    return {
      footerData: [],
      loading: false,
      SITEAUTHKEY:"",
      cryptoLoader:false,
      prevRates: {}, // To store previous rates for comparison
      blinkRates: {},
      cryptoData:[],
      visibleDepositItems: [], // For deposit items
      visibleWithdrawItems: [], // For withdraw items
      depositLimit: 12, // Limit for how many deposit items to show
      withdrawLimit: 12, // Limit for how many withdraw items to show
      isAuthicate: true,
      isLogged: false,
    };
  },
  components: {
    footerBottom
  },
  computed: {
    depositHasMore() {
      return this.cryptoData.length > this.visibleDepositItems.length;
    },
    withdrawHasMore() {
      return this.cryptoData.length > this.visibleWithdrawItems.length;
    }
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    async getFooterData() {
    this.loading = true;
    const url = `${process.env.VUE_APP_API_KEY}/api/navigation/get-all-navigation?name=Payments&site_auth_key=${this.SITEAUTHKEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      this.footerData = data?.data[0]?.data[0];
      // console.log(data?.data[0]?.data[0], "game in privacy ploicy navigation");
    } catch (error) {
      console.error(error);
      // Handle error
    } finally {
      this.loading = false;
    }
  },
  async fetchCryptoPaymentMethods() {
      // Fetch tokens from localStorage

      const userToken = localStorage.getItem("userToken");
      const usernameToken = localStorage.getItem("usernameToken");

      if (!userToken || !usernameToken) {
        console.error("Missing tokens");
        return;
      }
      this.cryptoLoader = true;

      try {
        // Make the API request with the tokens in headers
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/auto-payment/get-passimo-pay-currencies`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: userToken, // Token from localStorage
              usernametoken: usernameToken, // Username token from localStorage
            },
          }
        );

        // If the response is not OK, throw an error
        if (!response.ok) {
          throw new Error("Failed to fetch crypto payment methods");
        }

        // Parse the JSON response
        const data = await response.json();
        // Update rates and blinking logic
        this.updateCryptoRates(data?.data);
        console.log(data, "data cgheck in promotin");
        this.cryptoData = data?.data;
        this.visibleDepositItems = this.cryptoData.slice(0, this.depositLimit);
      this.visibleWithdrawItems = this.cryptoData.slice(0, this.withdrawLimit);
      } catch (error) {
        // Handle error and set error state
        console.error("Error fetching crypto payment methods:", error);
        this.error = "Failed to fetch crypto payment data";
      } finally {
        this.cryptoLoader = false;
      }
    },
    loadMoreDeposit() {
      const nextDepositItems = this.cryptoData.slice(this.visibleDepositItems.length, this.visibleDepositItems.length + this.depositLimit);
      this.visibleDepositItems = [...this.visibleDepositItems, ...nextDepositItems];
    },
    loadMoreWithdraw() {
      const nextWithdrawItems = this.cryptoData.slice(this.visibleWithdrawItems.length, this.visibleWithdrawItems.length + this.withdrawLimit);
      this.visibleWithdrawItems = [...this.visibleWithdrawItems, ...nextWithdrawItems];
    },

    updateCryptoRates(data) {
      const newBlinkRates = {};
      const updatedPrevRates = {};

      data.forEach((item) => {
        const previousRate = this.prevRates[item.id];
        const currentRate = item.rate_usd;

        if (previousRate !== undefined && previousRate !== currentRate) {
          newBlinkRates[item.id] = true;

          setTimeout(() => {
            this.blinkRates = {
              ...this.blinkRates,
              [item.id]: false,
            };
          }, 2000);
        }

        updatedPrevRates[item.id] = currentRate;
      });

      this.prevRates = updatedPrevRates;
      this.blinkRates = newBlinkRates;
    },

  },
  created() {
    // Use localStorage.getItem to retrieve data from local storage
    const userAuthenticated = localStorage.getItem("user_authenticated");
    this.isLogged = userAuthenticated === "true";

    // Check if the user is authenticated, and set showButton accordingly
    if (this.isLogged) {
      this.isAuthicate = false;
    } else {
      this.isAuthicate = true;
    }
  },
  mounted() {
    const siteAuthKey=process.env.VUE_APP_API_SITE_AUTH_KEY
    this.SITEAUTHKEY=siteAuthKey
    this.getFooterData();
    this.fetchCryptoPaymentMethods()

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.payments-page.basic-page {
  margin: 0 auto;
  max-width: 1420px;
  padding: 0px 45px;
  width: 100%
}

.btn-load-more{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:50px ;

}
@media(max-width:1024px) {
  .payments-page.basic-page {
    padding: 0 15px;
    padding-bottom: 30px;
    padding-top: 0px
  }
}

.payments-page__cards,
.payments-page__cards-skeleton {
  grid-gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
}

.payments-page__cards-skeleton {
  min-height: 450px;
  padding-top: 50px
}

.bs-payment-method {
  --item-no: 0;
  animation: fade-up .3s ease-out .2s;
  animation-delay: .2s;
  animation-delay: calc(.2s*(var(--item-no) + 1));
  animation-fill-mode: forwards;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 12px 14px rgba(22, 20, 33, .05);
  opacity: 0;
  overflow: hidden
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(30px)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}
.cryptoLoaderBox{
  width:100%;
  margin-top: 50px;
  margin-bottom:40px;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 40px;
  height:40px;
  animation: spin 1s linear infinite;
  /* margin: 0 auto; */
}
.header-crypto{
}
.header-image{
  width:50px;
  height: 100px;
}
.header-text{
  font-weight: bold;
  font-size: 24px;
}
.bs-payment-method__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%
}

.bs-payment-method__item {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between
}

.bs-payment-method__item:nth-child(odd) {
  background: #f2f6f9
}

.bs-payment-method-header {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  padding: 0
}

.bs-payment-method-header img {
  height: 24px;
  margin: 28px 0;
  max-height: 30px
}

.bs-payment-method__item-label,
.bs-payment-method__item-value {
  color: #585d6d;
  display: block;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px 10px 12px 20px
}

.bs-payment-method__item-value {
  color: #161421;
  font-weight: 600;
  padding: 12px 20px 12px 0;
  text-align: right
}

.bs-payment-method__item-value strong {
  display: block
}

.bs-payment-method-type-name {
  text-transform: capitalize
}

.bs-payment-method__cta {
  padding: 10px
}
@media (max-width: 1600px){
.basic-page h1 {
    font-size: 48px;
    margin-bottom: 40px;
}

.basic-page h2 {
    font-size: 32px;
}
}
.payments-page{
  text-align: left;
}
</style>