<template>
    <div class="basic-page faq-page">
        <h1>
            FAQ
        </h1>
        <div
                        class="spinner"
                        style="margin-top: 150px"
                        v-if="loading"
                      ></div>
        <section  v-for="(faq, index) in FaqData" 
            :key="index"  class="faq-page__section" :class="{ 'is-expanded': openedIndex === Number(index) }">
            <h2 class="faq-page__section-title" @click="faq_function(index)">
                <!-- <font-awesome-icon class="faq-page__section-icon icon sprite-icons" :icon="['fas', 'user']"
                    style="color: #ffffff;" /> -->
                {{ faq?.name }}
                <font-awesome-icon :icon="['fas', 'angle-right']" size="lg" style="color: #ffffff;"
                    class="faq-page__section-arrow icon sprite-icons" />
            </h2>
            <div class="faq-page__section-body" :style="{ display: displayArray[index] }">
                <div  class="faq-page__section-item" v-for="(ques, newindex) in FaqData[index]?.data[0]?.qna" :key="newindex">
                    <h3 @click="secondDivOpen(newindex)"><font-awesome-icon :icon="['fas', 'chevron-right']"
                            class="faq-page__item-arrow icon sprite-icons" />
                        {{ ques?.question }}
                    </h3>
                    <div :style="{ display: secondDivDisplayArray[newindex] }" class="secondDiv">
                        <p class="flex-container" v-html="ques?.answer"></p>
                    </div>
                </div>
                
            </div>
        </section>
        
</div>
<div>
    <footerBottom />
</div></template> 
  
<script>
import footerBottom from '../parts/footer.vue'


export default {
    name: 'FAQ',
    data() {
        return {
            FaqData: [],
      SITEAUTHKEY:"",
            openedIndex: 100,
            displayArray: ['none', 'none', 'none', 'none', 'none', 'none', 'none', 'none',"none","none","none","none",'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none',"none","none","none","none"],
            secondDivDisplayArray: ['none', 'none', 'none', 'none', 'none', 'none', 'none', 'none','none', 'none', 'none', 'none', 'none', 'none', 'none', 'none',"none","none","none","none"],
            loading:false
        };
    },
    components: {
        footerBottom
    },
    created() {
        this.secondDivDisplayArray=[]
        
        for (let index = 0; index < 100; index++) {
            this.secondDivDisplayArray.push('none')
            
        }
    },
    methods: {
        t(key) {
      return this.$t(key);
    },
    async getFooterData() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_KEY}/api/navigation/get-all-navigation?site_auth_key=${this.SITEAUTHKEY}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        this.FaqData = data.data;
        // console.log(data?.data, "game in FAQ navigation");
        // console.log(data?.data[0].data[0]?.qna, "game kjk navigation");

       
      } catch (error) {
        console.error(error);
        // Handle error
      } finally {
        this.loading = false;
      }
    },
        faq_function(index) {
            // console.log(index,"checking")
            // Toggle the opened index. If the same index is clicked, close it.
            this.openedIndex = this.openedIndex === index ? null : index;

             // Update the display property for the specific index
            this.displayArray = this.displayArray.map((value, i) => {
                return i === index ? (value === 'none' ? 'block' : 'none') : 'none';
            });
        },
        secondDivOpen(index){
            this.secondDivDisplayArray = this.secondDivDisplayArray.map((value, i) => {
                return i === index ? (value === 'none' ? 'block' : 'none') : 'none';
            });
        }
    },
    mounted() {
    const siteAuthKey=process.env.VUE_APP_API_SITE_AUTH_KEY
    this.SITEAUTHKEY=siteAuthKey
    this.getFooterData();

  },
}
</script>
  
<style scoped>.basic-page {
    --base-color: #d3d7db;
    --highlight-color: #fff;
    color: #d3d7db;
    color: var(--base-color);
    font-size: 18px;
    line-height: 1.5;
    margin: 0 auto;
    max-width: 930px;
    padding: 60px 15px;
    width: 100%
}

@media(max-width:1600px) {
    .basic-page {
        font-size: 16px;
        padding-bottom: 40px;
        padding-top: 40px
    }
}

@media(max-width:768px) {
    .basic-page {
        font-size: 14px;
        padding-bottom: 30px;
        padding-top: 30px
    }
}

.basic-page h1,
.basic-page h2,
.basic-page h3 {
    color: var(--highlight-color);
    line-height: 1.2;
    margin: 60px 0 30px
}

@media(max-width:1600px) {

    .basic-page h1,
    .basic-page h2,
    .basic-page h3 {
        margin: 40px 0 25px
    }
}

@media(max-width:768px) {

    .basic-page h1,
    .basic-page h2,
    .basic-page h3 {
        margin: 30px 0 20px
    }
}

.basic-page h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 72px;
    font-weight: 900;
    margin: 0 0 60px
}

@media(max-width:1600px) {
    .basic-page h1 {
        font-size: 48px;
        margin-bottom: 40px
    }
}

@media(max-width:768px) {
    .basic-page h1 {
        font-size: 32px;
        margin-bottom: 30px
    }
}

.basic-page h2 {
    font-size: 48px
}

@media(max-width:1600px) {
    .basic-page h2 {
        font-size: 32px
    }
}

@media(max-width:768px) {
    .basic-page h2 {
        font-size: 24px
    }
}

.basic-page h3 {
    font-size: 32px
}

@media(max-width:1600px) {
    .basic-page h3 {
        font-size: 24px
    }
}

@media(max-width:768px) {
    .basic-page h3 {
        font-size: 18px
    }
}

.basic-page a {
    color: var(--highlight-color);
    cursor: pointer;
    -webkit-text-decoration: underline;
    text-decoration: underline
}

.basic-page a:hover {
    -webkit-text-decoration: none;
    text-decoration: none
}

.basic-page ol {
    list-style: decimal inside
}

.basic-page ol>li {
    margin: 1em 0
}

.basic-page ol>li::marker {
    color: var(--highlight-color);
    font-weight: 700
}

.basic-page ol>li p:first-child {
    display: inline
}

.basic-page img {
    display: block;
    margin: 1.5em 0
}

.basic-page__video {
    margin-bottom: 2em;
    padding: 56.25% 0 0;
    position: relative
}

.basic-page__video iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.faq-page h2.faq-page__section-title {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    margin: 0
}

@media(max-width:768px) {
    .faq-page h2.faq-page__section-title {
        font-size: 18px
    }
}

.faq-page .faq-page__section-item h3 {
    align-items: center;
    color: #d3d7db;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    padding: 10px
}

@media(max-width:768px) {
    .faq-page .faq-page__section-item h3 {
        font-size: 16px
    }
}

.faq-page .faq-page__section-item div {
    margin: -10px 0 0 34px
}

@media(max-width:768px) {
    .faq-page .faq-page__section-item div {
        margin-left: 32px
    }
}

.faq-page .faq-page__section-item.is-opened h3 {
    color: #fff
}

.faq-page__section {
    border-top: 1px solid #3c3955;
    padding: 18px 0
}

.faq-page__section-icon {
    fill: #fff;
    height: 18px;
    margin-right: 15px;
    width: 18px
}

@media(max-width:768px) {
    .faq-page__section-icon {
        height: 16px;
        width: 16px
    }
}

.faq-page__section-arrow {
    fill: #fff;
    height: 12px;
    margin-left: auto;
    transition: transform .3s;
    width: 12px
}

.is-expanded .faq-page__section-arrow {
    transform: rotate(90deg)
}

.faq-page__section-body {
    padding-top: 18px
}

.faq-page__item-arrow {
    fill: #d3d7db;
    height: 12px;
    margin-right: 10px;
    transition: transform .3s, fill .3s;
    width: 12px
}

.is-opened .faq-page__item-arrow {
    fill: #fff;
    transform: rotate(90deg)
}
.faq-page__section-item{
    text-align: left;
}
</style>

  