<template>
  <main>
    <div class="basic-page complaints">
      <div
                        class="spinner"
                        style="margin-top: 150px"
                        v-if="loading"
                      ></div>
      <h1>
        {{footerData?.title}}
      </h1>
      <div>
        <div  v-for="(footerData,index) in footerData?.qna"
                            :key="index">
          <h2>{{footerData?.question}}</h2>
              <p class="flex-container" v-html="footerData?.answer"></p>
          
            
        </div>

        <!-- <div class="general-terms__last-update">
          <p>Last updated: May 3rd, 2023</p> <a class="general-terms__download">
            Download PDF
          </a>
        </div> -->
      </div>
      
      <!-- <div class="complaints-icons">
         <a href="http://www.askgamblers.com" rel="nofollow"><img
            src="https://d3oqh5ecy4r3n8.cloudfront.net/img/askgamblers-certificate-of-trust.ba768d3.png"
            alt="askgamblers"></a>
          </div> -->
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'PlaySafe',
  data() {
    return {
      footerData: [],
      loading: false,
      SITEAUTHKEY:""
    };
  },
  components: {
    footerBottom
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    async getFooterData() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_KEY}/api/navigation/get-all-navigation?name=Complaints&site_auth_key=${this.SITEAUTHKEY}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        this.footerData = data?.data[0]?.data[0];
        console.log(data, "game in terms navigation");
      } catch (error) {
        console.error(error);
        // Handle error
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    const siteAuthKey=process.env.VUE_APP_API_SITE_AUTH_KEY
    this.SITEAUTHKEY=siteAuthKey
    this.getFooterData();

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.basic-page {
  --base-color: #d3d7db;
  --highlight-color: #fff;
  color: #d3d7db;
  color: var(--base-color);
  font-size: 18px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 930px;
  padding: 60px 15px;
  width: 100%
}

@media(max-width:1600px) {
  .basic-page {
    font-size: 16px;
    padding-bottom: 40px;
    padding-top: 40px
  }
}

@media(max-width:768px) {
  .basic-page {
    font-size: 14px;
    padding-bottom: 30px;
    padding-top: 30px
  }
}

.basic-page h1,
.basic-page h2,
.basic-page h3 {
  color: var(--highlight-color);
  line-height: 1.2;
  margin: 60px 0 30px
}

@media(max-width:1600px) {

  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 40px 0 25px
  }
}

@media(max-width:768px) {

  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 30px 0 20px
  }
}

.basic-page h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 72px;
  font-weight: 900;
  margin: 0 0 60px
}

@media(max-width:1600px) {
  .basic-page h1 {
    font-size: 48px;
    margin-bottom: 40px
  }
}

@media(max-width:768px) {
  .basic-page h1 {
    font-size: 32px;
    margin-bottom: 30px
  }
}

.basic-page h2 {
  font-size: 48px
}

@media(max-width:1600px) {
  .basic-page h2 {
    font-size: 32px
  }
}

@media(max-width:768px) {
  .basic-page h2 {
    font-size: 24px
  }
}

.basic-page h3 {
  font-size: 32px
}

@media(max-width:1600px) {
  .basic-page h3 {
    font-size: 24px
  }
}

@media(max-width:768px) {
  .basic-page h3 {
    font-size: 18px
  }
}

.basic-page a {
  color: var(--highlight-color);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline
}

.basic-page a:hover {
  -webkit-text-decoration: none;
  text-decoration: none
}

.basic-page ol {
  list-style: decimal inside
}

.basic-page ol>li {
  margin: 1em 0
}

.basic-page ol>li::marker {
  color: var(--highlight-color);
  font-weight: 700
}

.basic-page ol>li p:first-child {
  display: inline
}

.basic-page img {
  display: block;
  margin: 1.5em 0
}

.basic-page__video {
  margin-bottom: 2em;
  padding: 56.25% 0 0;
  position: relative
}

.basic-page__video iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.complaints {
  text-align: left;
}

.complaints-icons {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-bottom: 15px
}

.complaints-icons img {
  max-height: 66px
}</style>