<template>
  <main>
    <div class="c-games-category"><!---->
      <div>
        <section data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="games-sections__heading">
              <div data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  TRENDING GAMES
                </h1> <!---->
              </div>
            </div>
            <div>
              <div data-cy="cy-games-block" class="games-block">
                <div class="game"><!----> <a href="/slots/shining-crown" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€1,125.16</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/egt.svg" alt="Amusnet"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/egt/ShiningCrown.webp"
                        alt="Shining Crown Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/secrets-of-alchemy" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€1,125.16</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/egt.svg" alt="Amusnet"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/egt/SecretsOfAlchemy.webp"
                        alt="Secrets of Alchemy Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/blood-suckers-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€6,442.84</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/redtiger.svg" alt="Red Tiger Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/bloodsuckersmega.webp"
                        alt="Blood Suckers MegaWays Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/20-golden-coins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€1,125.16</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/egt.svg" alt="Amusnet"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/egt/20GoldenCoins.webp"
                        alt="20 Golden Coins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/sirens-cove-jackpot" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€2,894.05</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/highfive.svg" alt="High 5 Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/highfive/SirensCove.webp"
                        alt="Siren's Cove JACKPOT Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/northern-gold-rush" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/NorthernGoldRush.webp"
                        alt="Northern Gold Rush Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/tombstone" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/nolimit.svg" alt="Nolimit City"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/nolimit/Tombstone.webp"
                        alt="Tombstone Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/sizzling-eggs" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/wazdan.svg" alt="Wazdan"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/wazdan/SizzlingEggs.webp"
                        alt="Sizzling Eggs Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/diamond-riches" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/booming.svg" alt="Booming Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/booming/DiamondRiches.webp"
                        alt="Diamond Riches Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/densho" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/hacksaw.svg" alt="Hacksaw"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/Densho96.webp"
                        alt="Densho Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/werewolfs-night" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/WerewolfsNight.webp"
                        alt="Werewolf's Night Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/wild-phoenix-rises" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mascot.svg" alt="Mascot"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mascot/wild_phoenix_rises.webp"
                        alt="Wild Phoenix Rises Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/the-wild-wings-of-phoenix" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/booming.svg" alt="Booming Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/booming/TheWildWingsofPhoenix.webp"
                        alt="The Wild Wings of Phoenix Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/starlight-princess" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/StarlightPrincess.webp"
                        alt="Starlight Princess Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/88-dragons-bounty" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€100,610.62</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/belatra.svg" alt="Belatra"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/belatra/88DragonsTreasure.webp"
                        alt="88 Dragons Bounty Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/book-of-tut-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/BookofTutMegaways.webp"
                        alt="Book of Tut Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/reveal-the-kraken" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mascot.svg" alt="Mascot"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mascot/reveal_the_kraken.webp"
                        alt="Reveal The Kraken Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/book-of-panda-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/BookOfPanda.webp"
                        alt="Book of Panda Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/the-dog-house-multihold" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/TheDogHouseMultihold.webp"
                        alt="The Dog House Multihold Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/golden-tree" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/netgame.svg"
                          alt="NetGame Entertainment" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/netgame/GoldenTree.webp"
                        alt="Golden Tree Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/table-games/silver" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/Silver.webp"
                        alt="Silver" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/dragons-bonanza" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/belatra.svg" alt="Belatra"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/belatra/DragonsBonanza.webp"
                        alt="Dragon's Bonanza Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/25-coins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/wazdan.svg" alt="Wazdan"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/wazdan/25Coins.webp"
                        alt="25 Coins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/3-hot-chillies" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/3oaks.svg" alt="3 Oaks Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/3oaks/3_hot_chillies.webp"
                        alt="3 Hot Chillies Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/beast-band" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/BeastBand.webp"
                        alt="Beast Band Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/hellapenos" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/thunderkick.svg" alt="Thunderkick"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/thunderkick/Hellapenos_tk.webp"
                        alt="Hellapenos Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mystic-moon" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/technology.svg" alt="CT Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/technology/MysticMoon.webp"
                        alt="Mystic Moon Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/beast-below" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/hacksaw.svg" alt="Hacksaw"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/relax/BeastBelow96.webp"
                        alt="Beast Below Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
              </div> <!---->
              <div data-cy="cy-games-block__footer" class="games-block__footer"><!----></div>
            </div>
          </div>
        </section>
      </div>
      <section class="c-site-description">
        <div class="c-site-description__section">
          <h2>
            TRENDING GAMES
          </h2>
          <p>
            <span>
              When I personally think of the word “Trend”, I’m thinking of that black button down shirt with flames on it
              that Guy Fieri used to wear. Might have been a cool trend when I was 11 years old, but these days they’re
              better used for costume parties, or bonfires.
            </span>
            <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
          </p>
          <div :style="{ display: readMoreData }">
            <p>Trending Games at BitStarz are a collection of games that are rising in popularity, based on what our
              players are playing at the moment. The more players who play them, the higher they’re gonna place among the
              trending games.</p>
            <p>So if you’re wondering what everyone else is playing right now, this is the game category you wanna pay
              attention to.</p>
          </div>

        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>WHAT MAKES A TRENDING GAME?</h2>
          <div>
            <p>As mentioned before, a trending game is based on current popularity among our players. So if you’re not
              exactly sure what to play, or perhaps want to try something you’ve never played before, it’s a great
              selection of games to choose from.</p>
            <p>So why is that? Well, the taste in games is highly individualized of course. You might be inclined to give
              a spin on <a href="/slots/aztec-magic">Aztec Magic</a> or <a href="/slots/platinum-lightning">Platinum
                Lightning</a>, and I might gravitate more towards <a href="/slots/twerk">Twerk</a> or <a
                href="/slots/book-of-cats">Book of Cats</a>. Hey, we don’t judge.</p>
            <p>What you can find comfort in however is that the games must be trending for a reason. It’s not based on the
              opinion of us at BitStarz, but rather what the other players like to play right now. Perhaps they’re paying
              out good at the moment, have good features, or a great theme (just like Twerk).</p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>BIG PAYOUTS</h2>
          <div>
            <p>Do the trending games have bigger payouts than the other games? Well I think it depends and it’s hard to
              tell for certain, but there has to be a reason why so many players are choosing to play those particular
              games. Big wins is probably the biggest one to be fair.</p>
            <p>But it’s not just all about the big payout, but the anticipation of a potential massive win. This means
              that it could also be that they have a history of paying out big in the past, or simply people are just
              playing it because of the mechanics.</p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>BIG BONUSES</h2>
          <div>
            <p>More than likely you’ll find bonus rounds or free spins in these games. This, for the simple reason that
              most of them have these days, so I’m taking an educated guess. In most of the games these days you’ll also
              find the bonus buy feature which is an increasingly more popular feature.</p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>BIG NAMES, BIG GAMES</h2>
          <div>
            <p>Regardless of the title you’ll find amongst the trending ones, you’ll be sure to find the best game studios
              in the World. If they weren’t that, we wouldn’t expose them to you of course.</p>
            <p>Here you’ll find games from <a href="/games/nolimit">Nolimit City</a>, <a href="/games/booming">Booming
                Games</a>, <a href="/games/endorphina">Endorphina</a>, <a href="/games/bgaming">BGaming</a>, <a
                href="/games/pragmaticplay">Pragmatic Play</a> and many more (so many I can’t list them on the top of my
              head here). We have standards and so do you, so we would never include any games that weren’t top quality.
            </p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>TAKE ON THE TREND!</h2>
          <div>
            <p>Why not check them out today? I mean, if so many players are playing them there has to be a good reason,
              right? Also, it’s constantly changing, which means that your next favorite might be in this very category.
            </p>
          </div>
        </div>
      </section>

    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'TrendingGames',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex'
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  methods: {
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px)
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}</style>
