<template>
  <div class="modal" @click.self="$emit('close')">
    <div class="modal-content" :style="{ width: isMobile ? '100%' : '100%' }">
      <span class="close-icon" @click="$emit('close')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="white"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </span>
      <div class="search-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path
            fill="white"
            d="M22.49 21.07l-5.63-5.63A9.94 9.94 0 0 0 20 10c0-5.52-4.48-10-10-10S0 4.48 0 10s4.48 10 10 10c2.3 0 4.41-.77 6.13-2.07l5.63 5.63c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41zM10 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          />
        </svg>
        <input
          class="input"
          type="text"
          autocomplete="off"
          :name="randomizedName1"
          v-model="searchQuery"
          :placeholder="`${$t('Games')}, ${$t('Provider')}, ${$t(
            'Categories'
          )}`"
          @input="debouncedSearch"
        />
      </div>

      <p
        v-if="!searchQuery"
        class=""
        style="
          text-align: center;
          font-weight: normal;
          color: white;
          margin-top: 20px;
          font-size: 18px;
        "
      >
        {{ t(`Your`) }} {{ t(`search`) }} {{ t(`result will be`) }}
        {{ t(`here`) }} :
      </p>

      <p
        v-if="searchQuery"
        class=""
        style="
          text-align: center;
          font-weight: normal;
          color: white;
          margin-top: 20px;
          font-size: 18px;
        "
      >
        {{ t(`Search`) }} {{ t(`Result`) }}
        <span style="color: yellow">"{{ t(searchQuery) }}"</span>
        {{ t(`found`) }}
        <span style="color: yellow">{{ searchResults.length }}</span>
      </p>

      <div class="box">
        <div>
          <h2
            _ngcontent-uvo-c283=""
            class="section-title"
            v-if="gameCategories.length"
            style="color: white; font-size: 30px"
          >
            {{ t(`CATEGORY`) }}
          </h2>
          <ul
            v-if="gameCategories.length"
            style="display: flex; gap: 20px; flex-wrap: wrap; margin-top: 15px"
          >
            <li
              class="searchhover"
              style="
                display: flex;
                justify-content: space-between;
                color: white;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: 12px;
                font-size: 16px;
                background-color: #171617;
              "
              v-for="(item, index) in gameCategories"
              :key="index"
            >
              <a
                :href="item.link"
                class="searchhover"
                style="text-decoration: none; color: #ffffff"
                ><p class="searchhover">{{ t(item.name) }}</p></a
              >
            </li>
          </ul>
          <h2
            _ngcontent-uvo-c283=""
            class="section-title"
            v-if="providers.length"
            style="
              color: #ffffff;
              text-decoration: none;
              font-size: 30px;
              margin-top: 45px;
            "
          >
            {{ t(`PROVIDER`) }}
          </h2>
          <ul
            v-if="providers.length"
            style="display: flex; gap: 20px; flex-wrap: wrap; margin-top: 20px"
          >
            <li
              style="
                display: flex;
                justify-content: space-between;
                color: white;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: 12px;
                font-size: 16px;
                background-color: #171617;
                cursor: pointer;
              "
              v-for="(item, index) in providers"
              :key="index"
              @click="navigateToProviderGame(item.gpName, item.gpId)"
            >
              <p class="searchhover">{{ item.gpName }}</p>
            </li>
          </ul>
          <ul
            v-if="providers1.length"
            style="display: flex; gap: 20px; flex-wrap: wrap; margin-top: 20px"
          >
            <router-link
              :to="`/game-by-provider/${parseData(item.provider_name)}/${
                item.provider_id
              }/${apiProviderName}`"
              :style="{
                display: 'flex',
                justifyContent: 'space-between',
                color: 'white',
                paddingLeft: '15px',
                paddingRight: '15px',
                borderRadius: '12px',
                fontSize: '16px',
                backgroundColor: '#171617',
                cursor: 'pointer',
                textDecoration: 'none',
              }"
              v-for="(item, index) in providers"
              :key="index"
            >
              <p class="searchhover">{{ parseData(item.provider_name) }}</p>
            </router-link>
          </ul>
        </div>

        <div class="">
          <span _ngcontent-uvo-c283="" class="section-title">
            {{ t(`Top`) }}
            <span _ngcontent-uvo-c283="" class="section-title--txt">{{
              t(`games`)
            }}</span>
          </span>
        </div>
        <div class="search-result-box">
          <!-- Display search results dynamically or skeleton loading -->
          <template v-if="loading">
            <!-- Skeleton loading state -->
            <div class="skeleton-box">
              <div
                v-for="n in 18"
                :key="n"
                style="background-color: #2b2740"
                class="skeleton"
              ></div>
            </div>
          </template>
          <template v-else-if="searchResults.length === 0">
            <!-- No data found message -->
            <div
              class="search-not-found"
              style="
                margin-top: 50px;
                display: flex;
                width: 100%;
                gap: 8px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                stroke="yellow"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="8" y1="15" x2="16" y2="15" />
                <line x1="9" y1="9" x2="9.01" y2="9" />
                <line x1="15" y1="9" x2="15.01" y2="9" />
              </svg>
              <p style="font-weight: medium; color: white">
                {{ t(`No`) }} {{ t(`Search`) }} {{ t(`Found`) }}
              </p>
            </div>
          </template>
          <template v-else>
            <!-- Display search results -->

            <div
              v-for="game in searchResults"
              :key="game._id.$oid"
              class="game-item"
              @click="
                handleLoginSeemlessGame(
                  game.gameProviderId,
                  game.gameID,
                  'player'
                )
              "
            >
              <img
                class="img"
                :src="game.gameInfos[0].gameIconUrl"
                style="object-fit: cover"
                alt="Game Image"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import allData from "../../script/json/casinoprovider.json";
export default {
  props: ["isMobile"],
  data() {
    return {
      searchQuery: "",
      searchResults: [],
      loading: false,
      providers: [],
      gameCategories: [],
      allGameCategories: allData.gameCategory,
      allProviders: allData.all_providers.slice(2, 100),
      SITEAUTHKEY: "",
      gameCategories1: [],
      providers1: [],
    };
  },
  mounted() {
    const siteAuthKey = process.env.VUE_APP_API_SITE_AUTH_KEY;
    this.SITEAUTHKEY = siteAuthKey;
    this.fetchProvider1();
    this.fetchProvider2();
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    navigateToProviderGame(providerName, provider) {
      window.location.href = `/game-provider/${providerName}/${provider}`;
    },
    getGameIconUrl(game) {
      // Check the language key here and return the appropriate URL
      return game.gameInfos[0]; // English URL
    },
    async handleLoginSeemlessGame(gpid, gameid, mode) {
      // console.log("njnju");
      const token = localStorage.getItem("userToken");
      const username_token = localStorage.getItem("usernameToken");
      const username = localStorage.getItem("username");
      if ((!token || !username_token || !username) && mode === "player") {
        toast("Please login first.");
        return;
      }
      window.location.href = `/game/casino/${gpid}/${gameid}?mode=${mode}`;
    },
    parseData(string) {
      if (!string) {
        return "Invalid input"; // Handle null or empty input
      }
    },

    async fetchProvider1() {
      try {
        this.loading = true;
        let url = `${process.env.VUE_APP_API_KEY}/api/game/get-all-game?status=true&page=1&limit=50&site_auth_key=${this.SITEAUTHKEY}`;
        if (this.searchQuery) {
          url += `&search=${this.searchQuery}`;
        }
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          method: "GET",
          headers: headers,
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        this.searchResults = data.data;

        if (this.searchQuery) {
          this.gameCategories = this.allGameCategories.filter((item) =>
            item.name.toLocaleLowerCase().includes(this.searchQuery)
          );
          this.providers = this.allProviders.filter((item) =>
            item.gpName.toLocaleLowerCase().includes(this.searchQuery)
          );
        }
        if (this.searchQuery == "") {
          this.gameCategories = [];
          this.providers = [];
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchProvider2() {
      try {
        this.loading = true;

        let url = `${process.env.VUE_APP_API_KEY}/api/secondary-provider/get-provider?limit=50&status=true&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        if (this.searchQuery) {
          url += `&search=${this.searchQuery}`;
        }
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          method: "GET",
          headers: headers,
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data, "provider search");
        this.searchResults = data.data;

        if (this.searchQuery) {
          // this.gameCategories1 = this.allGameCategories.filter((item) =>
          //   item.name.toLocaleLowerCase().includes(this.searchQuery)
          // );
          // this.providers1 = this.allProviders.filter((item) =>
          //   item.gpName.toLocaleLowerCase().includes(this.searchQuery)
          // );
          this.gameCategories1 = data?.data;
        }
        if (this.searchQuery == "") {
          this.gameCategories1 = [];
          this.providers1 = [];
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    debouncedSearch: function () {
      if (this.debouncedTimer) {
        clearTimeout(this.debouncedTimer);
      }
      this.debouncedTimer = setTimeout(() => {
        this.fetchProvider1();
        this.fetchProvider2();
      }, 300);
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #3b3955;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.game-item {
  display: flex;
  align-items: start;
  justify-content: start;
  cursor: pointer;
  /* width:180px; */
  width: 100%;
  height: 260px;
}
.skeleton-box {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.skeleton {
  width: 190px;
  height: 240px;
  border-radius: 8px;
}
.section-title {
  font-size: 40px;
  line-height: 130%;
  font-weight: 900;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
  animation: showAnimation 1s ease 0.5s forwards;
  text-transform: uppercase;
  font-style: italic;
  color: #dfe4e8;
  max-width: 51%;
}
.section-title--txt {
  font-size: 25px;
  line-height: 130%;
  font-weight: 900;
  display: inline-block;
  margin-left: 6px;
  margin-bottom: 3px;
  color: rgb(var(--basicBg));
}
.modal-content {
  background-color: #3b3955;
  height: 100vh;
}
.search-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #2b2740;
  padding-left: 25px;
}
.close-icon {
  position: absolute;
  top: 0px;
  right: 20px;
  cursor: pointer;
  font-size: 50px;
}

.input {
  outline: none;
  padding: 6px;
  background-color: #2b2740;
  border: none;
  border-radius: 6px;
  padding: 10px;
  font-size: 20px;
  width: 95%;

  color: white;
}
.input::placeholder {
  color: #cbd5e1;
  padding-left: 5px; /* Adjust padding as needed */
  font-family: Arial, sans-serif;
}
.search-result-box {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
  width: 100%;
}

.box {
  height: 90vh;
  overflow: scroll;
  padding: 20px;
  padding-bottom: 100px;
}

.box::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
}
.searchhover:hover {
  color: #8938d5;
}

.search-result-box > img {
  width: 100%;
  height: 100%;
}

.game-item {
  position: relative;
}
.search-not-found {
  margin-left: 300%;
}
/* .game-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    70,
    70,
    69,
    0.7
  ); 
  opacity: 0; 
  transition: opacity 0.3s ease;
  border-radius: 16px;
} */

.game-item:hover .game-overlay {
  opacity: 1; /* Show on hover */
}

.play-button,
.demo-link {
  cursor: pointer; /* Add cursor pointer */
}

.play-button {
  color: #252525;
  background-color: #ffff69;
  padding: 12px 20px;
  border-radius: 20px;
  margin: 0 5px;
  width: 60%;
  text-decoration: none;
  text-align: center;
}

.demo-link {
  color: #c1c1c1;
  text-decoration: underline;
  margin-top: 10px;
}
.img {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  margin-left: -10px;
}

@media screen and (max-width: 1400px) {
  .search-result-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  .search-not-found {
    margin-left: 200%;
  }
  .img {
    height: 100%;
    width: 100%;
  }
  .box {
    padding: 30px;
  }
  .skeleton-box {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }

  .skeleton {
    width: 210px;
    height: 320px;
    border-radius: 8px;
  }
}
@media screen and (max-width: 1000px) {
  .search-result-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  .search-not-found {
    margin-left: 150%;
  }
  .input {
    outline: none;
    padding: 6px;
    border-radius: 6px;
    width: 90%;
    color: white;
  }
  .box {
    padding: 20px;
  }
  .img {
    height: 100%;
    width: 100%;
  }
  .game-item {
    height: 230px;
  }
  .skeleton-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .skeleton {
    width: 200px;
    height: 310px;
    border-radius: 8px;
  }
}
@media screen and (max-width: 578px) {
  .search-result-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .skeleton-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .skeleton {
    width: 150px;
    height: 170px;
    border-radius: 8px;
  }
  .input {
    outline: none;
    padding: 6px;
    border-radius: 6px;

    width: 80%;
    color: white;
    margin-left: 5px;
  }
  .img {
    height: 100%;
    width: 100%;
  }
  .search-not-found {
    margin-left: 100%;
  }
  .box {
    padding: 9px;
  }
  .search-header {
    padding: 10px;
  }
  .close-icon {
    position: absolute;
    top: -6px;
    right: 20px;
    cursor: pointer;
    font-size: 50px;
  }
  .game-item {
    height: 160px;
  }
}

@media screen and (max-width: 578px) {
  .skeleton-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
  }

  .skeleton {
    width: 120px;
    height: 150px;
    border-radius: 8px;
  }
}

.play-button:hover,
.demo-link:hover {
}
/* Skeleton loading styles */
.game-item.skeleton {
  background-color: #3c3f41; /* Skeleton color */
  border-radius: 10px;
  margin-bottom: 10px;
}
</style>
