<template>
  <main>
    <div class="c-games-category"><!---->
      <div>
        <section data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="games-sections__heading">
              <div data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  CLASSIC SLOTS
                </h1> <!---->
              </div>
            </div>
            <div>
              <div data-cy="cy-games-block" class="games-block">
                <div class="game"><!----> <a href="/slots/bitstarz-billion" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-exclusive"><!----> <span
                            class="c-game-badge__text">Exclusive</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/BitstarzBillion.webp"
                        alt="Bitstarz Billion Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/forty-fruity-million" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/FortyFruityMillion.webp"
                        alt="Forty Fruity Million Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/won-hundred" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamzix.svg" alt="Gamzix"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamzix/WonHundred.webp"
                        alt="Won Hundred Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruityliner-40" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mancala.svg" alt="Mancala Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mancala/Fruityliner40.webp"
                        alt="Fruityliner 40 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/triple-double-da-vinci-diamonds" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/highfive.svg" alt="High 5 Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/highfive/TripleDoubleDaVinciDiamonds.webp"
                        alt="Triple Double Da Vinci Diamonds Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/slot-machine" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/SlotMachine.webp"
                        alt="Slot Machine Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/10-fruitata-wins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/technology.svg" alt="CT Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/technology/10FruitataWins.webp"
                        alt="10 Fruitata Wins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mega-lucky-100" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/MegaLucky100.webp"
                        alt="Mega Lucky 100 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/juicy-win-hold-the-spin" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamzix.svg" alt="Gamzix"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamzix/JuicyWinHoldTheSpin.webp"
                        alt="Juicy Win: Hold The Spin Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/lucky-clover-243" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/LuckyClover243.webp"
                        alt="Lucky Clover 243 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruit-fiesta-3-reel" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/microgaming.svg" alt="Microgaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_fruitFiesta3ReelDesktop.webp"
                        alt="Fruit Fiesta 3-Reel Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruits-royale-5" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/fugaso.svg" alt="FUGASO"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/groove/FruitsRoyale5.webp"
                        alt="Fruits Royale 5 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mega-diamonds-jackpot" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/microgaming.svg" alt="Microgaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_megaDiamondsJackpotDesktop.webp"
                        alt="Mega Diamonds Jackpot Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/inferno-777-re-spins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/fugaso.svg" alt="FUGASO"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/groove/Inferno777Respins.webp"
                        alt="Inferno 777 Re-spins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/40-super-blazing-sevens" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gameart.svg" alt="GameArt"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gameart/40SuperBlazingSevens.webp"
                        alt="40 Super Blazing Sevens Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/extreme-fruits-ultimate-deluxe" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/playtech.svg" alt="Playtech"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/playtech/ExtremeFruitsUltimateDeluxe.webp"
                        alt="Extreme Fruits Ultimate Deluxe Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruity-christmas" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/FruityChristmas.webp"
                        alt="Fruity Christmas Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/100-power-hot" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span><span class="c-game-badge is-jackpot"><!---->
                          <span class="c-game-badge__text">€1,135.07</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/egt.svg" alt="Amusnet"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/egt/100PowerHot.webp"
                        alt="100 Power Hot Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/froot-loot-5-line" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/microgaming.svg" alt="Microgaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_frootLoot5Desktop.webp"
                        alt="Froot Loot 5-Line Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/40-fruitata-wins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/technology.svg" alt="CT Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/technology/40FruitataWins.webp"
                        alt="40 Fruitata Wins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/lucky-easter-243" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/LuckyEaster243.webp"
                        alt="Lucky Easter 243 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/wild-lady-cash-10" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/WildLadyCash10.webp"
                        alt="Wild Lady Cash 10 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/coin-win-hold-the-spin" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamzix.svg" alt="Gamzix"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamzix/CoinWinHoldTheSpin.webp"
                        alt="Coin Win: Hold The Spin Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/froot-loot-9-line" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/microgaming.svg" alt="Microgaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_frootLootDesktop.webp"
                        alt="Froot Loot 9-Line Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruits-royale" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/fugaso.svg" alt="FUGASO"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/groove/FruitsRoyale.webp"
                        alt="Fruits Royale Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruit-salad-3-reel" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/microgaming.svg" alt="Microgaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_fruitSalad3ReelDesktop.webp"
                        alt="Fruit Salad 3-Reel Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/hot-lucky-7s" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bsg.svg" alt="Betsoft Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/bsg/HotLucky7s.webp"
                        alt="Hot Lucky 7’s Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/cashn-fruits-27-hold-and-win" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/CashnFruits27HoldAndWin.webp"
                        alt="Cash'n Fruits 27 Hold And Win Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/cashn-fruits-hold-and-win" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/CashAndFruitsHoldAndWin.webp"
                        alt="Cash'n Fruits Hold and Win Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/coinsn-diamonds-x" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/CoinsnDiamondsX.webp"
                        alt="Coins'n Diamonds X Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/golden-joker-100" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/GoldenJoker100.webp"
                        alt="Golden Joker 100 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/warriors-luck" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/WarriorsLuck.webp"
                        alt="Warrior’s Luck Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/froot-loot-3-reel" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/microgaming.svg" alt="Microgaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/quickfire/MGS_frootLoot3ReelDesktop.webp"
                        alt="Froot Loot 3-Reel Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mega-booming-diamonds" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/MegaBoomingDiamonds.webp"
                        alt="Mega Booming Diamonds Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mega-multi-diamonds" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/highfive.svg" alt="High 5 Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/highfive/MegaMultiDiamonds.webp"
                        alt="Mega Multi Diamonds Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruit-machine-megabonus" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mascot.svg" alt="Mascot"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mascot/fruit_machine_megabonus.webp"
                        alt="Fruit Machine Megabonus Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/100-lucky-sevens" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gameart.svg" alt="GameArt"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gameart/100LuckySevens.webp"
                        alt="100 Lucky Sevens Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/cashn-fruits-x" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/CashnFruitsX.webp"
                        alt="Cash'n Fruits X Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/ultra-luck" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamzix.svg" alt="Gamzix"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamzix/UltraLuck.webp"
                        alt="Ultra Luck Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruit-machine-x25" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mascot.svg" alt="Mascot"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mascot/fruit_machine_x25.webp"
                        alt="Fruit Machine x25 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mega-lucky-576" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/MegaLucky576.webp"
                        alt="Mega Lucky 576 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/really-hot-2" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamzix.svg" alt="Gamzix"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamzix/ReallyHot2.webp"
                        alt="Really Hot 2 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/brumbys-243" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/Brumbys243.webp"
                        alt="Brumby's 243 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruityliner-5" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mancala.svg" alt="Mancala Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mancala/Fruityliner5.webp"
                        alt="Fruityliner 5 Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/wild-lady-cash" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/WildLadyCash.webp"
                        alt="Wild Lady Cash Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/40-bulky-fruits" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€1,135.07</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/egt.svg" alt="Amusnet"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/egt/40BulkyFruits.webp"
                        alt="40 Bulky Fruits Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/3x-super-peppers" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/belatra.svg" alt="Belatra"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/belatra/3xSuperPeppers.webp"
                        alt="3x Super Peppers Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/3x3-hell-spin" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamzix.svg" alt="Gamzix"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamzix/3X3HellSpin.webp"
                        alt="3X3: Hell Spin Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/happy-rabbit-27-ways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamzix.svg" alt="Gamzix"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamzix/HappyRabbit27Ways.webp"
                        alt="Happy Rabbit: 27 Ways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/lucky-cashnfruit" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/LuckyCashnFruits.webp"
                        alt="Lucky Cash'n'Fruit Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/crown-of-fire" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/CrownofFire.webp"
                        alt="Crown of Fire Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruit-story" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamzix.svg" alt="Gamzix"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamzix/FruitStory.webp"
                        alt="Fruit Story Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/allways-lucky-spins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/AllwaysLuckySpins.webp"
                        alt="Allways Lucky Spins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/inner-fire" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evoplay.svg" alt="Evoplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evoplay/InnerFire.webp"
                        alt="Inner Fire Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/777-vegas-showtime" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mancala.svg" alt="Mancala Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mancala/777VegasShowtime.webp"
                        alt="777 Vegas Showtime Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/hot-volcano" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evoplay.svg" alt="Evoplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evoplay/HotVolcano.webp"
                        alt="Hot Volcano Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fruits-gold" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-jackpot"><!----> <span
                            class="c-game-badge__text">€1,135.07</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/egt.svg" alt="Amusnet"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/egt/FruitsandGold.webp"
                        alt="Fruits &amp; Gold Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/fortune-three" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamebeat.svg" alt="Gamebeat"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamebeat/FortuneThree.webp"
                        alt="Fortune Three Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/reel-reel-hot" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mancala.svg" alt="Mancala Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mancala/ReelReelHot.webp"
                        alt="Reel Reel Hot Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/barsandbells" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/mancala.svg" alt="Mancala Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/mancala/BarsAndBells.webp"
                        alt="#BarsAndBells Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/lucky-x-bells" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/LuckyXBells.webp"
                        alt="Lucky X Bells Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/smoking-hot-fruits-20-lines" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1x2gaming.svg" alt="1x2 Gaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1x2gaming/SmokingHotFruits20Lines.webp"
                        alt="Smoking Hot Fruits 20 Lines Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/9-gems" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/platipus.svg" alt="Platipus"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/platipus/ninegems.webp"
                        alt="9 Gems Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
              </div> <!---->
              <div data-cy="cy-games-block__footer" class="games-block__footer"><button
                  class="c-button games-block__button size-l" data-cy="cy-games-block__button"><!----> <span>Load
                    more</span> <!----></button></div>
            </div>
          </div>
        </section>
      </div>
      <section class="c-site-description">
        <div class="c-site-description__section">
          <h2>
            CLASSIC SLOTS
          </h2>
          <p>
            <span>
              Classics never go out of style. I mean, there are people still playing gameboy and 8-bit nintendo these
              days. Not because the graphics are awesome, but for two other reasons; nostalgia and mechanics. Btw, the
              same obviously goes for toasters, cars, and slot games.
            </span>
            <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
          </p>

        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>WHAT IS A CLASSIC SLOT?</h2>
          <div>
            <p>Before there were bonus rounds, free spins features, and themes such as Family Guy, you had the basic
              mechanics and simplistic themes. I think there are some symbols that we also associate with the classics
              that are still used today, such as cherries, lemons, BAR, Diamonds, etc.</p>
            <p>One thing that also comes to mind when you think of a classic slot is three reels and nine paylines. Things
              were in other words pretty simple back in the day, but there’s a lot of beauty in simplicity.</p>
            <p>To put things simply, there’s no need to keep track of 29,317 paylines, jackpots, features, stacked wilds,
              walking wilds, expanding wilds, exploding wilds, all over the place wilds. Yeah, you get the point.
              Sometimes life is complicated as it is, and a throwback to more simple times is needed.</p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>BACK TO THE FUTURE</h2>
          <div>
            <p>If you do think that the really old dusty classic slots are too simple, you’re in luck. Because many Game
              Studios these days have revamped classic slots with better graphics. Basically a refurbished version.</p>
            <p>Some have polished the symbols, included more reels, added some jackpot or free spins feature, yeah,
              everyone has their own unique twist. There’s also Game Providers that have truly pimped out these machines
              to the max with all kinds of stuff. Kind of takes away from the whole idea of Classics but who are we to
              judge?</p>
            <p>As we always say at bitleon, we believe in having a good mix of every game category. Just because we’re
              puritans who like the old dusty classics which were probably left at the old Las Vegas Stardust Casino when
              it was demolished, it doesn't mean that’s what everyone likes.</p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>EVERYONE LOVES THE JOKER</h2>
          <div>
            <p>Simply because they’re old, it doesn’t mean they lack the payouts that you’re able to land in the new ones.
              Take the game <router-link to="/slots/fire-joker">Fire Joker</router-link> from Play’n GO as an example, where one of our
              players landed an awesome $227,755 on a single spin.</p>
            <p>Shameless as we occasionally are, we’d also like to plug our very own Classic Slots, <router-link
                to="/slots/bitstarz-billion">bitleon Billion</router-link> and <router-link to="/slots/20-bitstarz">20 bitleon</router-link>. If
              you like them, let us know so we can produce more, if you hate them, we’ll abandon them just like the
              Stardust Casino in Vegas did theirs.</p>
          </div>
        </div>
        <div :style="{ display: readMoreData }" class="c-site-description__section">
          <h2>THE CLASSICS ARE FOREVER COOL!</h2>
          <div>
            <p>Once again, people still like retro stuff till this day. Graphics will always improve, but a great idea and
              mechanics are timeless. You can never compensate for terrible game play with cutting-edge graphics, but the
              opposite will always be true and stand the test of time.</p>
          </div>
        </div>


      </section>

    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'ClassicSlots',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex'
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  methods: {
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px)
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

.c-game-container .c-game-box img {
  border-radius: 10px;
}</style>
