<template>
  <main>
    <div class="basic-page bonus-terms is-page">
      <h1>
        Bonus Terms and Conditions
      </h1>
      <ol>
        <li>
          <h2>No Deposit Bonuses Terms and Conditions</h2>
          <ol>
            <li>
              <p>The maximum winnings that will be paid out resulting from a bonus or free spins that you've gotten from
                us for free, without any deposit being required (eg. the 20 free spins upon registration, Weekly Free
                Spins Drops, Second Chance Spins, Friday Loyalty Bonus (exception VIP players who have specifically been
                set on a VIP Cashback Deal as part of their Friday Loyalty Bonus)) will be 100 EUR/USD/CAD/AUD/NZD/USDT,
                300 BRL, 1,000 NOK, 0.408 BCH, 1.497 LTC, 0.070 ETH, 1,250 DOGE, 10,000 JPY, 400 PLN, or 6,000 RUB. In
                regards to BTC players, the maximum winnings that will be paid out resulting from a free bonus or free
                spins without deposit will be 2.14 mBTC. Any winnings exceeding this amount will be forfeited.</p>
            </li>
            <li>
              <p>The Wagering Requirements for Free and Registration bonuses are forty (40) times the Bonus sum awarded to
                you, unless stated otherwise in the supplemental Terms and Conditions. (For Registration bonuses, extra
                spins, free spins and free bonuses which require NO deposit, note that no winnings at all may be
                withdrawn/transferred until you have transferred at least 20 EUR/USD/CAD/AUD/NZD/USDT, 100 BRL, 200 NOK,
                1,500 RUB, 0.082 BCH, 0.299 LTC, 250 DOGE, 3000 JPY, 80 PLN, 0.070 ETH or 0.00080 BTC (or currency
                equivalent) into your Player Account. In addition, no winnings accrued in connection with any Free Bonus
                may be withdrawn/transferred until the wagering requirements have been met.)</p>
            </li>
            <li>
              <p>For no deposit bonus withdrawals, we reserve the right to request a KYC. Regarding the KYC (Know Your
                Customer), the following documents may be needed for verification: One government approved identity card
                (ID card, passport and driver's license) with a picture, as well as proof of residency such as a utility
                bill (which has been issued in the past 90 days). If your deposit was via a credit card we will require a
                photo of the credit card (front and back) showing all 4 corners.</p>
            </li>
            <li>
              <p>General bonus terms and conditions apply.</p>
            </li>
            <li>
              <p>Players with disposable email addresses are not eligible for any free spins without deposit. If a player
                despite this would receive free spins without a deposit being made, all winnings from the spins will be
                confiscated.</p>
            </li>
            <li>
              <p>In order to receive any no deposit free spins as part of the registration process, you need to verify
                your account via email. On some occasions, a SMS verification will be required.</p>
            </li>
            <li>
              <p>Players from the following countries are not eligible for the no deposit free spins: Algeria (DZ),
                Austria (AT), Bahrain (BH), Bangladesh (BD), Benin (BJ), Bosnia and Herzegovina (BA), Botswana (BW),
                Bulgaria (BG), Brazil (BR), Burundi (BI), Cameroon (CM), Cape Verde (CV), Chad (TD), Comoros (KM), Croatia
                (HR), Djibouti (DJ), Egypt (EG), Equatorial Guinea (GQ), Finland (FI), Gabon (GA), Gambia (GM), Germany
                (DE), Ghana (GH), Greece (GR), Guinea (GN), Guinea-Bissau (GW), India (IN), Indonesia (ID), Jordan (JO),
                Kenya (KE), Kosovo (XK), Kuwait (KW), Lesotho (LS), Republic of North Macedonia (MK), Madagascar (MG),
                Malawi (MW), Malaysia (MY), Mauritania (MR), Mongolia (MN), Montenegro (ME), Morocco (MA), Mozambique
                (MZ), Namibia (NA), Nepal (NP), Niger (NE), Nigeria (NG), Oman (OM), Palestine State of (PS), Philippines
                (PH), Poland (PL), Portugal (PT), Romania (RO), Sao Tome and Principe (ST), Senegal (SN), Seychelles (SC),
                Slovenia (SI), Sri Lanka (LK), Swaziland (SZ), Tanzania United Republic of (TZ), Thailand (TH), Togo (TG),
                Tunisia (TN), Ukraine (UA), United Arab Emirates (AE), Vietnam (VN) and Zambia (ZM)</p>
            </li>
          </ol>
        </li>
        <li>
          <h2>Free Spins Terms and Conditions</h2>
          <ol>
            <li>
              <p>For offers that provide free spins or other rewards after a certain period following the qualifying
                deposit (e.g. the 180 free spins in the Welcome Offer or Wednesday Free Spins), the qualifying deposit
                must be wagered at least once to meet the offer requirements. Specifically, for the 180 free spins in the
                Welcome Offer, the deposit must be wagered at least once within 24 hours of depositing. For all other
                cases (unless specified otherwise), the qualifying deposit must be wagered at least once before the offer
                expires. If a deposit is not wagered within the specified time, it will not qualify for the promotion.</p>
            </li>
            <li>
              <p>You do not have to enter a bonus code to qualify for free spins.</p>
            </li>
            <li>
              <p>Any winnings generated from free spins will be awarded with a wagering requirement of 40 (forty).</p>
            </li>
            <li>
              <p>To activate your free spins you must access and open the game within 1 day of them being credited,
                otherwise they will expire.</p>
            </li>
            <li>
              <p>Once your free spins have been activated you must complete all game rounds within 1 days otherwise they
                will expire.</p>
            </li>
            <li>
              <p>bitleon reserves the right to cancel or amend these terms &amp; conditions at any point without any
                prior notice.</p>
            </li>
            <li>
              <p>The 180 Free Spins that are being credited as a part of our welcome package will be credited in
                increments of 20 per day (as long as the player qualifies and meets all the requirements for the
                promotion). First 20 are credited right after your first deposit, and then 20 more 24 hours later. This
                will go on for 8 straight days until you get a total of 180. Please note, cancelling any part of the
                welcome package at any stage prior to completion, will forfeit the rest of the offer.</p>
            </li>
            <li>
              <p>What games you can use your free spins on depends on what country you're in. Kindly check the bonus
                section once logged into your account to see which games.</p>
            </li>
            <li>
              <p>Wednesday Free Spins are only issued under a single currency, the last active currency used during the
                campaign period.</p>
            </li>
            <li>
              <p>Kindly note that you have to have made 4 deposits prior to participating in the Wednesday Free Spins
                promotion to be eligible for the free spins. The same applies to the Reload Monday promotion. These 4
                deposits do not include the ones that are needed for claiming the promotional offer. To participate in the
                level up adventure you must have made one deposit lifetime.</p>
            </li>
            <li>
              <p>Making a cash out to another bitleon account for the purpose of receiving a bonus is not permitted. If
                such actions are practiced, bitleon reserves the right to confiscate any winnings derived from the bonus.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>General Bonus Terms and Conditions</h2>
          <ol>
            <li>
              <p>All customer offers are limited to one per person, family, household address, email address, telephone
                number, same payment account number (e.g. debit or credit card, NETeller etc), IP, or shared computer,
                e.g. public library or workplace.</p>
            </li>
            <li>
              <ol>
                <li>
                  <p>We reserve the right to not pay players using disposable email addresses</p>
                </li>
                <li>
                  <p>Players from the following countries are not eligible for any promotional offer, unless stated
                    otherwise in the Promotion rules: Slovenia (SI), Croatia (HR), Bosnia and Herzegovina (BA), Republic
                    of North Macedonia (MK), Montenegro (ME), Austria (AT), Kosovo (XK) and Finland (FI).</p>
                </li>
                <li>
                  <p>We reserve the right to lower the percentage of the Reload Monday bonus to a 25% bonus for players
                    who we deem have received an un-proportionate level of bonuses based on their bonus release to deposit
                    ratio.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>Once the first step of the welcome package is activated, the customer can then switch currency as
                follows, i.e. if a player deposits €20 and activates the first step of welcome package, he can then claim
                the second step by depositing in bitcoins.</p>
            </li>
            <li>
              <p>All bonuses (unless otherwise specified) need to be wagered 40 (forty) times before the funds can be
                withdrawn.</p>
            </li>
            <li>
              <p>Please note that different games contribute to a different percentage towards the wagering requirements.
                Slots contribute 100% (aside from the excluded ones below), while all table games, video poker games, Jogo
                do Bicho, live games and bitleon Originals (excluding the bitleon Originals Slot game) contribute 5%.
              </p>
            </li>
            <li>
              <p> The following games do not contribute towards the completion of the wagering requirement of a bonus:
                Immortal Romance, Aliens, Mega Moolah, Ragnarok, Pocket Dice, Lucky Angler, Eggomatic, Big Bang, The Dark
                Knight, Peek-A-Boo, Tomb Raider, The Dark Knight Rises, Forsaken Kingdom, Scrooge, Secret of the Stones,
                Terminator 2, Champion of the Track, Robin Hood, Tomb Raider 2, Queen of Gold, Castle Builder (II), Game
                of Thrones, Playboy, Ozwin`s Jackpots, Vampire: The Masquerade - Las Vegas, Pimped, Tut`s Twister,
                Stardust, Divine Forest, 300 Shields, Super Fast Hot Hot Respin, Wolf Hunters, Tree of Fortune, Bikini
                Party, MegaBoy, Pearls of India, Sea Hunter, Eye of the Kraken, Double Dragons, Hot Ink, Pumpkin Patch,
                Art of the Heist, Dragon Dance, Wild Orient, Retro Reels, Retro Reels Extreme Heat, Retro Reels Diamond
                Glitz, Craps, Max Quest: Wrath of Ra, Pinocchio, Split Way Royal, Super 7 Blackjack, Tens or Better, Three
                Card Rummy, Triple Edge Poker, WhoSpunIt Plus, Zoom Roulette, VIP European Roulette, London Hunter, Reel
                Steal, Robin Hood: Shifting Riches, Single Deck Blackjack Professional Series, The French Roulette, TXS
                Hold'em Professional Series, Jackpot Jester 200000, Golden Legend, Happy Halloween, MULTIFRUIT 81, Royal
                Masquerade, Tower Quest, Hugo 2, Astro Legends: Lyra and Erion, Beautiful Bones, Cool Buck, Medusa, Reel
                Gems, Spectacular Wheel of Wealth, Untamed Bengal Tiger, Untamed Crowned Eagle, Untamed Giant Panda,
                Untamed Wolf Pack, Wheel of Wealth, Wheel of Wealth Special Edition, Book of Oz, Alchymedes, Dark Vortex,
                Holmes and the Stolen Stones, Jokerizer, Spina Colada, The Dark Joker Rizes, Vikings Go Berzerk, Vikings
                go to Hell, Wicked Circus, Scarab Treasure, Rage to Riches, Gems Odyssey, Cazino Cosmos, Baker's Treat,
                Lemur Does Vegas, Zombie Hoard, Bookie of Odds, Scudamore's Super Stakes, Sugar Pop and Sugar Pop 2:
                Double Dipped, Fruitbat Crazy, Santa's Village. All Belatra slots are excluded as well, except: Venetian
                Rain, Customs Storage, Jingle Bells, 7 Days of the Spanish Armada, Neptune's Kingdom, Dracula Riches.</p>
              <p>Bloodsuckers, Bloodsuckers touch, Kings of Chicago, Simsalabim, Zombies, Jack Hammer, Jack Hammer 2, Jack
                Hammer 2 touch, Demolition Squad, Steam Tower, Reel Rush, Reel Rush touch, Muse: Wild Inspiration,
                Victorious, Victorious touch, Ghost Pirates, Johnny the Octopus and Mega Gems contribute at a 50% rate.
                Jackpot 6000, Mega Joker, Gypsy Rose and Safari contribute at a 75% rate.</p>
              <p>Kindly note that Ninja, Dr. Jekyll &amp; Mr. Hyde, Devil's Delight, The Wish Master, Dead or Alive,
                Pimped, Stardust, Pinocchio Vampire: The Masquerade - Las Vegas、1429 Uncharted Seas, Marching Legions,
                Golden Beauty and Jackpot Games are not able to be played with bonus money.</p>
            </li>
            <li>
              <p>Players can request a withdrawal of their deposited amount prior to meeting the wagering requirements.
                However, the bonus amount and winnings will be forfeited as a result. Please note that when playing with a
                bonus, real money gets exhausted first.</p>
            </li>
            <li>
              <p>All bonuses that are a part of the Welcome Package are valid for 7 days. all other bonuses (unless
                otherwise stated) will expire after 24 hours from being issued.</p>
            </li>
            <li>
              <p>Bonus funds and winnings will be forfeited upon expiry of the bonus.</p>
            </li>
            <li>
              <p>When betting, placed bets are deducted from the player’s cash balance. However, if no cash balance is
                available the bet placed will be deducted from the player’s bonus balance.</p>
            </li>
            <li>
              <p>Until the play through requirements have been met, the maximum bet that is allowed to be placed is 5
                EUR/USD/CAD/AUD/NZD/USDT, 25 BRL, 50 NOK, 0.05 BCH, 0.003 ETH, 75 Dogecoin, 0.06 LTC, 20 PLN, 500 JPY or
                300 RUB. When it comes to Bitcoin players the maximum bet is 0.2 mBTC. This includes double up wagers
                after the game round has been completed, for example, wagering winnings from X game round on red/black,
                and also in-game bonus features that can be purchased.</p>
            </li>
            <li>
              <p>Any bonus can be removed prior to any wagering conditions being met. Please contact bitleon support to
                learn more or use the forfeit button under ’Bonuses’.</p>
            </li>
            <li>
              <p>Only one bonus can be claimed at a time. Deposit related bonuses cannot be "stacked". bitleon.com
                management reserve the right to void any bonuses and/or winnings obtained by fraudulent behavior.</p>
            </li>
            <li>
              <p>Any "free spin" or "bonus" winnings resulting from bonus funds after the bonus has been wagered, lost or
                forfeited shall be removed.</p>
            </li>
            <li>
              <p>All withdrawals will be subject to an internal audit before being processed. bitleon reserves all rights
                to void Bonuses or any winnings for failed audits. Player hereby consents in advance to same.</p>
            </li>
            <li>
              <p>If, upon such a review, it appears that a Player(s) are taking advantage of any software or system bug or
                failure, or participating in any form of activity that bitleon, in its sole and complete discretion,
                deems to be abusive ('Promotion Abuse'), bitleon reserves the right to revoke the entitlement of such a
                Player to receive or benefit from the promotion (and/or withhold the pay out of the proceeds of such abuse
                to the Player/s in question). Only fully settled bets (i.e. bets that result in a win or loss) will be
                counted towards wagering.</p>
            </li>
            <li>
              <p>Multiple accounts are not permitted. Creating more than one accounts with bitleon in order to claim any
                bonuses is deemed as bonus abuse and may result in confiscated funds.</p>
            </li>
            <li>
              <p>Bonus round and free spins do not qualify for the jackpot bonuses pursuant to casino software provider
                rules. Only real money rounds qualify for the jackpot bonuses.</p>
            </li>
            <li>
              <p>Minimal risk bets on any games (i.e. betting in proportions on different outcomes in the same hand to
                create "action" with minimal risk) do not qualify for completing the wagering requirement. Examples of
                minimal risk bets include betting on red and black simultaneously in Roulette, and betting player and
                banker simultaneously in Baccarat.</p>
            </li>
            <li>
              <p>If it's discovered that a group players are using the same betting patterns and are connected via (but
                not limited to) location, banking pattern or IP, bitleon management will at it's own discretion have the
                mandate to confiscate bonus winnings and/or deposit.</p>
            </li>
            <li>
              <p>If not stated otherwise, bonus money derived from free spins given on Bgaming games can only be wagered
                on other Softswiss games.</p>
            </li>
            <li>
              <p>If, while playing at bitleon Casino, you win a sum regarded by bitleon Management as worthy of
                publicity, you agree to make yourself available for any event of such nature arranged by bitleon. While
                bitleon protects all personal data entrusted to us, we reserve the right to use first names and the first
                initial of the last name in any bitleon announcement about promotion results or on the website or lobby.
              </p>
            </li>
            <li>
              <p>Regarding the bonuses and promotions, all times and dates are stated in CET/CEST.</p>
            </li>
            <li>
              <p>When accepting a deposit bonus, kindly remember that your deposit is locked with the accepted bonus. This
                means that you have two options, either to complete the wagering requirement of the bonus, or cancel the
                bonus. If you choose to cancel the bonus, the bonus and all winnings attributed to the bonus will be lost.
                Wagering with both the bonus money and your deposited cash will count towards the wagering requirement.
              </p>
            </li>
            <li>
              <p>In the event of any dispute, the decision of bitleon will be final.</p>
            </li>
            <li>
              <p>Kindly note that it's not allowed to use bonus funds purely to progress through the bonus stages and then
                final stages completed with cash bets when bonus funds have been released or forfeited. If such tactic is
                adopted, bitleon reserves the right to confiscate the winnings.</p>
            </li>
            <li>
              <p>Kindly note that if your balance drops below $0.05 or currency equivalent, the bonus will be forfeited.
                Furthermore, the adoptation of "Bonus Hunting" by reaching a bonus feature, exiting the game to later go
                back and play the feature is not permitted either. Doing this could result in confiscation of winnings
                related to this.</p>
            </li>
            <li>
              <p>If you upon deposit use a promo code for a promotion you are not eligible for, you will not receive the
                bonus.</p>
            </li>
            <li>
              <p>Deposit bonuses will be added instantly. If you're eligible for a deposit offer and do not receive it,
                you need to contact the customer support before you start playing. Failure to do so will disqualify you
                from receiving the bonus retroactively.</p>
            </li>
            <li>
              <p>Please note that expired/cancelled bonuses and free spins can not be reinstated.</p>
            </li>
            <li>
              <p>The Terms and Conditions as published on the Website (and updated from time to time) are in English and
                it is the English version of these Terms and Conditions that form the basis of these Terms and Conditions
                only. Translations into other languages may be made as a service and are made in good faith. However, in
                the event of differences between the English version and a translation, the English version has priority
                over any translation.</p>
            </li>
          </ol>
        </li>
      </ol>
      <div class="bonus-terms__last-update">
        <p>Last updated: March 1st 2023</p>
      </div>
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'BonusesTerms',
  data() {
    return {

    };
  },
  components: {
    footerBottom
  },
  methods: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.basic-page {
  --base-color: #d3d7db;
  --highlight-color: #fff;
  color: #d3d7db;
  color: var(--base-color);
  font-size: 18px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 930px;
  padding: 60px 15px;
  width: 100%
}

@media(max-width:1600px) {
  .basic-page {
    font-size: 16px;
    padding-bottom: 40px;
    padding-top: 40px
  }
}

@media(max-width:768px) {
  .basic-page {
    font-size: 14px;
    padding-bottom: 30px;
    padding-top: 30px
  }
}

.basic-page h1,
.basic-page h2,
.basic-page h3 {
  color: var(--highlight-color);
  line-height: 1.2;
  margin: 60px 0 30px
}

@media(max-width:1600px) {

  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 40px 0 25px
  }
}

@media(max-width:768px) {

  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 30px 0 20px
  }
}

.basic-page h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 72px;
  font-weight: 900;
  margin: 0 0 60px
}

@media(max-width:1600px) {
  .basic-page h1 {
    font-size: 48px;
    margin-bottom: 40px
  }
}

@media(max-width:768px) {
  .basic-page h1 {
    font-size: 32px;
    margin-bottom: 30px
  }
}

.basic-page h2 {
  font-size: 48px
}

@media(max-width:1600px) {
  .basic-page h2 {
    font-size: 32px
  }
}

@media(max-width:768px) {
  .basic-page h2 {
    font-size: 24px
  }
}

.basic-page h3 {
  font-size: 32px
}

@media(max-width:1600px) {
  .basic-page h3 {
    font-size: 24px
  }
}

@media(max-width:768px) {
  .basic-page h3 {
    font-size: 18px
  }
}

.basic-page a {
  color: var(--highlight-color);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline
}

.basic-page a:hover {
  -webkit-text-decoration: none;
  text-decoration: none
}

.basic-page ol {
  list-style: decimal inside
}

.basic-page ol>li {
  margin: 1em 0
}

.basic-page ol>li::marker {
  color: var(--highlight-color);
  font-weight: 700
}

.basic-page ol>li p:first-child {
  display: inline
}

.basic-page img {
  display: block;
  margin: 1.5em 0
}

.basic-page__video {
  margin-bottom: 2em;
  padding: 56.25% 0 0;
  position: relative
}

.basic-page__video iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.bonus-terms {
  text-align: left;
}

.bonus-terms.basic-page ol {
  counter-reset: item;
  list-style: none
}

.bonus-terms.basic-page ol li {
  counter-increment: item
}

.bonus-terms.basic-page ol li>p:first-child:before {
  color: var(--highlight-color);
  content: counters(item, ".") ". ";
  font-weight: 700
}</style>