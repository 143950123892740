<template>
  <div class="iframe-container">
          
          <div v-if="isLoading" class="loaderbox" style="font-size:30px; color: rgb(66, 68, 69);">
            <div class="loader">{{t(`Loading`)+"..."}}
        <span></span>
        </div>
          </div>
          <div  
          @click="$router.push('/')"
           class="cross" >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="white" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                <path d="M0 0h24v24H0z" fill="none"/>
              </svg>
            </div>
            <iframe
              class="iframe"
              :src="gameUrl"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
  </template>
  
  <script>
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  import axios from 'axios';
  
  export default {
    name: 'GameIframe',
    data() {
      return {
        gameUrl: '',
        isLoading: true, // Initially set loading state to true
      };
    },
  
    created() {
      console.log(this.$route.params, "query")
      if (this.$route.params.api_provider_name === "NEXUSGGREU") {
        console.log("llll")
        this.fetchNexusGameUrl();
      } else if (this.$route.params.api_provider_name === "DREAMGATES") {
        this.fetchDreamGatesGameUrl();
      } else if (this.$route.params.api_provider_name === "EVERGAME") {
        this.fetchEverGameUrl();
      }
    },
    methods: {
      t(key) {
          return this.$t(key);
        },
      openGameInNewWindow() {
        // Check if gameUrl is not empty
        if (this.gameUrl) {
          // Open gameUrl in a new window/tab
          window.open(this.gameUrl, '_blank');
        }
      },
      async fetchNexusGameUrl() {
        const token = localStorage.getItem("userToken");
        const username = localStorage.getItem("username");
        try {
          const payload = {
            user_code: username || "demouser01",
            provider_code: this.$route.params.provider_id,
            game_code: this.$route.params.game_id,
            lang: "en"
          };
          const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/nexus/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
          const response = await axios.post(apiUrl, payload);
          if (response.data.status === 200) {
            this.gameUrl = response.data.data.launch_url;
            console.log(response.data, "url")
          } else {
            console.error('Failed to fetch game URL:', response.data);
              toast(response?.data?.message);
          }
        } catch (error) {
          toast(error?.response?.data?.message||error?.message);
          console.error('Error fetching game URL:', error);
        } finally {
          this.isLoading = false; // Set loading state to false when request is completed
        }
      },
      async fetchDreamGatesGameUrl() {
        const token = localStorage.getItem("userToken");
        const username = localStorage.getItem("username");
        const user_code = localStorage.getItem("user_code");
        try {
          const payload = {
            user_code: user_code || 400202552,
            provider_id: this.$route.params.provider_id,
            game_symbol: this.$route.params.game_id,
            lang: 1,
            win_ratio: 0
          };
          const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/dreamgates/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
          const response = await axios.post(apiUrl, payload);
          if (response.data.status === 200) {
            this.gameUrl = response.data.data.game_url;
          } else {
            toast(response?.data?.message);
            console.error('Failed to fetch game URL:', response.data);
          }
        } catch (error) {
          toast(error?.response?.data?.message||error?.message);
          console.error('Error fetching game URL:', error.response);
        } finally {
          this.isLoading = false; // Set loading state to false when request is completed
        }
      },
      async fetchEverGameUrl() {
        const token = localStorage.getItem("userToken");
        const username = localStorage.getItem("username");
        try {
          const payload = {
            userCode: username || "Thomas1",
            nickName: this.$route.params.game_id,
            vendorCode: this.$route.params.provider_id,
            language: "en"
  
          };
          const apiUrl = `${process.env.VUE_APP_API_KEY}/api/secondary-game/evergame/get-game-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
          const response = await axios.post(apiUrl, payload);
          if (response.data.status === 200) {
            this.gameUrl = response.data.launchUrl;
          } else {
            toast(response?.data?.message);
            console.error('Failed to fetch game URL:', response.data);
          }
        } catch (error) {
          toast(error?.response?.data?.message||error?.message);
          console.error('Error fetching game URL:', error);
        } finally {
          this.isLoading = false; // Set loading state to false when request is completed
        }
      }
    }
  };
  </script>
    
  <style scoped>
  /* Custom Scrollbar Styles */
  .back-button {
    margin-top: 7%;
    margin-bottom: 20px;
    margin-left: 20px;
    color: "white";
    background-color: white;
    padding: 10px;
    border-radius: 6px;
  }
  
  .iframe-page {
    width: 100%;
    height: 95vh; /* Adjust height as needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background: url("../../assets/images/main/bg-dashboard.webp"); */
    /* background-color: blue; */
    position:fixed;
    z-index: 100;
  }
  
  .iframe-container {
    width: 95%;
    margin: auto;
    height: 84vh;
    margin-top: 20px;
    border-radius: 12px;
    flex-grow: 1; /* Allow the iframe container to take up remaining space */
    overflow: hidden; /* Hide any overflow content */ 
  }
  .iframe {
    width: 100%;
    height: 100%;
    /* padding: 10px; */
    background: #000;
    z-index: 1;
  }
  .cross{
    display: flex;
    justify-content:flex-end ;
    cursor: pointer;
    position: absolute;
    padding: 10px;
    right: 40px;
  }
  @media screen and (max-width: 1024px) {
    .cross {
      display: none;
  }
  }
  @media screen and (max-width: 1024px) {
    .iframe-container {
      width: 100%;
      margin: auto;
      margin-top: 0px;
      border-radius: 0px;
      flex-grow: 1; /* Allow the iframe container to take up remaining space */
      overflow: hidden; /* Hide any overflow content */
      height: 100vh;
      margin-bottom: 0px;
    }
  
    .iframe {
      width: 100%;
      height: 100%;
      padding: 0px;
      /* background: #000; */
    }
  
    
  }
  
  
  .loaderbox {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    background-color: #000000;
  }
  </style>
  