<template>
  <div class="bs-c-account-modal__content">
    <div class="bs-c-tabs-swiper">
      <div
        @click="showSection('profile')"
        :style="{
          color: activeSection === 'profile' ? '#FAFA02' : '',
          borderColor: activeSection === 'profile' ? '#FAFA02' : '',
        }"
        :class="{ 'is-active': activeSection === 'profile' }"
        class="bs-c-tabs-swiper__item"
      >
        {{t(`Profile`)}} {{t(`Info`)}}
      </div>
      <div
        @click="showSection('email')"
        :class="{ 'is-active': activeSection === 'email' }"
        class="bs-c-tabs-swiper__item"
      >
        {{t(`Email`)}} {{t(`Verification`)}}
      </div>
      <div
        :class="{ 'is-active': activeSection === 'sms' }"
        @click="showSection('sms')"
        class="bs-c-tabs-swiper__item"
      >
        {{t(`Sms`)}} {{t(`Verification`)}}
      </div>
      <div
        :class="{ 'is-active': activeSection === 'bank' }"
        @click="showSection('bank')"
        class="bs-c-tabs-swiper__item"
      >
        {{t(`Bank`)}} {{t(`Verfication`)}}
      </div>
      <div
        :class="{ 'is-active': activeSection === 'kyc' }"
        @click="showSection('kyc')"
        class="bs-c-tabs-swiper__item"
      >
        {{t(`Kyc`)}} {{t(`Verfication`)}}
      </div>
    </div>

    <div
      :class="{ 'is-active': activeSection === 'profile' }"
      v-if="activeSection === 'profile'"
      class="bs-c-account-profile bs-c-account-profile-info"
    >
      <form class="bs-c-account-profile-info__form bs-c-widget-box">
        <span class="bs-c-deposit__input-name-text">{{t(`Email`)}} {{t(`Address`)}} </span>
        <label for="email" class="bs-c-deposit__input-wrapper is-disabled"
          ><input
            id="email"
            disabled="disabled"
            readonly="readonly"
            name="email"
            :value="userData?.data?.email"
            type="text"
            class="bs-c-deposit__input"
          />
        </label>
        <div class="bs-c-deposit__input-wrapper">
          <span class="bs-c-deposit__input-name-text">{{t(`Username`)}}</span>
          <label for="first_name" class="is-disabled"
            ><input
              id="first_name"
              autocomplete="nope"
              autocorrect="off"
              autocapitalize="none"
              readonly="readonly"
              onfocus="this.removeAttribute('readonly');"
              onblur="this.setAttribute('readonly', '')"
              ontouchstart="this.removeAttribute('readonly');"
              :value="userData?.data?.username"
              maxlength="50"
              disabled="disabled"
              type="text"
              class="bs-c-deposit__input is-not-empty-input"
            />
          </label>
        </div>

        <div v-if="userData?.data?.country" class="bs-c-deposit__input-wrapper">
          <span class="bs-c-deposit__input-name-text">{{t(`Country`)}}</span>
          <label for="first_name" class="is-disabled"
            ><input
              id="first_name"
              autocomplete="nope"
              autocorrect="off"
              autocapitalize="none"
              readonly="readonly"
              onfocus="this.removeAttribute('readonly');"
              onblur="this.setAttribute('readonly', '')"
              ontouchstart="this.removeAttribute('readonly');"
              :value="userData?.data?.country"
              maxlength="50"
              disabled="disabled"
              type="text"
              class="bs-c-deposit__input is-not-empty-input"
            />
          </label>
        </div>
        <div v-if="userData?.data?.phone" class="bs-c-deposit__input-wrapper">
          <span class="bs-c-deposit__input-name-text">{{t(`Phone`)}} {{t(`Number`)}}</span>
          <label for="first_name" class="is-disabled"
            ><input
              id="first_name"
              autocomplete="nope"
              autocorrect="off"
              autocapitalize="none"
              readonly="readonly"
              onfocus="this.removeAttribute('readonly');"
              onblur="this.setAttribute('readonly', '')"
              ontouchstart="this.removeAttribute('readonly');"
              :value="userData?.data?.phone"
              maxlength="50"
              disabled="disabled"
              type="text"
              class="bs-c-deposit__input is-not-empty-input"
            />
          </label>
        </div>
        <div v-if="userData?.data?.phone" class="bs-c-deposit__input-wrapper">
          <span class="bs-c-deposit__input-name-text">{{t(`Currency`)}}</span>
          <label for="first_name" class="is-disabled"
            ><input
              id="first_name"
              autocomplete="nope"
              autocorrect="off"
              autocapitalize="none"
              readonly="readonly"
              onfocus="this.removeAttribute('readonly');"
              onblur="this.setAttribute('readonly', '')"
              ontouchstart="this.removeAttribute('readonly');"
              :value="userData?.data?.currency"
              maxlength="50"
              disabled="disabled"
              type="text"
              class="bs-c-deposit__input is-not-empty-input"
            />
          </label>
        </div>
        <div
          v-if="userData?.data?.birthday"
          class="bs-c-deposit__input-wrapper"
        >
          <span class="bs-c-deposit__input-name-text">{{t(`Currency`)}}</span>
          <label for="first_name" class="is-disabled"
            ><input
              id="first_name"
              autocomplete="nope"
              autocorrect="off"
              autocapitalize="none"
              readonly="readonly"
              onfocus="this.removeAttribute('readonly');"
              onblur="this.setAttribute('readonly', '')"
              ontouchstart="this.removeAttribute('readonly');"
              :value="userData?.data?.birthday"
              maxlength="50"
              disabled="disabled"
              type="text"
              class="bs-c-deposit__input is-not-empty-input"
            />
          </label>
        </div>

        <!-- <div class="bs-c-deposit__input-wrapper">
                            <label for="address" class="is-disabled"
                              ><input
                                id="address"
                                autocomplete="nope"
                                autocorrect="off"
                                autocapitalize="none"
                                readonly="readonly"
                                onfocus="this.removeAttribute('readonly');"
                                onblur="this.setAttribute('readonly', '')"
                                ontouchstart="this.removeAttribute('readonly');"
                                name="address"
                                maxlength="50"
                                disabled="disabled"
                                type="text"
                                class="bs-c-deposit__input is-not-empty-input"
                              />
                              <div class="bs-c-deposit__input-name has-items">
                                <span class="bs-c-deposit__input-name-text"
                                  >Address</span
                                >
                              </div>
                            </label>
                          </div> -->

        <div v-if="userData?.data?.gender" class="bs-c-deposit__input-wrapper">
          <span class="bs-c-deposit__input-name-text">{{t(`Gender`)}}</span>
          <label for="first_name" class="is-disabled"
            ><input
              id="first_name"
              autocomplete="nope"
              autocorrect="off"
              autocapitalize="none"
              readonly="readonly"
              onfocus="this.removeAttribute('readonly');"
              onblur="this.setAttribute('readonly', '')"
              ontouchstart="this.removeAttribute('readonly');"
              :value="userData?.data?.gender"
              maxlength="50"
              disabled="disabled"
              type="text"
              class="bs-c-deposit__input is-not-empty-input"
            />
          </label>
        </div>
        <button
          disabled="disabled"
          class="c-button is-full-width size-l"
          type="submit"
          tabindex="12"
        >
          <span>{{t(`Save`)}} {{t(`Changes`)}}</span>
        </button>
        <div class="form-top-error-holder"></div>
      </form>
    </div>

    <div
      class="form-box"
      v-if="activeSection === 'email'"
      :class="{ section: true, active: activeSection === 'settings' }"
    >
      <div
        class="bank-details-box"
        v-if="userData?.data?.email_verified === false"
      >
        <div v-if="emailStep === 'pending'">
          <p style="color: black">
            {{ t(`Please verify your email address:`) }}
          </p>
          <div class="">
            <input
              type="email"
              v-model="email"
              placeholder="Enter your email"
              class="verfiyinput"
            />
            <button
              @click="sendEmailOtp"
              class="bankSubmit"
              tabindex="0"
              style="margin-top: 0px"
            >
              <div
                v-if="sendOtpLoader"
                style="height: 15px; width: 15px"
                class="spinner"
              ></div>

              <div v-else class="custom-button-wrapper">
                {{ t(`Send`) }} {{t(`OTP`)}}
              </div>
            </button>
          </div>
        </div>
        <div v-else-if="emailStep === 'inputOtp'">
          <p style="color: black">
            {{ t(`Please enter the OTP sent to your email!`) }}
          </p>
          <div class="">
            <input
              type="text"
              v-model="emailOtp"
              placeholder="Enter OTP"
              class="verfiyinput"
            />
            <button
              @click="verifyEmailOtp"
              class="bankSubmit"
              tabindex="0"
              style="margin-top: 0px"
            >
              <div
                v-if="verifyOtpLoader"
                style="height: 15px; width: 15px"
                class="spinner"
              ></div>

              <div v-else class="custom-button-wrapper">
                {{ t(`Verify`) }} {{t(`OTP`)}}
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="bank-details-box" v-else style="text-align: center">
        <p
          style="
            display: flex;
            color: #22c55e;
            justify-content: center;
            font-weight: bold;
          "
        >
          {{t(`Your email address is verified`)}}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            width="20px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M20 6L9 17l-5-5" />
          </svg>
        </p>
        <div
          class="verfiyinput"
          style="font-weight: 700; display: flex; justify-content: start"
        >
          {{ userData?.data?.email }}
        </div>
      </div>
      <!-- </div> -->
    </div>

    <div
      class="form-box"
      v-if="activeSection === 'sms'"
      :class="{ section: true, active: activeSection === 'settings' }"
    >
      <div
        class="bank-details-box"
        v-if="userData?.data?.sms_verified === false"
      >
        <div v-if="smsStep === 'pending'">
          <p style="color: black">
            {{ t(`Please verify your phone number:`) }}
          </p>
          <div>
            <input
              type="text"
              v-model="phone"
              placeholder="Enter your phone number"
              class="verfiyinput"
            />
            <button
              @click="sendSmsOtp"
              class="bankSubmit"
              tabindex="0"
              style="margin-top: 0px"
            >
              <div class="custom-button-wrapper">
                {{ t(`Send`) }} {{t(`OTP`)}}
              </div>
            </button>
          </div>
        </div>
        <div v-else-if="smsStep === 'inputOtp'">
          <p>
            {{ t(`Please enter the OTP sent to your phone:`) }}
          </p>
          <input
            type="text"
            v-model="smsOtp"
            placeholder="Enter OTP"
            class="verfiyinput"
          />
          <button
            @click="verifySmsOtp"
            class="bankSubmit"
            tabindex="0"
            style="margin-top:0px"
          >
            <div class="custom-button-wrapper">
              {{ t(`Verify`) }} {{t(`OTP`)}}
            </div>
          </button>
        </div>
      </div>
      <div class="bank-details-box" v-else style="text-align: center">
        <p
          style="
            display: flex;
            color: #22c55e;
            justify-content: center;
            font-weight: bold;
          "
        >
          {{t(`Your email address is verified`)}}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            width="20px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M20 6L9 17l-5-5" />
          </svg>
        </p>
        <div
          class="verfiyinput"
          style="font-weight: 700; display: flex; justify-content: start"
        >
          {{ userData?.data?.phone }}
        </div>
      </div>
    </div>

    <div
      v-if="activeSection === 'bank'"
      :class="{ section: true, active: activeSection === 'settings' }"
    >
      <div class="form-box">
        <div v-if="showBankDetails || userData?.data?.bank_verified === false">
          <div class="bank-details-box">
            <div class="bank-details-form">
              <div class="form-group">
                <label for="bankName">{{ t(`Bank Name`) }}</label>
                <input
                  type="text"
                  id="bankName"
                  v-model="bankName"
                  :placeholder="`${$t('Enter your bank name')}`"
                  class="verfiyinput"
                />
              </div>
              <div class="form-group">
                <label for="bankHolderName">{{
                  t(`Bank Holder's Name`)
                }}</label>
                <input
                  type="text"
                  id="bankHolderName"
                  v-model="bankHolderName"
                  :placeholder="`${$t('Enter bank holders name')}`"
                  class="verfiyinput"
                />
              </div>
              <div class="form-group">
                <label for="accountNumber">{{ t(`Account`) }} {{t(`Number`)}}</label>
                <input
                  type="text"
                  id="accountNumber"
                  v-model="accountNumber"
                  :placeholder="`${$t('Enter account number')}`"
                  class="verfiyinput"
                />
              </div>
              <div class="form-group">
                <label for="ifscCode">{{ t(`Code`) }} (IFSC, IBAN, SWIFT/BIC, etc.)</label>
                <input
                  type="text"
                  id="ifscCode"
                  v-model="ifscCode"
                  :placeholder="`${$t('Enter  code')}`"
                  class="verfiyinput"
                />
              </div>
              <div class="form-group">
                <label for="branchCode">{{ t(`Branch`) }}</label>
                <input
                  type="text"
                  id="branchCode"
                  v-model="branchCode"
                  :placeholder="`${$t('Enter branch')}`"
                  class="verfiyinput"
                />
              </div>
            </div>
            <button @click="submitKycDetails" class="bankSubmit" tabindex="0">
              <div
                v-if="kycLoader"
                style="height: 15px; width: 15px"
                class="spinner"
              ></div>

              <div v-else class="custom-button-wrapper">
                {{ t(`Submit Details`) }}
              </div>
            </button>
          </div>
        </div>

        <div v-else style="text-align: center">
          <p
            style="
              display: flex;
              color: #22c55e;
              font-weight: 800;
              justify-content: center;
            "
          >
            {{t(`Your bank is verified`)}}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              width="20px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M20 6L9 17l-5-5" />
            </svg>
          </p>
          <div class="bank-details-box">
            <div class="bank-details-form">
              <div class="form-group">
                <label for="bankName">{{ t(`Bank Name`) }}</label>
                <div
                  class="verfiyinput"
                  style="display: flex; align-items: start"
                >
                  {{ userData?.data?.bank_name }}
                </div>
              </div>
              <div class="form-group">
                <label for="bankHolderName">{{
                  t(`Bank Holder's Name`)
                }}</label>
                <div
                  class="verfiyinput"
                  style="display: flex; align-items: start"
                >
                  {{ userData?.data?.bank_holder }}
                </div>
              </div>
              <div class="form-group">
                <label for="accountNumber">{{ t(`Account`) }} {{t(`Number`)}}</label>
                <div
                  class="verfiyinput"
                  style="display: flex; align-items: start"
                >
                  {{ userData?.data?.account_number }}
                </div>
              </div>
              <div class="form-group">
                <label for="ifscCode">{{ t(`Code`) }} (IFSC, IBAN, SWIFT/BIC, etc.)</label>
                <div
                  class="verfiyinput"
                  style="display: flex; align-items: start"
                >
                  {{ userData?.data?.ifsc_code }}
                </div>
              </div>
              <div class="form-group">
                <label for="branchCode">{{ t(`Branch`) }}</label>
                <div
                  style="display: flex; align-items: start"
                  class="verfiyinput"
                >
                  {{ userData?.data?.branch_code }}
                </div>
              </div>
            </div>
            <!-- <button
                                   @click="handleEdit"
                                  class="bankSubmit"
                                  tabindex="0"
                                   
                                
                                >
                                  <div
                                    v-if="kycLoader"
                                    style="height: 15px; width: 15px"
                                    class="spinner"
                                  ></div>

                                  <div v-else class="custom-button-wrapper">
                                    {{ t(`Submit Details`) }}
                                  </div>
                                </button> -->
          </div>
        </div>
      </div>
    </div>
<div>


    <div
    style="width:100%;"
      v-if="activeSection === 'kyc'"
      :class="{ section: true, active: activeSection === 'settings' }"
    >
      <div >
        <!-- <h2 class="card-title ">Upload KYC Document</h2> -->
        <div class="ducument-details">
          <div
           
            class="bank-details-box"
            style="display: flex; flex-direction: column; gap: 30px"
          >
         
              <p style="text-align: center; font-weight: 800; font-size: 18px;">{{t(`Upload`)}} {{t(`Document`)}}:</p>
              <div class="grid-container">

              <div v-for="(value, key) in documentDetails" :key="key" class="grid-item">
                <p style="text-align: center; font-weight: 800; font-size: 14px;">{{ transformKey(key) }}</p>
      <input
        type="file"
        @change="handleFileUpload($event, key)"
        required
      />
      <div v-if="imgLoader[key]" class="spinner"></div>
      <img :src="documentDetails[key] || formData[key]" v-if="documentDetails[key] || formData[key]" alt="Uploaded Image" style="margin-top: 10px; width:90%; height:350px; border-radius: 8px" />
    </div>
  </div>
            <button class="bankSubmit" @click="handleSubmit">
              <div
                v-if="documnetLoader"
                style="height: 15px; width: 15px"
                class="spinner"
              ></div>
              <div v-else>{{t(`Submit`)}}</div>
            </button>
          </div>
        </div>
      </div>
     
    </div>
  </div>

  </div>
</template>

<script>
import footerBottom from "../parts/footer.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  name: "T&C",
  data() {
    return {
      footerData: [],
      loading: false,
      SITEAUTHKEY: "",
      activeSection: "profile",
      email: "",
      phone: "",
      emailOtp: "",
      sendOtpLoader: false,
      verifyOtpLoader: false,
      documnetLoader: false,

      kycLoader: false,
      showBankDetails: false,
      smsOtp: "",
      bankDetails: "",
      emailStep: "pending",
      accountNumber: "",
      bankName: "",
      bankHolderName: "",
      ifscCode: "",
      branchCode: "",
      smsStep: "pending",
      documentDetails:{},

      kycStep: "pending",
      // imgLoader: false,
      userData: {},
      formData: { },
    
      imgLoader: {},

     
    };
  },
  components: {
    footerBottom,
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    showSection(section) {
      // console.log(section, "section");
      this.activeSection = section; // Update the active section
    },
    transformKey(key) {
      // Example transformation from snake_case to Title Case
      return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    async getFooterData() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_KEY}/api/navigation/get-all-navigation?name=Terms Conditions&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        this.footerData = data?.data[0]?.data[0];
        // console.log(data?.data[0]?.data[0], "game in terms navigation");
      } catch (error) {
        console.error(error);
        // Handle error
      } finally {
        this.loading = false;
      }
    },
    async fetchUserData() {
      try {
        // Retrieve tokens from localStorage
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        // Check if tokens exist
        if (!userToken || !usernameToken) {
          console.error("User tokens not found in localStorage");
          return;
        }

        // Make GET request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/get-single-user?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const userData = await response.json();
        // Save the retrieved user data
        this.userData = userData;
        // console.log("User data during in header:", userData);

        localStorage.setItem("username", userData.data.username);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    showSection(section) {
      // console.log(section, "section");
      this.activeSection = section;
    },
    async sendEmailOtp() {
      // Logic to send email OTP
      if (!this.email) {
        toast("Please enter a valid email");
        return;
      }
      const payload = {
        email: this.email,
        site_auth_key: process.env.VUE_APP_API_SITE_AUTH_KEY,
      };
      this.sendOtpLoader = true;
      try {
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        if (!userToken || !usernameToken) {
          console.error("User tokens not found in localStorage");
          return;
        }

        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/send-email-otp?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          const data = await response.json();
          this.sendOtpLoader = false;
          this.emailStep = "inputOtp";
          // console.log(data, "checknig");
          toast(data?.message);
        } else {
          this.sendOtpLoader = false;

          // If not successful, handle the error
          console.error("Error:", response.status);
        }
      } catch (error) {
        console.error("Error checking username existence:", error);
        this.sendOtpLoader = false;
      }
    },
    async verifyEmailOtp() {
      if (!this.emailOtp) {
        toast("Please enter a otp");
        return;
      }
      const payload = {
        email: this.email,
        userEnteredOTP: this.emailOtp,
        site_auth_key:process.env.VUE_APP_API_SITE_AUTH_KEY
      };
      this.verifyOtpLoader = true;
      try {
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        if (!userToken || !usernameToken) {
          console.error("User tokens not found in localStorage");
          return;
        }

        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/verify-email-otp?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(payload),
          }
        );
        toast("email verified successfully !");
        this.verifyOtpLoader = false;
        this.fetchUserData();
        this.emailStep = "verified";
        // console.log(response, "response verify");
      } catch (error) {
        console.error("Error checking username existence:", error);
        this.verifyOtpLoader = false;
      }
    },
    sendSmsOtp() {
      this.smsStep = "inputOtp";
      // Logic to send SMS OTP
      alert(`Sending OTP to phone: ${this.phone}`);
    },
    verifySmsOtp() {
      // Logic to verify SMS OTP
      alert(`Verifying phone OTP: ${this.smsOtp}`);
      this.smsStep = "verified"; // Simulate successful verification
    },
    handleEdit() {
      this.showBankDetails = true;
    },
    async submitKycDetails() {
      if (
        !this.bankName ||
        !this.bankHolderName ||
        !this.accountNumber ||
        !this.ifscCode ||
        !this.branchCode
      ) {
        return toast("fill all the blanks.");
      }
      const payload = {
        bank_name: this.bankName,
        bank_holder: this.bankHolderName,
        account_number: this.accountNumber,
        ifsc_code: this.ifscCode,
        branch_code: this.branchCode,
        site_auth_key: process.env.VUE_APP_API_SITE_AUTH_KEY,
      };
      // console.log(payload, "oadsd");
      this.kycLoader = true;
      try {
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        if (!userToken || !usernameToken) {
          // console.error("User tokens not found in localStorage");
          this.kycLoader = false;

          return;
        }
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/update-bank-details?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          this.kycLoader = false;
          return response.json().then((errorData) => {
            toast(errorData?.message || "failed Updating");
            this.kycLoader = false;

            throw new Error(errorData?.message || "Failed to Update Password");
          });
        }
        this.kycStep = "verified";
        this.kycLoader = false;

        toast(response?.message || "updated Succefully");
        this.fetchUserData();
        this.showBankDetails = false;
      } catch (error) {
        this.kycLoader = false;
        toast(error?.message);
        console.error("Error updating password:", error);
      }
      // Simulate successful verification
    },
    async fetchGetDocumentDetails() {
      const token = localStorage.getItem("userToken");
      const usernametoken = localStorage.getItem("usernameToken");
      if (!token || !usernametoken) {
          return;
        }
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/get-document-details`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: token,
              usernametoken: usernametoken,
            },
          }
        );
        if (response.status !== 200) {
          throw new Error("Failed to fetch transactions");
        }
        const data = await response.json();
        console.log(data.data, "get document details");
        this.documentDetails = data.data;
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    },
   async  handleSubmit() {
      console.log('Submit data:', this.formData);

      // Handle form submission logic here

      // if (!this.formData.documentFile || !this.formData?.documentFile) {
      //   toast("Please fill al details");
      //   return;
      // }

      this.documnetLoader = true;
      try {
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        if (!userToken || !usernameToken) {
          console.error("User tokens not found in localStorage");
          this.documnetLoader = false;

          return;
        }

        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/update-document-details?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(this.formData),
          }
        );
        toast(response?.message || "document submitted successfully !");
        this.documnetLoader = false;
        this.fetchGetDocumentDetails();

        // console.log(response, "response verify");
      } catch (error) {
        this.documnetLoader = false;

        console.error("Error checking username existence:", error);
      }
    },
    async handleFileUpload(event,key) {
      const file = event.target.files[0];

      const formData = new FormData();
      formData.append("post_img", file);

      try {
        this.imgLoader[key] = true; // Show loader when starting image upload

        // console.log(this.imgLoader);
        const response = await this.sendPostRequest(
          `${process.env.VUE_APP_API_KEY}/api/payment/image-url?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          formData
        );

        if (response.url) {
          console.log(response.url,key,'response.url')
          this.imgLoader[key] = false; // Hide loader when image upload is successful
          // this.$set(this.formData, key, response.url); // Update formData with image URL
          this.formData[key] = response.url;
          
         
        }

      } catch (error) {
        this.imgLoader[key] = false;  // Hide loader when image upload fails
        // console.log(this.imgLoader);
        console.error("Error uploading image:", error.message);
      }
    },

    async sendPostRequest(url, formData) {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json(); // Assuming your API returns JSON response
        return responseData;
      } catch (error) {
        throw new Error(error.message || "Error sending POST request");
      }
    },
  },
  mounted() {
    const siteAuthKey = process.env.VUE_APP_API_SITE_AUTH_KEY;
    this.SITEAUTHKEY = siteAuthKey;
    this.getFooterData();
    this.fetchGetDocumentDetails()

    this.fetchUserData();
  },
  watcher(){
this.formData()
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.grid-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

@media (max-width: 1068px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 580px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
.box-input {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.account--nav {
  overflow: scroll;
  justify-content: center;
  display: flex;
}
.verfiyinput {
  border-radius: 6px;
  color: black;
  background-color: #d3d7db;
  border: 1px solid gray;
  outline: none;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}

.button-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin: auto;
  justify-content: center;
}
.inputBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verification-section {
  margin-top: 20px;
}

.ducument-details{
  display: grid;
  width:100%

}
.kyc_verified {
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #252525;
  width: 400px;
  margin: auto;
  border-radius: 16px;
  padding: 10px;
  margin-top: 10px;
}
.bankSubmit {
  color: black;
  background-color: #3861fb;
  border-radius: 6px;
  width: 95%;
  font-weight: 800;
  margin-top: 20px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}
.bankSubmit:hover {
  background-color: #3351bf;
  transition: all 0.3s ease;
}
._custom-button {
  color: black;

  background-color: red;
  /* border: 1px solid gray; */
  border: 2px solid red;
  border-radius: 6px;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}
.basic-page {
  --base-color: #d3d7db;
  --highlight-color: #fff;
  color: #d3d7db;
  color: var(--base-color);
  font-size: 18px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 930px;
  padding: 60px 15px;
  width: 100%;
}

@media (max-width: 1600px) {
  .basic-page {
    font-size: 16px;
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

@media (max-width: 768px) {
  .basic-page {
    font-size: 14px;
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.basic-page h1,
.basic-page h2,
.basic-page h3 {
  color: var(--highlight-color);
  line-height: 1.2;
  margin: 60px 0 30px;
}

@media (max-width: 1600px) {
  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 40px 0 25px;
  }
}

@media (max-width: 768px) {
  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 30px 0 20px;
  }
}

.basic-page h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 72px;
  font-weight: 900;
  margin: 0 0 60px;
}

@media (max-width: 1600px) {
  .basic-page h1 {
    font-size: 48px;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .basic-page h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  .kyc_verified {
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #252525;
    width: 95%;
    margin: auto;
    border-radius: 16px;
    padding: 10px;

    margin-top: 10px;
  }
}

.basic-page h2 {
  font-size: 48px;
}

@media (max-width: 1600px) {
  .basic-page h2 {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .basic-page h2 {
    font-size: 24px;
  }
}

.basic-page h3 {
  font-size: 32px;
}

@media (max-width: 1600px) {
  .basic-page h3 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .basic-page h3 {
    font-size: 18px;
  }
}

.basic-page a {
  color: var(--highlight-color);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.basic-page a:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.basic-page ol {
  list-style: decimal inside;
}

.basic-page ol > li {
  margin: 1em 0;
}

.basic-page ol > li::marker {
  color: var(--highlight-color);
  font-weight: 700;
}

.basic-page ol > li p:first-child {
  display: inline;
}

.basic-page img {
  display: block;
  margin: 1.5em 0;
}

.basic-page__video {
  margin-bottom: 2em;
  padding: 56.25% 0 0;
  position: relative;
}

.basic-page__video iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.general-terms.basic-page ol {
  counter-reset: item;
  list-style: none;
}

.general-terms.basic-page ol li {
  counter-increment: item;
}

.general-terms.basic-page ol li > h2:first-child:before,
.general-terms.basic-page ol li > h3:first-child:before,
.general-terms.basic-page ol li > p:first-child:before {
  color: var(--highlight-color);
  content: counters(item, ".") ". ";
  font-weight: 700;
}

.general-terms.is-in-pdf {
  --base-color: #161421;
  --highlight-color: #161421;
  font-size: 12px;
  padding: 20px;
  width: 590px;
}

.general-terms.is-in-pdf h1 {
  font-size: 20px;
  margin-top: 0;
}

.general-terms.is-in-pdf h1,
.general-terms.is-in-pdf h2 {
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.general-terms.is-in-pdf h2 {
  font-size: 14px;
}

.general-terms.is-in-pdf li,
.general-terms.is-in-pdf p {
  margin-top: 0;
}

.general-terms.is-in-pdf a {
  display: inline-block;
}

.general-terms.is-in-pdf .general-terms__download,
.general-terms.is-in-pdf .general-terms__download-spiner {
  display: none;
}

.general-terms__last-update {
  margin-top: 40px;
}

.general-terms__download-spiner {
  align-items: center;
  display: flex;
  pointer-events: none;
}

.general-terms__download-spiner .c-spinner {
  border-color: #d3d7db;
  margin-right: 5px;
}
.general-terms {
  text-align: left;
}

.form-box {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 10px;
  width: 100%;
  justify-content: center;
}
.bank-details-box {
  margin-left: 20px;
  border-radius: 17px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-bottom: 50px;
  padding: 20px;
}
.bank-details-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.box-input {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.account--nav {
  overflow: scroll;
  justify-content: center;
  display: flex;
}

.verfiyinput {
  border-radius: 6px;
  color: black;
  background-color: #f2f6f9;
  border: 1px solid gray;
  outline: none;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}
.account--content {
  border-radius: 10px;
  color: white;
  margin-bottom: 30px;
  height: auto;
  width: 100%;
}
.account-information--content {
  padding: 20px;
  background-color: #444;
  border-radius: 10px;
}
.button-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin: auto;
  justify-content: center;
}
.inputBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verification-section {
  margin-top: 20px;
}

.kyc_verified {
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #252525;
  width: 400px;
  margin: auto;
  border-radius: 16px;
  padding: 10px;
  margin-top: 10px;
}

.custom-button-wrapper {
  text-align: center;
}
input[type="email"],
input[type="text"] {
  width: calc(100% - 16px);
  margin: 8px 0;
}
.link {
  color: #fafa02;
  cursor: pointer;
  text-decoration: underline;
}

.bg-page:after {
  content: "";
  position: absolute;
  z-index: 2;
  background: linear-gradient(
    0deg,
    rgb(var(--mainBg)) 12.43%,
    rgba(var(--mainBg), 0)
  );
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.ngng {
  display: block;
  padding-top: 20px;
}
@media screen and (min-width: 1440px) {
  .ngng {
    padding-top: 32px;
  }
}
.container {
  padding: 0 var(--containerGutter);
  position: relative;
  width: 95%;
  margin: 0 auto;
  margin-top: 150px;
}
.account--grid-layout {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1440px) {
  .account--grid-layout {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;
    padding-bottom: 30px;
  }
}
.account--last-session {
  font-size: 14px;
  line-height: 140%;
  font-weight: 700;
  margin-bottom: 12px;
  grid-area: 1/1/-1/-1;
  text-align: center;
  color: rgb(var(--whiteColor));
}
@media screen and (min-width: 1440px) {
  .account--last-session {
    text-align: right;
  }
}
.account--last-session-time {
  color: rgb(var(--primaryColor));
}
.account--nav {
  margin-bottom: 20px;
  order: -1;
}
@media screen and (min-width: 1440px) {
  .account--nav {
    order: unset;
    background-color: rgb(var(--mainBgLight));
    position: relative;
    border-radius: 16px;
    padding: 20px;
    z-index: 1;
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .box-input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
}
@media screen and (max-width: 768px) {
  .box-input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media screen and (max-width: 1028px) {
  .account--nav {
    overflow: scroll;
    justify-content: start;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .account--nav-list {
    padding: 0;
    margin: 0;
    overflow: visible;
    grid-gap: 16px;
    grid-auto-flow: row;
  }
}
.account--nav-item {
  font-size: 16px;
  line-height: 140%;
  font-weight: 900;
  position: relative;
  font-style: italic;
  justify-content: center;
  cursor: pointer;
  border: 2px solid rgb(var(--bassicBgLight));
  text-transform: uppercase;
  color: rgb(var(--textColor));
  border-radius: 48px;
  padding: 11px 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1440px) {
  .account--nav-item {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1024px) {
  .account--nav-item {
    transition: var(--transitionTime) ease-in-out;
  }
}
.account--nav-item._active {
  border-color: rgb(var(--primaryBg));
  color: rgb(var(--primaryColor));
}
.account-links {
  margin-top: 28px;
  display: grid;
  grid-gap: 20px;
  padding: 0 24px;
}
.account-links--item {
  font-size: 14px;
  line-height: 140%;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  text-decoration: none;
  color: rgb(var(--textColor));
}
@media screen and (min-width: 1024px) {
  .account-links--item {
    transition: var(--transitionTime) ease-in-out;
  }
}

.account-links--item:not(:last-child) {
  margin-bottom: 0;
}
.account-links--icon {
  width: 24px;
  height: 20px;
  margin-right: 12px;
  flex-shrink: 0;
  color: rgb(var(--primaryColor));
}
.account-links--item {
  font-size: 14px;
  line-height: 140%;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  text-decoration: none;
  color: rgb(var(--textColor));
}
@media screen and (min-width: 1024px) {
  .account-links--item {
    transition: var(--transitionTime) ease-in-out;
  }
}
.account-links--icon {
  width: 24px;
  height: 20px;
  margin-right: 12px;
  flex-shrink: 0;
  color: rgb(var(--primaryColor));
}
.account-links--logout {
  font-size: 14px;
  line-height: 140%;
  font-weight: 700;
  margin-top: 15px;
  color: rgba(var(--textColor), 0.6);
  transition: var(--transitionTime);
  display: inline-flex;
  align-items: center;
}
.account-links--logout-icon {
  width: 20px;
  height: 20px;
  margin-right: 14px;
  flex-shrink: 0;
}

@media screen and (max-width: 1440px) {
  .kyc_verified {
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #252525;
    width: 95%;
    margin: auto;
    border-radius: 16px;
    padding: 10px;

    margin-top: 10px;
  }
}

.stb-button .custom-button-wrapper {
  display: inline-flex;
  height: 100%;
}
.stb-button .custom-button--medium {
  padding: 13px 24px;
  min-height: 20px;
  --stb-custom-button__font-size-title: 16px;
}
.stb-button._stroke .custom-button {
  border: 2px solid rgb(var(--primaryColor));
  background-color: initial;
  color: rgb(var(--primaryColor));
}
.custom-button__title {
  display: block;
  font-size: var(--stb-custom-button__font-size-title, 16px);
  line-height: var(--stb-custom-button__line-height-title, 19px);
}

.container {
  padding: 0 var(--containerGutter);
  position: relative;
  width: 95%;
  margin: auto;
  margin-top: 150px;
  padding: 0;
}
.stb-button .custom-button--primary {
  background-color: rgb(var(--primaryColor));
}
.card {
  width: 400px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #252525;
  color: gainsboro;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-body {
  padding: 20px;
}

.card-title {
  margin-bottom: 15px;
  color: #ffff00;
  font-size: 18px;
}

.card-text {
  margin-bottom: 10px;
}

.card-text strong {
  font-weight: bold;
}

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #252525;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Form */
.form-container form {
  display: grid;
  gap: 10px;
}

/* Label */
.form-container label {
  font-weight: bold;
}

/* Input fields */
.form-container input[type="text"],
.form-container select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #252525;
  color: white;
  outline: none;
}

/* File input */
.form-container input[type="file"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}


/* Submit button */
.form-container button[type="submit"] {
  width: 95%;
  padding: 10px;
  background-color: #ffff00;
  color: black;
  border: none;
  font-weight: 800;
  border-radius: 4px;
  cursor: pointer;
}

.form-container button[type="submit"]:hover {
  background-color: #d7d73c;
}

/* Error message */
.error-message {
  color: red;
  font-size: 14px;
}
</style>
