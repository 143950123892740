<template>
    <div v-if="visible" class="cookie-consent-card">
      <p>We use cookies, check <a href="/privacy-policy" @click="hideCard" class="link">Cookie Policy</a> for more info. You can change these settings in <a href="/privacy-policy" @click="hideCard" class="link">Cookie Settings</a></p>
      <button @click="acceptAll" class="accept-button">ACCEPT ALL</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: true
      };
    },
    methods: {
      acceptAll() {
        localStorage.setItem('cookieConsent', 'accepted');
        this.visible = false;
      },
      hideCard(event) {
        event.preventDefault(); // Prevent the default link behavior
        // localStorage.setItem('cookieConsent', 'accepted');
        this.$router.push('/privacy-policy');
      }
    },
    created() {
      const consent = localStorage.getItem('cookieConsent');
      if (consent === 'accepted') {
        this.visible = false;
      }
    }
  }
  </script>
  
  <style scoped>
 
  </style>
  