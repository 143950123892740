// src/modalState.js
import { reactive } from 'vue';

export const modalState = reactive({
  isModalOpen1: false,
  isModalOpen2: false,
  isModalOpen3: false,
  isModalOpen4: false,

  step1: 1,
  step2: 1,
  step3: 1,
});

export const openModal1 = () => {
  modalState.isModalOpen1 = true;
};

export const closeModal1 = () => {
  modalState.isModalOpen1 = false;
  modalState.step1 = 1;
};

export const openModal2 = () => {
  modalState.isModalOpen2 = true;
};

export const closeModal2 = () => {
  modalState.isModalOpen2 = false;
  modalState.step2 = 1;
};

export const openModal3 = () => {
  modalState.isModalOpen3 = true;
};

export const closeModal3 = () => {
  modalState.isModalOpen3 = false;
  modalState.step3 = 1;
};
export const openModal4 = () => {
  modalState.isModalOpen4 = true;
  document.body.style.overflow = 'hidden';
};

export const closeModal4 = () => {
  modalState.isModalOpen4 = false;
  document.body.style.overflow = 'scroll';


};

export const setStep1 = (newStep) => {
  modalState.step1 = newStep;
};

export const setStep2 = (newStep) => {
  modalState.step2 = newStep;
};

export const setStep3 = (newStep) => {
  modalState.step3 = newStep;
};


export const formatDate=(dateString)=>{
  const date = new Date(dateString);

  const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
  };

  const formattedDate = date.toLocaleString('en-US', options);

  // Swap month and day to get the desired format: dd-mm-yyyy
  const [month, day, year] = formattedDate.split(",")[0].split("/");
  const time = formattedDate.split(",")[1].trim();

  return `${day}-${month}-${year} ${time}`;
}