<template>
  <main>
    <div class="c-games-category"><!---->
      <div>
        <section data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="games-sections__heading">
              <div data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  Game Shows
                </h1> <!---->
              </div>
            </div>
            <div>
              <div data-cy="cy-games-block" class="games-block">
                <div class="game"><!----> <a href="/live-casino/crazy-pachinko" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-new"><!----> <span
                            class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/CrazyPachinko.webp"
                        alt="Crazy Pachinko" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/live-casino/cash-or-crash" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/cashorcrash.webp"
                        alt="Cash or Crash" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/live-casino/crazy-coin-flip" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/CrazyCoinFlip.webp"
                        alt="Crazy Coin Flip" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/table-games/crazy-time" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/crazytime.webp"
                        alt="Crazy Time" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/table-games/deal-or-no-deal" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/dealnodeal.webp"
                        alt="Deal or No Deal" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/crazy-time-a" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/crazytimea.webp"
                        alt="Crazy Time A Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/live-casino/dream-catcher" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/dream_catcher.webp"
                        alt="Dream Catcher" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/live-casino/extra-chilli-epic-spins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/ExtraChilliEpicSpins.webp"
                        alt="Extra Chilli Epic Spins" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/first-person-dream-catcher" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/moneywheel_rng.webp"
                        alt="First Person Dream Catcher Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/live-casino/funky-time" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/FunkyTime.webp"
                        alt="Funky Time" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/table-games/mega-ball" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/megaball.webp"
                        alt="Mega Ball" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/table-games/monopoly" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"> <!----></div> <span class="game-badge-separator"></span>
                      <!----> <span class="game-badge-provider is-bagde-hover"><span
                          class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evolution.svg" alt="Evolution"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evolution/monopoly.webp"
                        alt="Monopoly" class="c-game-box__image"> <!----></div>
                  </a></div>
              </div> <!---->
              <div data-cy="cy-games-block__footer" class="games-block__footer"><!----></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'GameShows',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex'
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  methods: {
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px)
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

.c-game-container .c-game-box img {
  border-radius: 10px;
}</style>
