<template>
    <div class="about-bitcoin">
        <div class="about-bitcoin__banner">
            <iframe title="About bitcoin video"
                src="https://www.youtube.com/embed/Gc2en3nHxA4?controls=1&amp;amp;showinfo=0&amp;amp;autoplay=0&amp;amp;rel=0"
                allowfullscreen="allowfullscreen" class="about-bitcoin__banner-video">
            </iframe>
        </div>
        <div class="about-bitcoin__section">
            <div class="about-bitcoin__section-wrapper">
                <h1>Why use bitcoin?</h1>
                <div class="about-bitcoin__section-intro">
                    <p>We know it can be a little tricky to wrap your head around the virtual currency of Bitcoin.</p>
                    <p>We think it’s the way forward in online payments and we want you to know why, so let’s break it down.
                    </p>
                </div>
                <div class="about-bitcoin__cards">
                    <div class="about-bitcoin__card">
                        <font-awesome-icon class="icon sprite-help-pages" :icon="['fas', 'user-shield']" />
                        <h4>SECURITY AND CONTROL OVER YOUR MONEY</h4>
                        <div class="about-bitcoin__card-description">
                            <p>The state-of-the-art encryption that secures your Bitcoin transactions is used in government
                                and military transactions, so you know it’s the safest around. As long as you take the
                                required steps to ensure your wallet is protected, no one can ever get their hands on your
                                money.</p>
                        </div> <!---->
                    </div>
                    <div class="about-bitcoin__card">
                        <font-awesome-icon class="icon sprite-help-pages" :icon="['fas', 'globe']" />
                        <h4>FAST INTERNATIONAL PAYMENTS</h4>
                        <div class="about-bitcoin__card-description">
                            <p>The Bitcoin network is working tirelessly to make sure you’re never waiting on a transfer.
                                You can pay someone on the other side of the world as quickly as you can pay your neighbour,
                                with transfers from Africa to Canada only taking around 10 minutes. There’s no bank, with
                                their snails-pace processes, to slow the whole procedure down.</p>
                        </div> <!---->
                    </div>
                    <div class="about-bitcoin__card">
                        
                        <font-awesome-icon class="icon sprite-help-pages" :icon="['fas', 'tag']" />
                        <h4>ZERO OR LOW FEES</h4>
                        <div class="about-bitcoin__card-description">
                            <p>Having to pay high transaction fees can be a real pain. The Bitcoin network is completely
                                free, with an option to speed up transaction processes by paying a voluntary fee.</p>
                        </div> <!---->
                    </div>
                    <div class="about-bitcoin__card">
                        <font-awesome-icon class="icon sprite-help-pages" :icon="['fas', 'bitcoin-sign']" />
                        <h4>OPEN AND FULLY DECENTRALIZED</h4>
                        <div class="about-bitcoin__card-description">
                            <p>Unlike your bank and credit card accounts, Bitcoin isn’t owned by anyone. It’s open-source,
                                with a community of developers maintaining the most popular clients. You’re free to spend
                                and play without worrying about some shady banker dipping his fingers into your winnings.
                            </p>
                        </div> <!---->
                    </div>
                    <div class="about-bitcoin__card">
                        <font-awesome-icon class="icon sprite-help-pages" :icon="['fas', 'id-card']" />
                        <h4>IDENTITY PROTECTION</h4>
                        <div class="about-bitcoin__card-description">
                            <p>Criminals are clever and once they have your credit card information they can steal your
                                identity and your money. That’s a thing of the past with Bitcoin. No case sensitive
                                information is required and you can even send a payment without revealing your identity at
                                all.</p>
                        </div> <!---->
                    </div>
                </div>
            </div>
        </div>
        <div class="about-bitcoin__section is-get-started">
            <div class="about-bitcoin__section-wrapper">
                <h1>Get started with bitcoins:</h1>
                <div class="about-bitcoin__section-intro">
                    <p>So now you know more about Bitcoins, we’re sure you’re wondering how to get started with them.</p>
                    <p>Just follow these simple steps and get playing in no time. Here’s what you have to do.</p>
                </div>
                <div class="about-bitcoin__cards">
                    <div class="about-bitcoin__card">
                        <font-awesome-icon class="icon sprite-help-pages" :icon="['fas', 'wallet']" />
                        <h4>1. PICK YOUR WALLET</h4>
                        <div class="about-bitcoin__card-description">
                            <p>Firstly, you'll need to choose which wallet you want to use for your Bitcoin transactions.
                                Just like real money, you'll need somewhere to keep them. Web wallets are the easiest most
                                convenient to use. There are plenty of online wallet options out there, including <a
                                    href="https://blockchain.info/wallet/#/" rel="nofollow">Blockchain</a>, Sparecoins or <a
                                    href="https://www.coinjar.com/" rel="nofollow">Coinjar</a>.</p>
                        </div> <!---->
                    </div>
                    <div class="about-bitcoin__card">
                        <font-awesome-icon class="icon sprite-help-pages" :icon="['fas', 'coins']" />
                        <h4>2. GET YOUR BITCOINS</h4>
                        <div class="about-bitcoin__card-description">
                            <p>Once you’ve chosen your wallet, it’s time to actually purchase some Bitcoins. This process is
                                getting easier and easier all the time, with lots of websites helping you to get your hands
                                on the virtual currency.</p>
                            <p>Many sites let you exchange multiple currencies for Bitcoins. These include <a
                                    href="https://www.coinbase.com/" rel="nofollow">Coinbase</a>, <a
                                    href="https://www.bitstamp.net" rel="nofollow">Bitstamp</a> and <a
                                    href="https://www.bitpanda.com/" rel="nofollow">Bitpanda</a>. You can even buy Bitcoins
                                directly from other members in your community by finding them on sites like <a
                                    href="https://localbitcoins.com/" rel="nofollow">LocalBitcoins.com</a>.</p>
                        </div> <!---->
                    </div>
                    <div class="about-bitcoin__card">
                        <font-awesome-icon class="icon sprite-help-pages" :icon="['fas', 'basket-shopping']" />
                        <h4>3. GET SPENDING</h4>
                        <div class="about-bitcoin__card-description">
                            <p>Once you have your Bitcoins it's time to get spending. You can use them to purchase just
                                about anything, from homes to holidays, cars to coats and of course, our fantastic casino
                                games too.</p>
                        </div>
                        <div class="about-bitcoin__logos"><a href="https://www.overstock.com/" rel="nofollow"><img
                                    src="https://d3oqh5ecy4r3n8.cloudfront.net/img/logo-overstock.28408e3.svg"
                                    alt="overstock"></a> <a href="https://www.namecheap.com/" rel="nofollow"><img
                                    src="https://d3oqh5ecy4r3n8.cloudfront.net/img/logo-namecheap.5eca1bf.svg"
                                    alt="namecheap"></a> <a href="https://www.bitcoinstore.com/" rel="nofollow"><img
                                    src="https://d3oqh5ecy4r3n8.cloudfront.net/img/logo-bitcoinstore.71eff15.svg"
                                    alt="bitcoin store"></a> <a href="https://www.virgingalactic.com/" rel="nofollow"><img
                                    src="https://d3oqh5ecy4r3n8.cloudfront.net/img/logo-virgin.99ed1dd.svg"
                                    alt="virgin galactic"></a> <a href="https://bitcoin.travel/" rel="nofollow"><img
                                    src="https://d3oqh5ecy4r3n8.cloudfront.net/img/logo-bitcointravel.4b1d568.svg"
                                    alt="bitcoin.travel"></a> <a href="https://www.gyft.com/" rel="nofollow"><img
                                    src="https://d3oqh5ecy4r3n8.cloudfront.net/img/logo-gyft.b26e1c5.svg" alt="gyft"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <footerBottom />
    </div>
</template>
  
<script>
import footerBottom from '../parts/footer.vue'


export default {
    name: 'AboutBitcoin',
    data() {
        return {

        };
    },
    components: {
        footerBottom
    },
    methods: {

    },
}
</script>
  
<style scoped>
.about-bitcoin__banner {
    background: #e8e8e8 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABjCAYAAABaKWrYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABPSURBVHgBjY5bDgAhCAOb3ftfxDMKqDFRMcXHxyRlKgEA0lchDTagHtMKETPm6O9mxYPNLOo9bFFS0fv1DHac2T2CWPjIBI5iwyL+oNJfAQqEUcYrPZ4vAAAAAElFTkSuQmCC) repeat-x;
    display: flex;
    justify-content: center
}

.lang-ja .about-bitcoin__banner {
    background-color: #1a1617
}

.lang-pt-BR .about-bitcoin__banner {
    background-color: #f3ead6
}

.lang-zh-CN .about-bitcoin__banner {
    background-color: #fff9f9
}

.about-bitcoin__banner-video {
    aspect-ratio: 16/9;
    border: 0;
    max-width: 900px;
    width: 100%
}

.about-bitcoin__section {
    color: #fff;
    font-size: 18px;
    padding: 80px 0
}

@media(max-width:768px) {
    .about-bitcoin__section {
        font-size: 14px;
        padding: 25px 0 30px
    }
}

.about-bitcoin__section h1,
.about-bitcoin__section h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1;
    margin: 0 0 25px
}

@media(max-width:768px) {

    .about-bitcoin__section h1,
    .about-bitcoin__section h2 {
        font-size: 38px
    }
}

@media(max-width:478px) {

    .about-bitcoin__section h1,
    .about-bitcoin__section h2 {
        font-size: 30px
    }
}

.about-bitcoin__section.is-get-started {
    background: #2b2740
}

.about-bitcoin__section.is-get-started .about-bitcoin__card {
    background: #3c3955
}

.about-bitcoin__section-wrapper {
    margin: 0 auto;
    max-width: 1420px;
    padding: 0 45px;
    width: 100%
}

@media(max-width:1024px) {
    .about-bitcoin__section-wrapper {
        padding: 0 15px
    }
}

.about-bitcoin__section-intro p {
    margin: 0
}

.about-bitcoin__cards {
    grid-gap: 40px;
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px
}

@media(max-width:768px) {
    .about-bitcoin__cards {
        gap: 20px;
        grid-template-columns: 1fr;
        margin-top: 30px
    }
}

.about-bitcoin__card {
    background: #2b2740;
    border-radius: 10px;
    height: 100%;
    padding: 40px 40px 40px 80px;
    position: relative
}

@media(max-width:768px) {
    .about-bitcoin__card {
        padding: 20px
    }
}

.about-bitcoin__card>svg {
    fill: #fff;
    height: 32px;
    left: 24px;
    position: absolute;
    top: 36px;
    width: 32px
}

@media(max-width:768px) {
    .about-bitcoin__card>svg {
        display: none
    }
}

.about-bitcoin__card h4 {
    font-size: inherit;
    margin-top: 0;
    text-transform: uppercase
}

.about-bitcoin__card p:last-child {
    margin-bottom: 0
}

.about-bitcoin__card a {
    color: #fff
}

@media(hover:hover) {
    .about-bitcoin__card a:hover {
        -webkit-text-decoration: none;
        text-decoration: none
    }
}

.about-bitcoin__logos {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 40px
}

@media(max-width:768px) {
    .about-bitcoin__logos {
        gap: 10px;
        margin-top: 20px
    }
}

.about-bitcoin__logos img {
    height: 30px;
    width: auto
}

@media(max-width:768px) {
    .about-bitcoin__logos img {
        height: 20px
    }
}
.about-bitcoin__section-wrapper{
    text-align:left;
}

</style>
  