



<template>
  <main>
    <div class="c-games-category"><!---->
      <div>
        <section data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="">
              <div   data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  {{t($route.params.name)}} {{t(`Games`)}}  
                </h1> <!---->
                <!-- <span _ngcontent-uvo-c283="" class="section-title">
                  {{t($route.params.name)}}
                  <span _ngcontent-uvo-c283="" class="section-title--txt">
                    {{t(`games`)}} ({{ totalCount }})</span
                    
                  >
                </span> -->
              </div>

              
            </div>
            <div style="margin-top: 20px">
              <div data-cy="cy-games-block" class="game-category-box ">
                <div v-for="game in games" :key="game._id" class="">
                  
              <router-link :to="`/top-games/${game.gameProviderId}/${game.gameID}?mode=player`" class="c-game-container">
             
                <div class="c-game-box">
                  <img :src="game.gameInfos[0].gameIconUrl" :alt="game.gameInfos[0].gameName" class="c-game-box__image image-casino-category" >
                </div>

              </router-link>
            </div>
            <template v-if="loading">
            <!-- Skeleton loading state -->
            <div class="skeleton-box">
              <div
                v-for="n in 25"
                :key="n"
                style=" background-color: #2b2740"
                class="skeleton"
              ></div>
            </div>
          </template>
              </div> <!---->
              <div data-cy="cy-games-block__footer" class="games-block__footer"><!----></div>
            </div>
          </div>
          <div class="load-more-container">
      <button style="margin-top: 10px" @click="loadMore" v-if="currentPage < totalPages" class="load-more-button">
        {{t(`Load More`)}}
        <span class="load-more-icon"></span>
      </button>
    </div>
        </section>
      </div>
      
      <section class="c-site-description">
  <div class="c-site-description__section">
    <h2>
      TOP GAMES
    </h2>
    <p>
      <span>
        At bitleon we’ve got a ton of games, and it’s rather safe to say that choosing the right one might be a bit
        of a daunting task, not to say time consuming and tedious. But no need to worry, there’s a shortcut. Behold,
        the “Top Games” category!
      </span>
      <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
    </p>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>What are top games?</h2>
    <div>
      <p>Great question. Here’s where we put the most played games at bitleon and you can find anything from
        jackpot games, classic slots, table games, etc. All we focus on here is what the most popular games are,
        that’s it. No discirmination of themes and game type in other words.</p>
      <p>Once again, we’ve got so many games in the casino that it can be tricky to find the right one. Perhaps you
        won’t find your new favorite game in this category, but there’s a big chance that you’ll find one you like
        as all the other players pretty much felt that way about the games that are there.</p>
      <p>This means you don’t need to waste your time going through every game we’ve got, and can fast forward to
        the top picks. Awesome, right?</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Isn’t it just the newest games?</h2>
    <div>
      <p>Not really. I mean, you are likely to find new games in this particular category, but that is mainly
        because we promote them and naturally that means they get a decent boost in popularity. Also, everyone wants
        to try the latest games out there, which is why they might find their way into this section.</p>
      <p>With that said, if that new game is just some temporary hype, they won’t be in the category for long. A
        great way to estimate the quality of the game is to see if a title remains in the top games for a long
        period of time. Which means there’s some quality there most likely.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Take the road less traveled</h2>
    <div>
      <p>But hey, you might be a contrarian that doesn’t want to be like everyone else and doesn't like to play the
        games that everyone else plays. That’s fair. If that’s the case you can always go to the <router-link to="/">bitleon home page</router-link> and click “Feeling Lucky!” and we’ll pick a game
        at random for you to enjoy. It’s like gambling on the game you’re gonna play. The Inception of gambling in
        other words.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>bitleon is the home of top games!</h2>
    <div>
      <p>Ready to check out the top games we’ve got? Most likely you’ll find a new favorite game in this category. I
        mean, the vast majority of players can’t be wrong, right? There has to be a reason why they play these ones!
      </p>
    </div>
  </div>
</section>
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import axios from 'axios'; // Import axios for making HTTP requests
import footerBottom from '../parts/footer.vue'; // Import footer component

export default {
  name: 'Table Games',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex',
      games: [], // Array to store games
      currentPage: 1, // Track the current page
      totalPages: null, // Track total pages from pagination
      loading:false
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  mounted() {
    // Call the API to fetch initial games
    this.fetchGames();
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    // Function to fetch games from API
    async fetchGames() {
      this.loading=true
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_KEY}/api/game/get-top-game`, {
          params: {
            status: true,
            page: this.currentPage,
            game_type:100,
            limit: 28,
            site_auth_key:process.env.VUE_APP_API_SITE_AUTH_KEY
          }
        });
        if (response.status === 200 && response.data.success) {
          // Update games array with new data
      this.loading=false

          this.games = [...this.games, ...response.data.data];
          this.totalPages = response.data.pagination.totalPages;
        } else {
      this.loading=false

          console.error('Failed to fetch games:', response.data.message);
        }
      } catch (error) {
      this.loading=false

        console.error('Error fetching games:', error);
      }
    },
    // Function to load more games
    loadMore() {
      if (this.currentPage < this.totalPages) {
        // Increment current page and fetch more games
        this.currentPage++;
        this.fetchGames();
      }
    },
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.skeleton-box {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.skeleton {
  width: 180px;
  height: 230px;
  border-radius: 8px;
}
@media screen and (max-width: 1200px) {

.skeleton-box {
display: grid;
width: 100%;
grid-template-columns: repeat(7, 1fr);
gap: 10px;
}

.skeleton {
width: 130px;
height: 160px;
border-radius: 8px;
}
}


@media screen and (max-width:700px) {

.skeleton-box {
display: grid;
width: 100%;
grid-template-columns: repeat(4, 1fr);
gap: 10px;
}

.skeleton {
width: 120px;
height: 130px;
border-radius: 8px;
}
}

@media screen and (max-width:448px) {

.skeleton-box {
display: grid;
width: 100%;
grid-template-columns: repeat(3, 1fr);
gap: 6px;
}

.skeleton {
width: 135px;
height: 140px;
border-radius: 8px;
}
}
.load-more-container {
  text-align: center;
}

.load-more-button {
  background-color: #1e90ff; /* Adjust color as needed */
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.load-more-button:hover {
  background-color: #007bff; /* Adjust hover color as needed */
}

.load-more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
  border-right: 2px solid rgba(255, 255, 255, 0.7);
  animation: loadMoreAnimation 0.8s infinite alternate;
}

@keyframes loadMoreAnimation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}
.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px)
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

.c-game-container .c-game-box img {
    border-radius: 10px;
}
</style>

