<template>
  
    <div class="iframe-page">

     
   
      <div class="iframe-container">
        
      <div v-if="loading " class="loaderbox" style="font-size:30px; color: rgb(66, 68, 69);">
        <div class="loader">{{t(`Loading`)+"..."}}
    <span></span>
  </div>
      </div>
      <div  
      @click="$router.push('/')"
       class="cross" >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="white" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </svg>
        </div>
        <iframe
          class="iframe"
          :src="iframeUrl"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </template>
  
  <script>
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  export default {
    
    data() {
      return {
        iframeUrl: "",
        loading: false,
        loading1:true,
       
      };
    },
    methods: {
      t(key) {
        return this.$t(key);
      },
      async handleLoginSeemlessGame(gpid, gameid, mode) {
        const token = localStorage.getItem("userToken");
        const username_token = localStorage.getItem("usernameToken");
        let username = localStorage.getItem("username");
  
        if (mode == "demo") {
          username = "demouser01111";
        }
  
        if ((!token || !username_token || !username) && !mode == "demo") {
          toast("Please login first.");
          return;
        }
        try {
          const payload = {
            Portfolio: "SeamlessGame",
            IsWapSports: false,
            Username: username,
            IsGetAll: "false",
            CompanyKey:process.env.VUE_APP_COMPANY_KEY,
            ServerId:process.env.VUE_APP_SERVER_ID,
            site_auth_key:process.env.VUE_APP_API_SITE_AUTH_KEY
          };
          const response = await fetch(
            `${process.env.VUE_APP_API_KEY}/api/casinogame/login-casino`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            }
          );
          if (response.status !== 200) {
            toast("Network response was not ok");
            return;
          }
  
          const data = await response.json();
          let url = `https:${data.data.url}&gpid=${gpid}&gameid=${gameid}`;
          // Determine device type based on screen size
          const deviceType = window.innerWidth <= 768 ? "m" : "d";
          // Add device query parameter to URL
          url += `&device=${deviceType}`;
          this.iframeUrl = url;
        } catch (error) {
          toast(error.message);
        }
        finally {
          this.loading1=false
        }
      },
      startLoader() {
        // Start the loader animation
        this.loading = true;
        
        // Stop the loader after 5 seconds
        setTimeout(() => {
          this.loading = false;
        }, 7000);
      },
      goBack() {
        // Example go back method
        window.history.back();
      },
    },
    mounted() {
      const gpId = this.$route.params.gpId;
      const gameId = this.$route.params.gameId;
      // const mode = this.$route.query.mode;
      const mode = "player";
      // console.log(gpId,gameId,mode,"sjjjhs")
      this.startLoader();
      this.handleLoginSeemlessGame(gpId, gameId, mode);
  
    },
  };
  </script>
  
  <style scoped>
.back-button {
  margin-top: 7%;
  margin-bottom: 20px;
  margin-left: 20px;
  color: "white";
  background-color: white;
  padding: 10px;
  border-radius: 6px;
}

.iframe-page {
  width: 100%;
  height: 95vh; /* Adjust height as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background: url("../../assets/images/main/bg-dashboard.webp"); */
  /* background-color: blue; */
  position:fixed;
  z-index: 100;
}

.iframe-container {
  width: 95%;
  margin: auto;
  margin-top: 20px;
  border-radius: 12px;
  flex-grow: 1; /* Allow the iframe container to take up remaining space */
  overflow: hidden; /* Hide any overflow content */
  margin-bottom: 50px;
}

.iframe {
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  background: #000;
  z-index: 1;
}
.cross{
  display: flex;
  justify-content:flex-end ;
  cursor: pointer;
  position: absolute;
  padding: 10px;
  right: 40px;
}
@media screen and (max-width: 1024px) {
  .cross {
    display: none;
}
}
@media screen and (max-width: 1024px) {
  .iframe-container {
    width: 100%;
    margin: auto;
    margin-top: 0px;
    border-radius: 0px;
    flex-grow: 1; /* Allow the iframe container to take up remaining space */
    overflow: hidden; /* Hide any overflow content */
    margin-bottom: 0px;
  }

  .iframe {
    width: 100%;
    height: 100%;
    padding: 0px;
    /* background: #000; */
  }

  
}


.loaderbox {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #000000;
}
</style>
