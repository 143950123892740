<template>
    <div class="c-vip-page">
        <picture>
            <source media="(max-width: 520px)" srcset="https://d3oqh5ecy4r3n8.cloudfront.net/img/bg-mobile.d42dc10.png"
                class="c-vip-page-img">
            <source media="(min-width: 521px)" srcset="https://d3oqh5ecy4r3n8.cloudfront.net/img/bg-desktop.302b433.png"
                class="c-vip-page-img"> <img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/bg-desktop.302b433.png"
                alt="VIP Starz Club background" class="c-vip-page-img is-desktop">
        </picture>
        <div class="c-vip-page-container">
            <header class="c-vip-page-header">
                <h1 class="c-vip-page-header__title">
                    VIP STARZ CLUB
                </h1> <span class="c-vip-page-header__text">The Most Exclusive VIP Program Ever</span>
            </header>
            <section class="c-vip-page-content">
                <div class="c-vip-page-content__card"><span class="c-vip-page-content__card-bg"></span> <img
                        src="https://d3oqh5ecy4r3n8.cloudfront.net/img/better-bonuses.90dcc02.png" alt="Better Bonuses icon"
                        class="c-vip-page-content__img"> <span class="c-vip-page-content__card-title">Better Bonuses</span>
                    <p class="c-vip-page-content__card-text">
                        As a VIP at bitleon, you enjoy bigger and better bonuses. After all, you deserve it. Enjoy a weekly
                        cashback with no restrictions on bet level or cashout. Extra surprise bonuses and reloads, and much
                        much more!
                    </p>
                </div>
                <div class="c-vip-page-content__card"><span class="c-vip-page-content__card-bg"></span> <img
                        src="https://d3oqh5ecy4r3n8.cloudfront.net/img/dedicated-vip-manager.62e2ca1.png"
                        alt="Dedicated VIP Manager icon" class="c-vip-page-content__img"> <span
                        class="c-vip-page-content__card-title">Dedicated VIP Manager</span>
                    <p class="c-vip-page-content__card-text">
                        Have 24/7 Access to a dedicated VIP Manager with 10+ years experience looking after top players.
                        You’ll be able to reach your Manager via Skype or Email and request custom limits on games, special
                        bonuses, just to mention a couple of things!
                    </p>
                </div>
                <div class="c-vip-page-content__card"><span class="c-vip-page-content__card-bg"></span> <img
                        src="https://d3oqh5ecy4r3n8.cloudfront.net/img/unrestricted-fast-cashouts.8a397a6.png"
                        alt="Unrestricted Fast Cashouts icon" class="c-vip-page-content__img"> <span
                        class="c-vip-page-content__card-title">Unrestricted Fast Cashouts</span>
                    <p class="c-vip-page-content__card-text">
                        When you win, you want the money out fast. As a VIP, you’ll enjoy prioritized, super fast
                        unrestricted amount cashouts. This means you can withdraw as much as you like at any time, with
                        lightning speed!
                    </p>
                </div>
                <div class="c-vip-page-content__card"><span class="c-vip-page-content__card-bg"></span> <img
                        src="https://d3oqh5ecy4r3n8.cloudfront.net/img/customized-bonus-plan.137c9cb.png"
                        alt="Customized Bonus Plan icon" class="c-vip-page-content__img"> <span
                        class="c-vip-page-content__card-title">Customized Bonus Plan</span>
                    <p class="c-vip-page-content__card-text">
                        Enjoy cashbacks and other reward plans that suits your style of playing. Are you a table games
                        player who doesn’t like wagering requirements? No problem. We’ll hook you up with a cashback. Let’s
                        arrange a bonus plan that works for you!
                    </p>
                </div>
                <div class="c-vip-page-content__card"><span class="c-vip-page-content__card-bg"></span> <img
                        src="https://d3oqh5ecy4r3n8.cloudfront.net/img/try-new-games.771d113.png"
                        alt="Be First to Try New Games! icon" class="c-vip-page-content__img"> <span
                        class="c-vip-page-content__card-title">Be First to Try New Games!</span>
                    <p class="c-vip-page-content__card-text">
                        Try new games before all other players as a VIP at bitleon, as we give you exclusive access to the
                        latest games in the casino! Get the preferential treatment you deserve by being the first to try the
                        newest releases!
                    </p>
                </div>
            </section> <!---->
        </div>
    </div>
    <div>
        <footerBottom />
    </div>
</template>
  
<script>
import footerBottom from '../parts/footer.vue'


export default {
    name: 'VIP',
    data() {
        return {
        };
    },
    components: {
        footerBottom
    },
}
</script>
  
<style scoped>
.c-vip-page {
    background-color: #1b1928;
    padding: 59vw 0 0;
    position: relative
}

@media(min-width:478px) {
    .c-vip-page {
        padding: 25vw 0 0
    }
}

@media(min-width:1650px) {
    .c-vip-page {
        padding: 354px 0 0
    }
}

.c-vip-page-img {
    height: auto;
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 1650px
}

@media(min-width:1650px) {
    .c-vip-page-img {
        top: -56px
    }
}

.c-vip-page-container {
    margin: 0 auto;
    max-width: 1420px;
    padding: 0 45px;
    position: relative;
    width: 100%;
    z-index: 1
}

@media(max-width:1024px) {
    .c-vip-page-container {
        padding: 0 15px
    }
}

.c-vip-page-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 14.5vw;
    text-align: center
}

@media(min-width:478px) {
    .c-vip-page-header {
        padding: 0 0 4.6vw
    }
}

@media(min-width:1650px) {
    .c-vip-page-header {
        padding: 0 0 75px
    }
}

.c-vip-page-header__title {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(#e5b400, #ffd22f);
    -webkit-background-clip: text;
    background-clip: text;
    background-origin: content-box;
    color: transparent;
    font-size: 36px;
    font-weight: 900;
    line-height: 1;
    margin: 0 0 10px;
    text-transform: uppercase
}

@media(min-width:768px) {
    .c-vip-page-header__title {
        font-size: 52px
    }
}

@media(min-width:1024px) {
    .c-vip-page-header__title {
        font-size: 64px
    }
}

.c-vip-page-header__text {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 42px
}

@media(min-width:768px) {
    .c-vip-page-header__text {
        font-size: 20px
    }
}

@media(min-width:1024px) {
    .c-vip-page-header__text {
        font-size: 24px
    }
}

.c-vip-page-content {
    grid-gap: 15px;
    display: grid;
    padding: 0 0 40px
}

@media(min-width:768px) {
    .c-vip-page-content {
        grid-template-columns: repeat(12, 1fr)
    }
}

@media(min-width:1024px) {
    .c-vip-page-content {
        grid-gap: 50px;
        padding: 0 0 100px
    }
}

.c-vip-page-content__card {
    align-items: center;
    background-color: #2b2740;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 20px;
    position: relative
}

@media(min-width:768px) {
    .c-vip-page-content__card {
        grid-column: span 4
    }

    .c-vip-page-content__card:nth-child(n+4) {
        grid-column: span 6
    }
}

@media(min-width:1024px) {
    .c-vip-page-content__card {
        padding: 30px
    }
}

.c-vip-page-content__card:first-child .c-vip-page-content__card-bg {
    background-color: rgba(255, 0, 92, .1)
}

.c-vip-page-content__card:nth-child(2) .c-vip-page-content__card-bg {
    background: rgba(255, 214, 0, .1)
}

.c-vip-page-content__card:nth-child(3) .c-vip-page-content__card-bg {
    background: rgba(255, 230, 0, .1)
}

.c-vip-page-content__card:nth-child(4) .c-vip-page-content__card-bg {
    background: rgba(0, 255, 87, .1)
}

.c-vip-page-content__card:nth-child(5) .c-vip-page-content__card-bg {
    background: rgba(0, 255, 209, .1)
}

.c-vip-page-content__card-bg {
    border-radius: 50%;
    filter: blur(80px);
    height: 65%;
    left: 0;
    position: absolute;
    top: -10%;
    width: 100%
}

.c-vip-page-content__img {
    height: 128px;
    width: auto
}

.c-vip-page-content__card-title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center
}

@media(min-width:1024px) {
    .c-vip-page-content__card-title {
        line-height: 1.75
    }
}

.c-vip-page-content__card-text {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center
}

@media(min-width:1024px) {
    .c-vip-page-content__card-text {
        font-size: 16px;
        font-weight: 600
    }
}</style>

  