<template>
  <main>
    <div class="c-games-category"><!---->
      <div>
        <section data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="games-sections__heading">
              <div data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  COLD GAMES - 24H
                </h1> <!---->
              </div>
            </div>
            <div>
              <div data-cy="cy-games-block" class="games-block">
                <div class="game"><!----> <a href="/slots/prehistoric-story" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 36%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/belatra.svg" alt="Belatra"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/belatra/PrehistoricStory.webp"
                        alt="Prehistoric Story Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/wild-cash-dice" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 36%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/WildCashDice.webp"
                        alt="Wild Cash Dice Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/book-of-ancients" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 38%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/gamebeat.svg" alt="Gamebeat"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/gamebeat/BookOfAncients.webp"
                        alt="Book Of Ancients Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/10000-bc-doublemax" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 47%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/4theplayer.svg" alt="4ThePlayer"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/yggdrasil/10000BCDoubleMax.webp"
                        alt="10000 BC DoubleMax Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/savage-buffalo-spirit-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 47%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/SavageBuffaloSpiritMegaways.webp"
                        alt="Savage Buffalo Spirit Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/the-belt-of-champion-bonus-buy" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 48%</span></span><span class="c-game-badge is-new"><!---->
                          <span class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/evoplay.svg" alt="Evoplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/evoplay/TheBeltofChampionBonusBuy.webp"
                        alt="The Belt of Champion Bonus Buy Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/tigers-fortune" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 49%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/lucky.svg" alt="Lucky"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/lucky/TigersFortune.webp"
                        alt="Tiger's Fortune Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/leprechauns-coins" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 50%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/platipus.svg" alt="Platipus"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/platipus/leprechauns.webp"
                        alt="Leprechaun's Coins Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/the-dog-house" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 51%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/TheDogHouse.webp"
                        alt="The Dog House Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/5-frozen-charms-megaways" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 53%</span></span><span class="c-game-badge is-new"><!---->
                          <span class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/pragmaticplay.svg"
                          alt="Pragmatic Play" class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/pragmaticexternal/5FrozenCharmsMegaways.webp"
                        alt="5 Frozen Charms Megaways Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/sizzling-eggs" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 53%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/wazdan.svg" alt="Wazdan"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/wazdan/SizzlingEggs.webp"
                        alt="Sizzling Eggs Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/jingle-balls" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 54%</span></span><span class="c-game-badge is-new"><!---->
                          <span class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/nolimit.svg" alt="Nolimit City"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/nolimit/JingleBalls.webp"
                        alt="Jingle Balls Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/big-bang" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 54%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/belatra.svg" alt="Belatra"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/belatra/BigBang.webp"
                        alt="Big Bang Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/lucky-crew" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 57%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/bgaming.svg" alt="BGaming"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/softswiss/LuckyCrew.webp"
                        alt="Lucky Crew Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/the-wild-wings-of-phoenix" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 59%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/booming.svg" alt="Booming Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/booming/TheWildWingsofPhoenix.webp"
                        alt="The Wild Wings of Phoenix Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/snow-giants" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 60%</span></span><span class="c-game-badge is-new"><!---->
                          <span class="c-game-badge__text">New</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/onlyplay.svg" alt="Onlyplay"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/onlyplay/SnowGiants.webp"
                        alt="Snow Giants Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/bang-bang" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 61%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/booming.svg" alt="Booming Games"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/booming/BangBang.webp"
                        alt="Bang Bang Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/mighty-wild-panther" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 63%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/wazdan.svg" alt="Wazdan"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/wazdan/MightyWildPanther.webp"
                        alt="Mighty Wild: Panther Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/2021-hit-slot" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 63%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/endorphina.svg" alt="Endorphina"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/endorphina/endorphina2_2021HitSlot.webp"
                        alt="2021 Hit Slot Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
                <div class="game"><!----> <a href="/slots/lucky-10-bells" class="c-game-container">
                    <div class="c-game-badges">
                      <div class="c-game-badges-collection"><span class="c-game-badge is-highest is-cold-rtp"><!---->
                          <span class="c-game-badge__text">RTP: 64%</span></span> <!----></div> <span
                        class="game-badge-separator"></span> <!----> <span
                        class="game-badge-provider is-bagde-hover"><span class="game-badge-provider__overlay"></span> <img
                          src="https://d1sc13y7hrlskd.cloudfront.net/providers/badges/1spin4win.svg" alt="1spin4win"
                          class="game-badge-provider__icon"></span>
                    </div>
                    <div class="c-game-box"><img
                        src="https://d1sc13y7hrlskd.cloudfront.net/optimized_images/portrait/1spin4win/Lucky10Bells.webp"
                        alt="Lucky 10 Bells Slot" class="c-game-box__image"> <!----></div>
                  </a></div>
              </div> <!---->
              <div data-cy="cy-games-block__footer" class="games-block__footer"><!----></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'ColdGames',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex'
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  methods: {
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px)
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

.c-game-container .c-game-box img {
  border-radius: 10px;
}

.c-game-badge.is-highest.is-cold-rtp {
    background-color: #3861fb;
}
</style>
