<template @mousemove="scroll" @mouseup="stopScroll">
  <div v-if="!showDetails" class="main-box"
  :style="{ color: !isActive ? 'black' : '' }"
  >
    <div>
      <p
      v-if="isActive"
        style="
          font-size: 35px;
          font-weight: bold;
          color: #dfe4e8;
          margin: 0px;
          margin-top: 10px;
        "
      >
        {{ t(`Promotions`) }}
      </p>
    </div>
    <div
      v-if="loading"
      class="spinnerPromotion"
      style="color: white; margin-top: 100px"
    ></div>

    <div class="promotions-grid">
      <div
        class="promotion-card"
        v-for="promotion in Promotions"
        :key="promotion._id"
      >
        <img
          :src="promotion.image"
          class="card-img-top"
          :alt="promotion.category"
        />
        <div class="card-content">
          <h5 class="card-title">
            {{
              promotion.category
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())
            }}
          </h5>
          <p class="card-text">
            {{
              promotion.description.split(" ").length > 30
                ? promotion.description.split(" ").slice(0, 30).join(" ") +
                  "..."
                : promotion.description
            }}
          </p>
          <p class="card-text">
            <strong>{{ t(`Reward`) }}:</strong> {{ promotion.reward_amount }}
            {{ promotion.reward_type }}
          </p>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 30px;
            "
          >
            <button @click="openModal" class="btn btn-primary">
              {{ t(`Get`) }} {{ t(`Bonus`) }}
            </button>
            <button
              @click="toggleDetails(promotion._id)"
              style="font-size: 12px"
              class="btn btn-secondary"
            >
              {{ t(`More`) }} {{ t(`Details`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showDetails" class="" :style="{ color: !isActive ? 'black' : '' }">
    <div class="promotion-container1">
      <div
        @click="returnBack"
        style="
          width: 100%;
          display: flex;
          justify-content: start;
          align-items: start;
        "
        :style="{ color: !isActive ? '#2B2740' : '' }"
      >
        <h5
          class="modal-title bgcgreen"
          style="font-size: 14px; cursor: pointer"
        >
          <span :style="{ color: !isActive ? '#2B2740' : '' }" class="mx-1">&lt;</span> {{ t(`Back`) }}
        </h5>
      </div>

      <img
        v-if="filteredPromotion"
        class="promotion-image"
        :src="filteredPromotion.image"
        alt="Promotion Image"
      />
      <div v-if="filteredPromotion"  class="bonus-rules ">
        <h3 class="" :style="{ color: !isActive ? '#2B2740' : '' }">{{
              filteredPromotion.category
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())
            }}</h3>
             <h2 :style="{ color: !isActive ? '#2B2740' : '' }" v-if="filteredPromotion" class="card-text1">
        {{ filteredPromotion?.description }}
      </h2>
      </div>

     
      <!-- <button
        style="
          margin-top: 20px;
          padding: 6px;
          border-radius: 17px;
          font-weight: 800;
          background-color: #01bd71;
          width: 100px;
        "
        @click="openModal"
      >
        {{ t(`Get`) }} {{ t(`Bonus`) }}
      </button> -->
      <div class="bonus-rules ">
        <h3 class="  " :style="{ color: !isActive ? '#2B2740' : '' }">Terms & Conditions:</h3>
        <h2 :style="{ color: !isActive ? '#2B2740' : '' }" v-if="filteredPromotion" class="card-text1">
        {{ filteredPromotion?.eligibility }}
      </h2>
      </div>
     

      <div class="bonus-rules ">
      <h3 :style="{ color: !isActive ? '#2B2740' : '' }"  class="">How to Get Bonus:</h3>
      <p :style="{ color: !isActive ? '#2B2740' : '' }" v-if="filteredPromotion" class="card-text1" v-html="filteredPromotion?.rules"></p>

      </div>
      <!-- <div v-if="filteredPromotion" class="bonus-rules">
        <h3>{{ t(`How`) }} {{ t(`to`) }} {{ t(`Get`) }} {{ t(`Bonus`) }}:</h3>
        <p class="rules" v-html="filteredPromotion?.rules"></p>
      </div>
      <div class="terms-and-conditions">
        <h3>{{ t(`Terms`) }} & {{ t(`Conditions`) }}:</h3>
        <p v-if="filteredPromotion">
          {{ filteredPromotion?.eligibility }}
        </p>
      
      </div> -->
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { modalState, openModal1 } from "../modalState.js";
export default {
  props: {
 
    isActive: {
      type: Boolean,
      default: true // Add the isActive prop
    }
  },
  data() {
    
    return {
      Promotions: [], // Corrected variable name
      loading: false,
      SITEAUTHKEY: "",
      showDetails: false,
      filteredPromotion: {},
    };
  },
  methods: {
    toggleDetails(id) {
      this.showDetails = true;
      let promotiondetails = this.Promotions.filter(
        (promotion) => promotion._id === id
      );
      let rawPromotionDetails = JSON.parse(JSON.stringify(promotiondetails));
      this.filteredPromotion = rawPromotionDetails[0];
      // console.log(rawPromotionDetails[0],"promotiondetails")
    },
    openModal() {
      openModal1();
    },
    t(key) {
      return this.$t(key);
    },
    returnBack() {
      this.showDetails = false;
    },
    async fetchUserData() {
      try {
        this.loading = true;

        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/promotion/get-all-promotion-user?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const responseData = await response.json();
        // console.log("User data123:", responseData.promotions);

        // Save the retrieved user data
        this.Promotions = responseData.promotions; // Corrected variable name
        this.loading = false;
      } catch (error) {
        console.error("Error fetching user data:", error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    viewDetails(promotionId) {
      // Use Vue Router to navigate to the details page and pass the promotionId
      this.$router.push({
        name: "PromotionDetails",
        params: { promotionId: promotionId },
      });
    },
  },
  mounted() {
    console.log(this.isActive,"isActive cehck")
    const siteAuthKey = process.env.VUE_APP_API_SITE_AUTH_KEY;
    this.SITEAUTHKEY = siteAuthKey;
    this.fetchUserData(); // Add an event listener for window resize
  },
};
</script>

<style scoped>
.main-box {
}
.promotions-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  justify-content: "center";
  align-items: "center";
  margin-top: -20px;
}

.promotion-card {
  position: relative;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* border: 0.3px solid #F9FF5E; */
  background-color: #0D0D0D;
  transition: transform 0.3s ease-in-out; /* Add transition for hover effect */
}

.promotion-card:hover {
  transform: translateY(-5px); /* Move card up on hover */
}

.card-img-top {
  object-fit: cover;
  height: 250px;
  width: 100%;
  border-radius: 10px;
}

.card-content {
  padding: 10px 0px;
  margin: 0px;
  color: #dfe4e8;
  text-align: left;
}

.card-title {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 1.6rem;
  text-align: left;
}
.single-card-title{
  text-align: left;
  border:2px solid red
}
.card-text {
}
.btn {
  width: 100%;
}
.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}
.spinnerPromotion {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
.bonus-heading,
.terms-heading {
  margin-top: 15px;
  font-size: 1rem;
  font-weight: bold;
}

.rules,
.terms {
  margin-top: 5px;
  font-size: 0.9rem;
}
.promotion-container1 {
  max-width: 800px;
  margin-top:30px;
  margin: auto;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
}
.card-text1{
  text-align: left;;
  font-size: 15px;
  font-weight: medium;
  color: gainsboro;
  margin-top: -20px;
}

.promotion-image {
  width: 100%;
  max-height: 400px;
  border-radius: 5px;
}
.promotion-category1 {
font-size: 30px;
font-family: bold;
text-align: left;
}
.promotion-title {
  font-size: 24px;
  width: 50%;
  line-height: 120%;
  font-weight: 900;
  font-style: italic;
  color: rgb(var(--whiteColor));
  /* text-transform: uppercase; */
  margin-top: 30px;
  width: 100%;
  color: #bbf7d0;
  text-align: center;
}

.bonus-rules {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

}
.bonus-rules > h3 {
  font-size: 24px;
  width: 50%;
  line-height: 120%;
  font-weight: 900;
  font-style: italic;
  color: rgb(var(--whiteColor));
  text-transform: uppercase;
  margin-top: 0px;
  font-size: 35px;

  width: 100%;
  text-align: left;
}
.rules {
  margin-top: 10px;
  color: #d6d3d1;
  margin-left: 30px;
  /* text-align: center; */
}
.terms-and-conditions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.terms-and-conditions > h3 {
  font-size: 24px;
  width: 50%;
  line-height: 120%;
  font-weight: 900;
  font-size: 35px;

  font-style: italic;
  color: rgb(var(--whiteColor));
  text-transform: uppercase;
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.terms-and-conditions > p {
  margin-top: 20px;
  color: #d6d3d1;
  margin-left: 30px;
  margin-bottom: 50px;
}
@media screen and (max-width: 1000px) {
  .promotions-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    justify-content: "center";
    align-items: "center";
    margin-top: -20px;
  }
}
@media screen and (max-width: 700px) {
  .promotion-title {
    font-size: 26px;
  }
  .promotion-card {
    position: relative;
    border-radius: 10px;
    padding: 10px;
    /* border: 0.3px solid #F9FF5E; */
    background-color: #0D0D0D;
    transition: transform 0.3s ease-in-out; /* Add transition for hover effect */
  }
  .promotions-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 20px;
    justify-content: "center";
    align-items: "center";
    margin-top: -20px;
  }
  .bonus-rules > h3 {
    font-size: 30px;
  }
  .terms-and-conditions > h3 {
    font-size: 30px;
  }
  /* Your styles for screen sizes less than 700 pixels go here */
}
</style>
