<template>
    <div
      v-if="visible"
      class="cookie-consent-card-login"
      style="display: flex; flex-direction: column"
    >
      <p style="text-align: left; font-size: 16px; font-weight: 800">
        Login/Register
      </p>
  
      <p>
        Welcome to our casino gaming site! To access our exciting games and
        features, please login to your account. If you don't have an account, you
        can Regesiter!
      </p>
      <button @click="acceptAll" class="accept-button" style="width:90px;margin-top: 20px">Ok</button>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: true,
      };
    },
    methods: {
      acceptAll() {
        this.visible = false;
      },
      hideCard(event) {
        event.preventDefault(); // Prevent the default link behavior
  
        this.visible = false;
      },
    },
    created() {
      const consent = localStorage.getItem("cookieConsent");
      if (consent === "accepted") {
        this.visible = false;
      }
    },
  };
  </script>
  
  <style scoped></style>
  